import React, { Component } from "react";
import "./MyMapsItems.css";
import { Grid } from '@material-ui/core';

class MyMapsItems extends Component {
  state = {};

  render() {
    return (
      <Grid container spacing={3} alignContent='center' justify='center'>
        {this.props.children.props.children.length === 0 ?
          <Grid item md={11} >
            Attualmente non ci sono elementi da mostrare. Utilizzare gli strumenti di disegno sopra per creare il proprio elemento personale.
        </Grid>
          :
          <Grid item md={12} >{this.props.children}</Grid>
        }
      </Grid>
    );
  }
}

export default MyMapsItems;

// IMPORT ALL IMAGES
const images = importAllImages(require.context("./images", false, /\.(png|jpe?g|svg)$/));
function importAllImages(r) {
  let images = {};
  r.keys().map((item, index) => (images[item.replace("./", "")] = r(item)));
  return images;
}
