import React, { Component } from "react";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';
import MuiAlert from '@material-ui/lab/Alert';
import { Dialog, DialogTitle, DialogContent, DialogContentText, Button, DialogActions } from '@material-ui/core';



const styles = {
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
  toolbarButtons: {
    marginLeft: 'auto',
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class attachmentUploadConfirmDialog extends Component {

  constructor(props) {
    super(props);

    this.state = {
      // Alert
      textMessage: "",
      severity: "info",
      isOpenMessage: false,

    };
  }

  openMessage = (text, type) => {
    this.setState(prevState => ({
      isOpenMessage: !prevState.isOpenMessage,
      isLoading: false,
      textMessage: text,
      severity: type
    }))
  }





  render() {
    const { file, isOpen, closeDialog, mainAction, layer } = this.props;


    return (
      <div>
       <Dialog
        open={isOpen}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
        Nuovo allegato
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           Sei sicuro di voler aggiungere l'allegato <b>{file ? file.name : ""}</b> sulla scheda <b>{layer ? layer.name : ""}</b> ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Annulla</Button>
          <Button onClick={mainAction} autoFocus>
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
      </div>
    );
  }
}

attachmentUploadConfirmDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(attachmentUploadConfirmDialog);
