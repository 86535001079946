import React, { Component, useRef } from "react";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import {
  Typography, CircularProgress, Snackbar, Grid, Button,
  Dialog, DialogContent, Fab
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { Row } from 'reactstrap';
import { Colxx } from '../common/CustomBootstrap';

// API REQUEST
import axios from 'axios';
import {
  GetAttachmentList, DownloadAttachmentRoot, PostAttachment
} from '../../../backEnd/requestAPI';

import ListView from "./AttachmentListView"
import AttachmentUploadConfirmDialog from "./attachmentUploadConfirmDialog"
import { Add } from '@material-ui/icons';
//Download
var fileDownload = require('js-file-download');

const styles = {
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
  toolbarButtons: {
    marginLeft: 'auto',
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}




class attachmentListDialogView extends Component {

  constructor(props) {
    super(props);

    this.state = {
      attachmentList: [],
      loadingCompleteProcess: '',

      // Alert
      textMessage: "",
      severity: "info",
      isOpenMessage: false,

      // Pagination
      currentPage: 0,
      totalPage: 0,

      // Attachment upload
      isOpenAttachmentConfirmDialog: false,
      attachmentSelected: ''

    };
  }

  componentDidMount() {
    this.getAttachments()
  }

  setCurrentPage = (number) => {
    this.setState({
      currentPage: number
    })
  }

  setTotalPage = (number) => {
    this.setState({
      totalPage: number
    })
  }

  setIsLoadingState = (state) => {
    this.setState({
      isLoading: state
    })
  }

  handleFileUpload = event => {
    this.setState({
      attachmentSelected: event.target.files[0]
    }, () => {
      this.openAttachmentConfirmDialog(true)
    })
  };



  openMessage = (text, type) => {
    this.setState(prevState => ({
      isOpenMessage: !prevState.isOpenMessage,
      isLoading: false,
      textMessage: text,
      severity: type
    }))
  }

  openAttachmentConfirmDialog = (value) => {
    this.setState({
      isOpenAttachmentConfirmDialog: value
    })
  }

  getAttachments = () => {
    const { layer } = this.props
    const { currentPage } = this.state

    const page = currentPage > 0 ? currentPage - 1 : 0

    console.log("ALLEGATI-layer", layer)

    const layerName = layer.name ? layer.name : layer.layerName.substring(layer.layerName.indexOf(':') + 1);
    const layerId = layer.feature.values_.id ? layer.feature.values_.id : layer.id;

    if (layer) {
      const config = {
        params: {
          geoServerLayer: layerName,
          geoServerId: layerId,
          limit: 8,
          offset: page * 8
        },
        headers: { Authorization: `Bearer ${localStorage.getItem("USER_TOKEN")}` }
      };

      this.setIsLoadingState(true);

      axios.get(
        GetAttachmentList,
        config)
        .then((res) => {
          return res.data;
        })
        .then((data) => {
          console.log("TESTAPP-attachmentList", data)

          if (data.results.length === 0) this.openMessage("Nessun allegato trovato", "info")
          this.setState({
            attachmentList: data.results,
            totalPage: Math.ceil(data.total / data.limit),
            isLoading: false
          })


        })
        .catch(error => {
          this.openMessage("Errore sconosciuto, contattare assistenza", "error")
        });
    }
  }

  downloadAttachment = (entity) => {
    const config = {
      params: {
        idAllegato: entity.id,
      },
      responseType: 'blob',
      headers: { Authorization: `Bearer ${localStorage.getItem("USER_TOKEN")}` }
    };

    this.setIsLoadingState(true);

    axios.get(
      DownloadAttachmentRoot,
      config)
      .then(response => {

        if (response.status === 200) {
          this.setIsLoadingState(false);
          fileDownload(response.data, entity.fileNome);
          this.openMessage("Download file", "success")
        } else if (response.status === 404) {
          this.setIsLoadingState(false);
          this.openMessage("File non trovato", "error")
        }
      })
      .catch(err => {
        this.setIsLoadingState(false);
        const errorMessage = err.response.data.message
        const message = errorMessage ? errorMessage : "Errore sconosciuto, contattare assistenza"

        this.openMessage(message, "error")
      });
  }


  postAttachment = () => {
    const { attachmentSelected } = this.state
    const { layer } = this.props

    console.log("TESTAPP", layer)

    this.openAttachmentConfirmDialog(false)

    const data = new FormData()
    data.append('documento', attachmentSelected)
    data.append('geoServerLayer', layer.name)
    data.append('geoServerId', layer.id)

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("USER_TOKEN")}`,
        'Content-Type': 'multipart/form-data'
      }
    };

    axios.post(PostAttachment, data,
      config).then(response => {

        this.setIsLoadingState(false);
        this.getAttachments()

      }).catch(error => {


        this.setState({ isLoadingSave: false })
        let errorResponse = error.response.data
        console.log("TESTAPP-error", errorResponse)

        if (errorResponse.statusCode === 401) {
          this.openSessionExpiredModal()
        } else if (errorResponse.statusCode === 403) {
          this.openMessage("Utente non autorizzato su questo Comune", "error")
        } else if (errorResponse.statusCode === 400) {
          this.openMessage(errorResponse.message, "warning")
        } else if (errorResponse.statusCode === 404) {
          this.openMessage(errorResponse.message, "warning")
        } else {
          this.openMessage("Errore sconosciuto, contattare assistenza", "error")
        }
      });
  }




  render() {
    const { classes, isOpen, closeDialog, layer } = this.props;
    const { isLoading, isOpenMessage, textMessage, severity,
      attachmentList, currentPage, totalPage, isOpenAttachmentConfirmDialog, attachmentSelected } = this.state


    return (
      <Dialog fullScreen open={isOpen} onClose={closeDialog} TransitionComponent={Transition}>
        <AppBar className={classes.appBar} style={{ background: '#ac4450' }}>
          <Toolbar style={{background: '#2F2483'}}>
            <IconButton edge="start" color="inherit" onClick={() => closeDialog()} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Allegati
            </Typography>
          </Toolbar>
        </AppBar>

        <DialogContent>
          <Row className="app-row survey-app">
            <Colxx xxs="12">

              {isLoading &&
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <CircularProgress />
                </div>
              }

              {attachmentList.length !== 0 && !isLoading ?
                <ListView
                  items={attachmentList}
                  currentPage={currentPage}
                  onChangePage={this.setCurrentPage}
                  totalPage={totalPage}
                  downloadAttachment={this.downloadAttachment}
                />
                :
                null
              }

              {isOpenMessage ?
                <Snackbar
                  open={isOpenMessage}
                  autoHideDuration={10000}
                  onClose={() => this.openMessage("", "info")}
                  nchorOrigin={{
                    vertical: "center",
                    horizontal: "center"
                  }}
                >

                  <Alert
                    onClose={() => this.openMessage("", "info")}
                    severity={severity}>
                    {textMessage}
                  </Alert>

                </Snackbar>
                : null
              }




            </Colxx>
          </Row>
        </DialogContent>



        {/* <div style={{ textAlign: 'right', margin: '21px' }}>

       <input
            style={{ display: 'none' }}
            ref="fileInput"
            type="file"
            onChange={this.handleFileUpload}
            inputProps={{ accept: '.pdf, image/*', size: 2048 }}

          />

          <Fab variant="extended" onClick={() => this.refs.fileInput.click()}>
            <Add sx={{ mr: 1 }} />
            Aggiungi allegato
          </Fab>
        </div>*/}

        {isOpenAttachmentConfirmDialog &&
          <AttachmentUploadConfirmDialog
            isOpen={isOpenAttachmentConfirmDialog}
            closeDialog={() => this.openAttachmentConfirmDialog(false)}
            file={attachmentSelected}
            mainAction={this.postAttachment}
            layer={layer}
          />}


        <div className={classes.appBar} style={{ background: '#ac4450', textAlign: 'center', minHeight: '21px' }}>

        </div>
      </Dialog>
    );
  }
}

attachmentListDialogView.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(attachmentListDialogView);
