import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  TextField, Grid, Button, InputAdornment, Typography,
} from '@material-ui/core';
import classNames from 'classnames';



const styles = theme => ({
  demo: {
    height: 'auto',
  },
  divider: {
    margin: `${theme.spacing(3)}px 0`,
  },
  input: {
    margin: theme.spacing(3),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(3),
  },
});


class InfoEsitiClassificazione extends Component {






  render() {
    const { classes, calcolaEsiti } = this.props;
    const {
      nodoPericolosita,
      nodoRischioSpecifico,
      nodoPericolositaComboValue,
      nodoRischioComboValue,
    } = this.props.nodo

    return (
      <Fragment>
        <br />

        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          style={{ padding: '21px' }}
        >
          <Grid
            container
            justify="center"
            md={12}
            xl={12}
            xs={12}
          >
            <Grid
              item
              md={12}
              xl={12}
              xs={12}
            >
              <div style={{ background: '#f0f9ff', padding: '8px', marginBottom: '21px', marginTop: '21px' }}>
                <Typography variant="h6" className={classes.title} >
                  ESITI DELLA CLASSIFICAZIONE PER FINALITA' DI PROTEZIONE CIVILE (DRPC SICILIA)
                </Typography>
              </div>
            </Grid>

            <Grid
              container
              direction="row"
              justify="center"
              alignItems="flex-start"
            >

              <Grid
                container
                direction="row"
                justify="center"
                alignItems="flex-start"
              >

                <Grid
                  container
                  justify="center"
                  style={{ marginTop: '55px', marginBottom: '34px' }}
                  md={12}
                  xl={8}
                  xs={12}
                >
                  <Button
                    fullWidth
                    style={{ margin: "3px" }}
                    color="primary" variant="outlined" className={classes.button}
                    onClick={() => calcolaEsiti()}
                  >
                    CALCOLA CLASSIFICAZIONE
                  </Button>
                </Grid>

              </Grid>
            </Grid>


            <Grid
              container
              direction="row"
              justify="center"
              alignItems="flex-start"
            >

              <Grid
                container
                justify="center"
                md={12}
                xl={8}
                xs={12}
                spacing={2}

              >

                <Grid
                  item
                  md={6}
                  xl={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    disabled
                    label="Pericolosità"
                    className={classNames(classes.margin, classes.textField)}
                    value={nodoPericolositaComboValue}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                      style: { fontSize: 21 }
                    }}
                    required
                  />
                </Grid>

                <Grid
                  item
                  md={6}
                  xl={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    disabled
                    label="Rischio specifico"
                    className={classNames(classes.margin, classes.textField)}
                    value={nodoRischioComboValue}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                      style: { fontSize: 21 }
                    }}
                    required
                  />
                </Grid>
              </Grid>
            </Grid>




          </Grid>
        </Grid>
      </Fragment >
    );
  }
}

InfoEsitiClassificazione.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(InfoEsitiClassificazione);