import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import {
  TextField, MenuItem, Grid,
  Button, InputAdornment, Typography,
  List, ListItem, ListItemText, ListItemSecondaryAction,
  IconButton, LinearProgress, Snackbar
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import DeleteConfirmDialog from '../istructionsToolsDialog/DeleteConfirmDialog';
import SessionExpiredModal from '../istructionsToolsDialog/SessionExpiredModal';

//Icons
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/LibraryAdd';
import SaveIcon from '@material-ui/icons/Save';


// API REQUEST
import axios from 'axios';
import {
  GetPunctualSiteInvestigationParameters,
  GetParameterTypeComboBox, GetParameterMeasurementComboBox,
  GetPunctualSiteInvestigationParameter, PostPunctualSiteInvestigationParameter,
  PutPunctualSiteInvestigationParameter, GetInfoTypeParameter,
  DeletePunctualSiteInvestigationParameter, getAnaLitoOptions
} from '../../../backEnd/requestAPI';

//IsoDate Convert
import moment from 'moment';


const styles = theme => ({
  demo: {
    height: 'auto',
  },
  divider: {
    margin: `${theme.spacing(3)}px 0`,
  },
  input: {
    margin: theme.spacing(3),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(3),
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class punctualSiteParameters extends Component {

  constructor(props) {
    super(props);

    this.state = {
      currentInvestigationID: props.investigationID,
      currentInvestigationType: props.investigationType,

      //LIST
      investigationsParametersList: [],
      investigationParametersTypeList: [],
      investigationParametersReliabilityMeasure: [],

      //DATA
      parameterType: '',
      paramaterDepthTop: '',
      paramaterDepthBotton: '',
      paramaterThickness: '',
      paramaterQuoteTop: '',
      paramaterQuoteBottom: '',
      parameterDate: '',
      parameterValue: '',
      parameterAmplitude: '',
      reliabilityMeasure: '',
      parameterValuesTable: '',
      parameterNote: '',

      //INFO PARAMETER TYPE
      InfoParameterType: "",
      descriptionType: "",
      parameter: "",
      unityMeasute: "",
      valueMAX: '',
      valueMin: '',
      anaLitoOptions: '',

      //CONTROLLER
      loadingCompleteProcess: 0,
      parameterSelectID: '',
      isNewParameter: true,
      loadingCompleteProcess: '',
      isParameterToUpdate: false,
      isLoadingSave: false,
      isOpenDeleteConfirmDialog: false,
      investigationParamaterIdToDelete: '',
      initialParamaterQuoteTop: '',
      initialparamaterQuoteBottom: '',
      initialParamaterThickness: '',
      severity: "info",
      isOpenSessionExpiredModal: false
    };

  }

  componentDidMount() {
    this.getParameters()
    this.getTypeComboBox()
    this.getMeasurementComboBox()
  }


  getTypeComboBox = () => {
    const { currentInvestigationType } = this.state
    const config = {
      params: {
        idTipoIndagine: currentInvestigationType
      },
      headers: { Authorization: `Bearer ${localStorage.getItem("USER_TOKEN")}` }
    };

    axios.get(GetParameterTypeComboBox,
      config).then(response => {
        this.setState(prevState => ({
          investigationParametersTypeList: response.data,
          loadingCompleteProcess: prevState.loadingCompleteProcess + 33
        }))
      }).catch(error => {
        this.openMessage("Errore sconosciuto, contattare assistenza", "error");
      });
  }

  getInfoType = () => {
    const { parameterType } = this.state

    const config = {
      params: {
        idTipoParametro: parameterType
      },
      headers: { Authorization: `Bearer ${localStorage.getItem("USER_TOKEN")}` }
    };

    axios.get(GetInfoTypeParameter,
      config).then(response => {
        let info = response.data

        this.setState({
          InfoParameterType: info.tipoParametro,
          descriptionType: info.descrizione,
          parameter: info.parametro,
          unityMeasute: info.unitaMisura,
          valueMAX: info.valoreMassimo,
          valueMin: info.valoreMinimo,
        }, () => {

          if (info.tipoParametro == "L" || info.tipoParametro == "LID") {
            this.getAnaLitoOptionsComboBox()
          }
        })
      }).catch(error => {
        this.openMessage("Errore sconosciuto, contattare assistenza", "error");
      });
  }

  getAnaLitoOptionsComboBox = () => {
    axios.get(getAnaLitoOptions,).then(response => {
      this.setState({
        anaLitoOptions: response.data
      })
    }).catch(error => {
      this.openMessage("Errore sconosciuto, contattare assistenza", "error");
    });
  }

  getMeasurementComboBox = () => {
    axios.get(GetParameterMeasurementComboBox,).then(response => {
      this.setState(prevState => ({
        investigationParametersReliabilityMeasure: response.data,
        loadingCompleteProcess: prevState.loadingCompleteProcess + 33
      }))
    }).catch(error => {
      this.openMessage("Errore sconosciuto, contattare assistenza", "error");
    });
  }

  getParameters = () => {
    const { currentInvestigationID } = this.state
    const config = {
      params: {
        idIndagine: currentInvestigationID,
      },
      headers: { Authorization: `Bearer ${localStorage.getItem("USER_TOKEN")}` }
    };

    axios.get(GetPunctualSiteInvestigationParameters,
      config).then(response => {
        let parameters = response.data;
        this.setState(prevState => ({
          investigationsParametersList: parameters,
          loadingCompleteProcess: prevState.loadingCompleteProcess + 33
        }))
      }).catch(error => {
        this.openMessage("Errore sconosciuto, contattare assistenza", "error");
      });
  }

  getParameter = (parameterID) => {

    const config = {
      params: {
        idParametro: parameterID,
      },
      headers: { Authorization: `Bearer ${localStorage.getItem("USER_TOKEN")}` }
    };

    axios.get(GetPunctualSiteInvestigationParameter,
      config).then(response => {

        let parameter = response.data;

        this.setState({
          parameterType: parameter.idEnumTipoParametro,
          paramaterDepthTop: parameter.profonditaTop,
          paramaterDepthBotton: parameter.profonditaBottom,
          paramaterThickness: parameter.spessore,
          paramaterQuoteTop: parameter.quotaTop,
          paramaterQuoteBottom: parameter.quotaBottom,
          parameterDate: moment(parameter.dataParametro).format('YYYY-MM-DD'),
          parameterValue: parameter.valore,
          parameterAmplitude: parameter.ampiezza,
          reliabilityMeasure: parameter.idEnumAttendibilitaMisura,
          parameterValuesTable: parameter.tab_curve,
          parameterNote: parameter.note ? parameter.note : "",
          isParameterToUpdate: true,

          //Controller
          initialparamaterQuoteBottom: parameter.quotaBottom,
          initialParamaterQuoteTop: parameter.quotaTop,
          initialparamaterQuoteBottom: parameter.quotaBottom,
          initialParamaterThickness: parameter.spessore

        }, () => {
          this.getInfoType()
        })

      }).catch(error => {
        this.openMessage("Errore sconosciuto, contattare assistenza", "error");
      });
  }

  saveParameter = (info) => {
    info.preventDefault();
    this.setState({ isLoadingSave: true })

    const {
      currentInvestigationID,
      parameterSelectID,

      //DATA
      parameterType,
      paramaterDepthTop,
      paramaterDepthBotton,
      paramaterThickness,
      paramaterQuoteTop,
      paramaterQuoteBottom,
      parameterDate,
      parameterValue,
      parameterAmplitude,
      reliabilityMeasure,
      parameterValuesTable,
      parameterNote,

      //CONTROLLER
      isParameterToUpdate,
    } = this.state

    const config = {
      params: {
        idParametro: parameterSelectID
      },
      headers: { Authorization: `Bearer ${localStorage.getItem("USER_TOKEN")}` }
    };

    const newParameter =
    {
      idIndagine: currentInvestigationID,
      idEnumTipoParametro: parameterType ? parseInt(parameterType) : 0,
      idEnumAttendibilitaMisura: reliabilityMeasure ? parseInt(reliabilityMeasure) : 0,
      profonditaTop: paramaterDepthTop ? parseFloat(paramaterDepthTop) : 0,
      profonditaBottom: paramaterDepthBotton ? parseFloat(paramaterDepthBotton) : 0,
      spessore: paramaterThickness ? parseFloat(paramaterThickness) : 0,
      quotaTop: parseFloat(paramaterQuoteTop),
      quotaBottom: paramaterQuoteTop ? parseFloat(paramaterQuoteBottom) : 0,
      dataParametro: parameterDate ? parameterDate : "",
      valore: parameterValue ? parameterValue : "",
      ampiezza: parameterAmplitude ? parameterAmplitude : "",
      tab_curve: parameterValuesTable ? parameterValuesTable : "",
      note: parameterNote ? parameterNote : ""
    }

    if (isParameterToUpdate) {
      axios.put(PutPunctualSiteInvestigationParameter,
        newParameter, config).then(response => {
          let newParameterSaved = response.data
          this.setState({
            isLoadingSave: false,
          }, () => {
            this.openMessage("Parametro aggiornato");
          })
        }).catch(error => {
          this.setState({ isLoadingSave: false })
          let errorResponse = error.response.data
          if (errorResponse.statusCode == 401) {
            this.openSessionExpiredModal()
          } else if (errorResponse.statusCode == 403) {
            this.openMessage("Utente non autorizzato su questo Comune", "error")
          } else if (errorResponse.statusCode == 400) {
            this.openMessage(errorResponse.message, "warning")
          } else {
            this.openMessage("Errore sconosciuto, contattare assistenza", "error")
          }
        });
    } else {
      axios.post(PostPunctualSiteInvestigationParameter,
        newParameter, config).then(response => {
          let newParameterSaved = response.data
          this.setState({
            isLoadingSave: false,
          }, () => {
            this.getParameters()
            this.openMessage("Parametro creato", "success");
          })
        }).catch(error => {
          this.setState({ isLoadingSave: false })
          let errorResponse = error.response.data
          if (errorResponse.statusCode == 401) {
            this.openSessionExpiredModal()
          } else if (errorResponse.statusCode == 403) {
            this.openMessage("Utente non autorizzato su questo Comune", "error")
          } else if (errorResponse.statusCode == 400) {
            this.openMessage(errorResponse.message, "warning")
          } else {
            this.openMessage("Errore sconosciuto, contattare assistenza", "error")
          }
        });
    }

  }

  deleteParameter = (parameterID) => {
    const config = {
      params: {
        idParametro: parameterID
      },
      headers: { Authorization: `Bearer ${localStorage.getItem("USER_TOKEN")}` }
    };

    axios.delete(DeletePunctualSiteInvestigationParameter,
      config).then(response => {
        this.openDeleteConfirmDialog("")
        this.openMessage("Parametro eliminato", "success")
        this.resetDataParameter()
        this.getParameters()
      }).catch(error => {
        let errorResponse = error.response.data
        if (errorResponse.statusCode == 401) {
          this.openSessionExpiredModal()
        } else if (errorResponse.statusCode == 403) {
          this.openMessage("Utente non autorizzato su questo Comune", "error")
        } else if (errorResponse.statusCode == 400) {
          this.openMessage(errorResponse.message, "warning")
        } else {
          this.openMessage("Errore sconosciuto, contattare assistenza", "error")
        }
      });
  }

  setValue = name => event => {
    this.setState({ [name]: event.target.value }, () => {
      this.calculateData(name)
      if (name == "parameterType") this.getInfoType()
    });
  };

  openDeleteConfirmDialog = (idParameter) => {
    this.setState(prevState => ({
      isOpenDeleteConfirmDialog: !prevState.isOpenDeleteConfirmDialog,
      investigationParamaterIdToDelete: idParameter
    }))
  }

  openSessionExpiredModal = () => {
    this.setState({
      isOpenSessionExpiredModal: true
    })
  }

  closeSessionExpiredModal = () => {
    this.setState({
      isOpenSessionExpiredModal: false
    })
  }

  openMessage = (text, type) => {
    this.setState(prevState => ({
      isOpenMessage: !prevState.isOpenMessage,
      textMessage: text,
      severity: type
    }))
  }

  calculateData = (name) => {

    const {
      paramaterDepthTop, paramaterDepthBotton,
      paramaterQuoteBottom, paramaterQuoteTop,
      paramaterThickness,
    } = this.state


    let prof_bot = parseFloat(paramaterDepthBotton).toFixed(2)
    let prof_top = parseFloat(paramaterDepthTop).toFixed(2)

    let quota_bot = parseFloat(paramaterQuoteBottom).toFixed(2)
    let quota_top = parseFloat(paramaterQuoteTop).toFixed(2)
    let spessore = parseFloat(paramaterThickness).toFixed(2)


    //spessore = prof_bot – prof_top
    let newParamaterThickness = prof_bot - prof_top
    this.setState({
      paramaterThickness: newParamaterThickness ? newParamaterThickness : 0
    })


    if (name === "paramaterQuoteBottom") {
      let newParamaterQuoteTop = quota_bot + spessore
      this.setState({
        initialparamaterQuoteBottom: quota_bot,
        //quota_top = quota_bot + Spessore
        initialParamaterQuoteTop: newParamaterQuoteTop ? parseFloat(newParamaterQuoteTop).toFixed(2) : 0,
        paramaterQuoteTop: newParamaterQuoteTop ? parseFloat(newParamaterQuoteTop).toFixed(2) : 0
      })
    } else if (name === "paramaterQuoteTop") {
      let newParamaterQuoteBottom = quota_top - spessore
      this.setState({
        initialParamaterQuoteTop: quota_top,
        //quota_bot  = quota_top -  Spessore
        initialparamaterQuoteBottom: newParamaterQuoteBottom ? parseFloat(newParamaterQuoteBottom).toFixed(2) : 0,
        paramaterQuoteBottom: newParamaterQuoteBottom ? parseFloat(newParamaterQuoteBottom).toFixed(2) : 0,
      })
    }

  }

  setQuote = newThickness => {
    const { initialParamaterQuoteTop, initialparamaterQuoteBottom } = this.state
    //let newInvestigationQuoteTop = initialparamaterQuoteBottom + newThickness
    let newParamaterQuoteBottom = initialParamaterQuoteTop - newThickness

    this.setState({
      //quota_top = quota_bot + Spessore
      // paramaterQuoteTop: newInvestigationQuoteTop && newThickness != 0 ? newInvestigationQuoteTop : 0,
      //quota_bot  = quota_top -  Spessore
      paramaterQuoteBottom: newParamaterQuoteBottom && newThickness != 0 ? parseFloat(newParamaterQuoteBottom).toFixed(2) : 0
    })
  }

  resetDataParameter = () => {
    //document.getElementById("punctual-site-parameter-form").reset();
    this.setState({
      //DATA
      parameterType: '',
      paramaterDepthTop: '',
      paramaterDepthBotton: '',
      paramaterThickness: '',
      paramaterQuoteTop: '',
      paramaterQuoteBottom: '',
      parameterDate: '',
      parameterValue: '',
      parameterAmplitude: '',
      reliabilityMeasure: '',
      parameterValuesTable: '',
      parameterNote: '',
      initialparamaterQuoteBottom: '',
      initialParamaterQuoteTop: '',
      initialParamaterThickness: '',
      anaLitoOptions: '',

      //INFO PARAMETER TYPE
      InfoParameterType: '',
      descriptionType: '',
      parameter: '',
      unityMeasute: '',
      valueMAX: '',
      valueMin: '',
      anaLitoOptions: '',

      //Controller
      isParameterToUpdate: false
    });
  }

  parameterSelect = value => {
    this.setState({
      parameterSelectID: value,
      isNewParameter: false
    }, () => {
      this.getParameter(value)
    })
  };

  render() {
    const { classes, investigationID, investigationType } = this.props;
    const {

      currentInvestigationID,
      currentInvestigationType,

      investigationsParametersList,
      investigationParametersTypeList,
      investigationParametersReliabilityMeasure,

      parameterType,
      paramaterDepthTop,
      paramaterDepthBotton,
      paramaterThickness,
      paramaterQuoteTop,
      paramaterQuoteBottom,
      parameterDate,
      parameterValue,
      parameterAmplitude,
      reliabilityMeasure,
      parameterValuesTable,
      parameterNote,
      loadingCompleteProcess,

      //INFO
      InfoParameterType,
      descriptionType,
      parameter,
      unityMeasute,
      valueMAX,
      valueMin,
      anaLitoOptions,

      //Controller
      isParameterToUpdate,
      isOpenMessage,
      textMessage,
      severity,
      isOpenSessionExpiredModal,
      isOpenDeleteConfirmDialog,
      investigationParamaterIdToDelete,
      initialParamaterThickness,
      isNewParameter

    } = this.state

    if (currentInvestigationID != investigationID && currentInvestigationType != investigationType) {
      this.setState({
        currentInvestigationID: investigationID,
        currentInvestigationType: investigationType,
      }, () => {
        this.getTypeComboBox()
      })
    }

    //Calcolo quote se cambia lo spessore
    if (paramaterThickness !== initialParamaterThickness) {
      this.setState({
        initialParamaterThickness: paramaterThickness
      }, () => {
        this.setQuote(paramaterThickness)
      })
    }


    return (
      <Fragment>
        <br />
        <form id="punctual-site-parameter-form" onSubmit={this.saveParameter} autoComplete="on">

          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
          >
            <Grid
              container
              justify="center"
              md={10}
              xl={12}
              xs={12}
              alignItems="flex-start"
            >

              {loadingCompleteProcess < 99 ?
                <Grid
                  item
                  md={12}
                  xl={12}
                  xs={12}
                >
                  <LinearProgress variant="determinate" value={loadingCompleteProcess == 99 ? 0 : loadingCompleteProcess} />
                  <Typography variant="h6" className={classes.title} style={{ textAlign: 'center' }}>
                    Caricamento in corso {loadingCompleteProcess}%
            </Typography>
                </Grid>
                :
                <>

                  <Grid
                    item
                    md={12}
                    xl={12}
                    xs={12}
                  >
                    <div style={{ background: '#f0f9ff', padding: '8px', marginBottom: '21px', marginTop: '21px' }}>
                      <Typography variant="h6" className={classes.title}>
                        PARAMETRI PER INDAGINE <b>{currentInvestigationID}</b>
                      </Typography>
                    </div>
                  </Grid>

                  {/** PRIMA COLONNA PRINCIPALE */}
                  <Grid
                    container
                    spacing={1}
                    md={3}
                    xl={3}
                    xs={12}
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                  >

                    <Grid
                      item
                      md={6}
                      xl={6}
                      xs={10}
                    >
                      <Typography variant="h6" className={classes.title}>
                        Lista Parametri
                       </Typography>

                    </Grid>

                    <Grid
                      item
                      md={2}
                      xl={2}
                      xs={2}
                    >
                      <IconButton color="primary"
                        aria-label="Nuovo Parametro"
                        onClick={() => this.resetDataParameter()}
                      >
                        <AddIcon />
                      </IconButton>

                    </Grid>

                    <Grid
                      item
                      md={10}
                      xl={10}
                      xs={12}
                    >
                      <List component="nav">
                        {investigationsParametersList.map((parameter) => {
                          return (
                            <ListItem
                              button
                              divider
                              key={parameter.idParametro}
                              onClick={() => this.parameterSelect(parameter.idParametro)}>

                              <ListItemSecondaryAction>

                                {/* <IconButton aria-label="Copy">
                                  <CopyIcon />
                                </IconButton>*/}
                                <IconButton aria-label="Delete" onClick={() => this.openDeleteConfirmDialog(parameter.idParametro)}>
                                  <DeleteIcon />
                                </IconButton>

                              </ListItemSecondaryAction>

                              <ListItemText primary={parameter.idParametro} />

                            </ListItem>
                          )
                        })}
                      </List>
                    </Grid>


                  </Grid>

                  <Grid
                    container
                    spacing={1}
                    justify="center"
                    md={9}
                    xl={9}
                    xs={12}
                  >

                    <Grid
                      item
                      md={12}
                      xl={12}
                      xs={12}
                    >
                      <Typography variant="h6" className={classes.title}>
                        Dettaglio Parametro
            </Typography>
                    </Grid>

                    <Grid
                      item
                      md={12}
                      xl={12}
                      xs={12}
                      className={classes.demo}
                    >
                      <TextField
                        required
                        fullWidth
                        select
                        label="Tipo parametro"
                        className={classNames(classes.margin, classes.textField)}
                        value={parameterType}
                        onChange={this.setValue("parameterType")}
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{
                          MenuProps: {
                            className: classes.menu,
                          },
                        }}
                        margin="normal"
                        variant="outlined"
                      >
                        {investigationParametersTypeList.map(option => (
                          <MenuItem key={option.idEnum} value={option.idEnum}>
                            {option.comboValue}
                          </MenuItem>
                        ))}
                      </TextField>

                    </Grid>

                    <Grid
                      item
                      md={4}
                      xl={4}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label="Profondità TOP"
                        className={classNames(classes.margin, classes.textField)}
                        value={paramaterDepthTop}
                        onChange={this.setValue("paramaterDepthTop")}
                        margin="normal"
                        variant="outlined"
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">(m)</InputAdornment>
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      md={4}
                      xl={4}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label="Profondità BOTTOM"
                        className={classNames(classes.margin, classes.textField)}
                        value={paramaterDepthBotton}
                        onChange={this.setValue("paramaterDepthBotton")}
                        margin="normal"
                        variant="outlined"
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">(m)</InputAdornment>
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      md={4}
                      xl={4}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        disabled
                        label="Spessore"
                        className={classNames(classes.margin, classes.textField)}
                        value={parseFloat(paramaterThickness).toFixed(2)}
                        onChange={this.setQuote}
                        margin="normal"
                        variant="outlined"
                        type="number"
                        error={paramaterThickness < 0 ? true : false}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">(m)</InputAdornment>,
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      md={4}
                      xl={4}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label="Quota TOP"
                        className={classNames(classes.margin, classes.textField)}
                        value={paramaterQuoteTop}
                        onChange={this.setValue("paramaterQuoteTop")}
                        margin="normal"
                        variant="outlined"
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">(m)</InputAdornment>,
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      md={4}
                      xl={4}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        disabled
                        label="Quota BOTTOM"
                        className={classNames(classes.margin, classes.textField)}
                        value={paramaterQuoteBottom}
                        onChange={this.setValue("paramaterQuoteBottom")}
                        margin="normal"
                        variant="outlined"
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">(m)</InputAdornment>,
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      md={4}
                      xl={4}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        required
                        label="Data parametro"
                        type="date"
                        className={classNames(classes.margin, classes.textField)}
                        value={parameterDate}
                        onChange={this.setValue("parameterDate")}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      md={3}
                      xl={3}
                      xs={12}
                    >
                      <TextField
                        disabled
                        fullWidth
                        label="Tipo"
                        className={classNames(classes.margin, classes.textField)}
                        value={InfoParameterType}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      md={9}
                      xl={9}
                      xs={12}
                    >
                      <TextField
                        disabled
                        fullWidth
                        label="Descrizione"
                        className={classNames(classes.margin, classes.textField)}
                        value={descriptionType}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>


                    <Grid
                      item
                      md={3}
                      xl={3}
                      xs={12}
                    >
                      <TextField
                        disabled
                        fullWidth
                        label="Parametro"
                        className={classNames(classes.margin, classes.textField)}
                        value={parameter}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      md={3}
                      xl={3}
                      xs={12}
                    >
                      <TextField
                        disabled
                        fullWidth
                        label="Unità di misura"
                        className={classNames(classes.margin, classes.textField)}
                        value={unityMeasute}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>


                    <Grid
                      item
                      md={3}
                      xl={3}
                      xs={12}
                    >
                      <TextField
                        disabled
                        fullWidth
                        label="Minimo"
                        className={classNames(classes.margin, classes.textField)}
                        value={valueMin}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">min</InputAdornment>,
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      md={3}
                      xl={3}
                      xs={12}
                    >
                      <TextField
                        disabled
                        fullWidth
                        label="Massimo"
                        className={classNames(classes.margin, classes.textField)}
                        value={valueMAX}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">max</InputAdornment>,
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      md={6}
                      xl={6}
                      xs={12}
                      className={classes.demo}
                    >
                      <TextField
                        fullWidth
                        select
                        label="Attendibilità Misura"
                        className={classNames(classes.margin, classes.textField)}
                        value={reliabilityMeasure}
                        onChange={this.setValue("reliabilityMeasure")}
                        InputLabelProps={{ shrink: true }}
                        SelectProps={{
                          MenuProps: {
                            className: classes.menu,
                          },
                        }}
                        margin="normal"
                        variant="outlined"
                      >
                        {investigationParametersReliabilityMeasure.map(option => (
                          <MenuItem key={option.idEnum} value={option.idEnum}>
                            {option.comboValue}
                          </MenuItem>
                        ))}
                      </TextField>

                    </Grid>

                    <Grid
                      item
                      md={3}
                      xl={3}
                      xs={12}
                    >

                      {anaLitoOptions ?

                        <TextField
                          required
                          fullWidth
                          select
                          label="Valore"
                          className={classNames(classes.margin, classes.textField)}
                          value={parameterValue}
                          onChange={this.setValue("parameterValue")}
                          InputLabelProps={{ shrink: true }}
                          SelectProps={{
                            MenuProps: {
                              className: classes.menu,
                            },
                          }}
                          margin="normal"
                          variant="outlined"
                        >
                          {anaLitoOptions.map(option => (
                            <MenuItem key={option.codiceLito} value={option.codiceLito}>
                              {option.denominazione}
                            </MenuItem>
                          ))}
                        </TextField>

                        :

                        <TextField
                          fullWidth
                          label="Valore"
                          className={classNames(classes.margin, classes.textField)}
                          value={parameterValue}
                          onChange={this.setValue("parameterValue")}
                          margin="normal"
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                        />
                      }

                    </Grid>

                    <Grid
                      item
                      md={3}
                      xl={3}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label="Ampiezza A0 HVSR"
                        className={classNames(classes.margin, classes.textField)}
                        value={parameterAmplitude}
                        onChange={this.setValue("parameterAmplitude")}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />

                    </Grid>

                    <Grid
                      item
                      md={12}
                      xl={12}
                      xs={12}
                      className={classes.demo}
                    >

                      <TextField
                        fullWidth
                        label="Tabella valori"
                        className={classNames(classes.margin, classes.textField)}
                        value={parameterValuesTable}
                        onChange={this.setValue("parameterValuesTable")}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>


                    <Grid
                      item
                      md={12}
                      xl={12}
                      xs={12}
                      className={classes.demo}
                    >

                      <TextField
                        fullWidth
                        label="Note"
                        className={classNames(classes.margin, classes.textField)}
                        value={parameterNote}
                        onChange={this.setValue("parameterNote")}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                  </Grid>

                  <Grid
                    container
                    spacing={1}
                    justify="center"
                    md={12}
                    xl={12}
                    xs={12}
                  >

                    <Button type="submit" style={{ marginTop: "21px" }} color="primary" variant="outlined" className={classes.button}>
                      <SaveIcon />
                      {isParameterToUpdate ? 'Modifica' : 'Salva'} Parametro
                </Button>

                  </Grid>
                </>
              }
            </Grid>
          </Grid>
        </form>
        <br></br>

        { isOpenMessage && <Snackbar
          open={isOpenMessage}
          autoHideDuration={10000}
          onClose={() => this.openMessage("", "info")}
        >
          <Alert
            onClose={() => this.openMessage("", "info")}
            severity={severity}>
            {textMessage}
          </Alert>
        </Snackbar>}

        <SessionExpiredModal
          handleClose={this.closeSessionExpiredModal}
          open={isOpenSessionExpiredModal}
        />

        <DeleteConfirmDialog
          handleClose={this.openDeleteConfirmDialog}
          open={isOpenDeleteConfirmDialog}
          title={"Sei sicuro di voler eliminare il Parametro " + investigationParamaterIdToDelete + " ?"}
          text=""
          confirmFunction={() => this.deleteParameter(investigationParamaterIdToDelete)}
        />

      </Fragment >
    );
  }
}

punctualSiteParameters.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(punctualSiteParameters);