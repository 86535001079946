import React, { Component } from "react";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, Snackbar } from '@material-ui/core/';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import DeleteIcon from '@material-ui/icons/Delete';
import MuiAlert from '@material-ui/lab/Alert';

//COMPONENTS
import LinearSiteInfo from './linearSiteInfo';
import LinearSiteInvestigations from './linearSiteInvestigations';
import LinearSiteParameters from './linearSiteParameters';
import DeleteConfirmDialog from '../istructionsToolsDialog/DeleteConfirmDialog';

// API REQUEST
import axios from 'axios';
import {
  DeleteLinearSite
} from '../../../backEnd/requestAPI';

const styles = {
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
  toolbarButtons: {
    marginLeft: 'auto',
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class linearSiteDialogView extends Component {

  constructor(props) {
    super(props);

    this.state = {
      //Data
      currentLinearSiteID: undefined,
      investigationID: undefined,
      investigationType: undefined,

      // CONTROLLER
      isViewInvestigations: false,
      isViewInvestigationParams: false,
      closeDialog: props.closeDialog,
      isOpenDeleteConfirmDialog: false,
      textMessage: "",
      severity: "info",
      isOpenMessage: false
    };
  }


  onOpenViewInvestigations = (LinearSiteID) => {
    this.setState({
      isViewInvestigations: true,
      currentLinearSiteID: LinearSiteID
    })
  }
  onCloseViewInvestigations = () => {
    this.setState({
      isViewInvestigations: false
    })
  }

  openViewInvestigationParams = (currentInvestigationID, typeSelected) => {
    this.setState({
      isViewInvestigationParams: true,
      investigationID: currentInvestigationID,
      investigationType: typeSelected

    })
  }
  closeViewInvestigationParams = () => {
    this.setState({
      isViewInvestigationParams: false
    })
  }


  deleteLinearSite = (linearSiteID) => {
    const { closeDialog } = this.state
    const config = {
      params: {
        idSitoLineare: linearSiteID
      },
      headers: { Authorization: `Bearer ${localStorage.getItem("USER_TOKEN")}` }
    };

    axios.delete(DeleteLinearSite,
      config).then(response => {
        this.openMessage("Sito Lineare Eliminato", "success")
        setTimeout(() => {
          this.openDeleteConfirmDialog("")
          closeDialog()
        }, 2000);


      }).catch(error => {
        alert(error);
      });
  }

  openDeleteConfirmDialog = () => {
    this.setState(prevState => ({
      isOpenDeleteConfirmDialog: !prevState.isOpenDeleteConfirmDialog,
    }))
  }

  openMessage = (text, type) => {
    this.setState(prevState => ({
      isOpenMessage: !prevState.isOpenMessage,
      textMessage: text,
      severity: type
    }))
  }

  render() {
    const { classes, isOpen, linearSite, isNewSite, coordinatesType } = this.props;
    const { isViewInvestigations, isViewInvestigationParams,
      currentLinearSiteID, investigationID, investigationType, closeDialog,
      isOpenDeleteConfirmDialog, isOpenMessage, textMessage, severity
    } = this.state;

    return (
      <div>
        <Dialog fullScreen open={isOpen} onClose={closeDialog} TransitionComponent={Transition}>
          <AppBar className={classes.appBar}>
            <Toolbar style={{background: '#2F2483'}}>
              <IconButton edge="start" color="inherit" onClick={() => (closeDialog(), this.onCloseViewInvestigations())} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Scheda Sito Lineare
            </Typography>
              <div className={classes.toolbarButtons}>

                {!isNewSite &&
                  <Button className={classes.button} variant="outlined" color="secondary" onClick={this.openDeleteConfirmDialog}>
                    <DeleteIcon className={classes.rightIcon} />
                  Elimina scheda
                </Button>}

              </div>
            </Toolbar>
          </AppBar>
          <LinearSiteInfo
            onViewInvestigations={this.onOpenViewInvestigations}
            linearSite={linearSite}
            coordinatesType={coordinatesType}
          />

          {isViewInvestigations &&
            <LinearSiteInvestigations
              openViewInvestigationParams={this.openViewInvestigationParams}
              closeViewInvestigationParams={this.closeViewInvestigationParams}
              LinearSiteID={currentLinearSiteID}
            />}

          {isViewInvestigationParams &&
            <LinearSiteParameters
              investigationID={investigationID}
              investigationType={investigationType}
            />}

        </Dialog>


        <DeleteConfirmDialog
          handleClose={this.openDeleteConfirmDialog}
          open={isOpenDeleteConfirmDialog}
          title={"Sei sicuro di voler eliminare il Sito Puntuale " + linearSite.id + " ?"}
          text="Eliminando il Sito Puntuale, verrano eliminate le indagini e i parametri correlati"
          confirmFunction={() => this.deleteLinearSite(linearSite.id)}
        />

        { isOpenMessage && <Snackbar
          open={isOpenMessage}
          autoHideDuration={10000}
          onClose={() => this.openMessage("", "info")}
        >
          <Alert
            onClose={() => this.openMessage("", "info")}
            severity={severity}>
            {textMessage}
          </Alert>
        </Snackbar>}

      </div>
    );
  }
}

linearSiteDialogView.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(linearSiteDialogView);
