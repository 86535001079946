import React from "react";
import mainConfig from "../config.json";
import moment from "moment";
export const InfoRow = props => {

  // CONVERT URL'S TO LINKS
  let value = props.value;
  var formats = [
    moment.ISO_8601,
    "YYYY-MM-DDZ",
  ];

  if (
    props.value != null &&
    props.value
      .toString()
      .substring(0, 4)
      .toUpperCase() === "HTTP"
  ) {
    value = (
      <a href={props.value} target="_blank" rel="noopener noreferrer">
        {props.value}
      </a>
    );
  } else if (
    props.value != null &&
    props.value
      .toString()
      .substring(0, 2)
      .toUpperCase() === "\\\\"
  ) {
    value = (
      <a href={mainConfig.imageViewerUrl + props.value} target="_blank" rel="noopener noreferrer">
        {props.value}
      </a>
    );
  }
  /* Controllo se il dato ricevuto è un numero */
  else if (props.value != null && typeof props.value === 'number') {
    value = props.value.toString();
  }

  else if (
    props.value != null &&
    moment(props.value, formats, true).isValid()
  ) {
    value = moment(props.value).format("DD-MM-YYYY");
  }

  function validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
  }

  return (
    /*<div className="sc-info-window-row">
       <div className="sc-info-window-label">{props.label}</div>
       <div className={props.imageData ? "sc-hidden" : "sc-info-window-value"}>
         {value}
         {props.children}
       </div>
     </div>*/

    <div class="divTable">
      <div class="divTableBody">
        <div class="divTableRow">
          <div class="divTableCellTitle" >{props.label}</div>
          <div class="divTableCellValue" >
            {validURL(value) && <a href={value}>Apri File</a>}
            {value}
            {props.children}
          </div>
        </div>
      </div>
    </div>


  );
};

export default InfoRow;

export const InfoRowValue = props => {
  return (
    <div
      className={"sc-info-window-value " + props.className}
      onClick={() => {
        props.onClick(props.feature);
      }}
    >
      {props.value}
      {props.children}{" "}
    </div>
  );
};
