import React, { Component } from "react";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import {
  Typography
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { Row } from 'reactstrap';


const styles = {
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
  toolbarButtons: {
    marginLeft: 'auto',
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class NoteAppDialog extends Component {





  render() {
    const { classes, isOpen, closeDialog } = this.props;


    return (
      <div>
        <Dialog fullScreen open={isOpen} onClose={closeDialog} TransitionComponent={Transition}>
          <AppBar className={classes.appBar}>
            <Toolbar style={{background: '#2F2483'}}>
              <IconButton edge="start" color="inherit" onClick={() => closeDialog()} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Note esplicative
              </Typography>
            </Toolbar>
          </AppBar>

          <Row className="app-row survey-app" style={{ margin: '21px' }}>


            <p style={{ lineHeight: '1.5' }}><strong> MAPPA DELLE INTERFERENZE IDRAULICHE: NOTE ESPLICATIVE</strong></p>

            <p style={{ lineHeight: '1.5' }}>
              La mappa delle interferenze tra idrografia, reti infrastrutturali e urbanizzazione va utilizzata per la definizione dei <strong>possibili scenari di rischio idraulico nell&rsquo;ambito della pianificazione di protezione civile</strong> e per una prima disamina delle criticit&agrave; idrauliche finalizzata alla selezione degli interventi strutturali necessari alla mitigazione del rischio. In tal senso, in corrispondenza delle aree mappate dovranno essere avviati controlli specifici<strong> (censimento con schede DRPC, analisi idrauliche</strong>).</p>
            <p style={{ lineHeight: '1.5' }}>Il reticolo idrografico naturale, la rete stradale e ferroviaria e le aree urbanizzate sono stati digitalizzati sulla base cartografica a scala 1:10.000 (CTR della Regione Siciliana, 2008); per aumentare il dettaglio a scale maggiori, &egrave; stato fatto uso anche delle immagini satellitari (Google Earth) e del DTM (Regione Siciliana, 2012). Tuttavia, &egrave; possibile che le tracce che identificano gli elementi costitutivi della mappa siano imperfette sia come numero, sia come geometria, in special modo in aree boscate e in corrispondenza delle aree urbanizzate e soggette a intense trasformazioni antropiche. In ragione della variabilit&agrave; del grado di accuratezza cartografica, &egrave; quindi possibile che alcune interferenze idrauliche non siano rappresentate.</p>
            <p style={{ lineHeight: '1.5' }}>Per quanto riguarda il significato da dare alla mappa delle interferenze idrauliche, occorre precisare che:</p>
            <ol type="a" style={{ lineHeight: '1.5' }}>
              <li>quelle mappate <strong>non sono da considerarsi aree di esondazione</strong>; si tratta dell&rsquo;evidenziazione di zone nelle quali &egrave; possibile che i beni vulnerabili siano oggetto di criticit&agrave; causate da deflussi idrici significativi lungo i corsi d&rsquo;acqua senza tenere conto di anomalie quali l&rsquo;integrit&agrave; delle sponde, le eventuali insufficienze delle sezioni idrauliche e/o la presenza di impedimenti al libero deflusso delle acque di piena, n&eacute; irregolarit&agrave; idro-morfologiche; in tali casi e qualora le precipitazioni assumano carattere eccezionale, le piene fluviali possono causare eventi alluvionali in areali pi&ugrave; estesi di quelli mappati;</li>
              <li>le interferenze non tengono conto in alcun modo del moto verso valle dei deflussi idrici;</li>
              <li>nelle more dell&rsquo;assimilazione nei Siti di attenzione del PAI e del PGRA, le aree mappate non rappresentano vincoli di alcun tipo;</li>
              <li>le aree mappate non tengono conto delle quote dei vettori dei differenti livelli informativi; pertanto, corsi d&rsquo;acqua incassati rispetto al piano-campagna circostante e corsi d&rsquo;acqua poco definiti dal punto di vista morfologico vengono trattati allo stesso modo;</li>
              <li>le case sparse, rientrando nella classe &ldquo;4&rdquo; del database dell&rsquo;ISTAT, non sono state identificate e quindi per tali beni non vengono evidenziate le eventuali interferenze con i corsi d&rsquo;acqua;</li>
              <li>non vengono considerati i deflussi idrici estranei alla rete idrografica naturale, n&eacute; quelli lungo le aste artificiali (canali di bonifica e simili).</li>
            </ol>

      <p style={{ lineHeight: '1.5' }}><strong>&nbsp;</strong></p>
            <p style={{ lineHeight: '1.5' }}><strong>DICHIARAZIONE DI ESCLUSIONE DI RESPONSABILIT&Agrave;</strong></p>
            <p style={{ lineHeight: '1.5' }}>In assenza di specifici studi di dettaglio, l'utilizzo della mappa delle interferenze idrauliche deve essere inteso con le connotazioni e i limiti di cui alle note esplicative.</p>
            <p style={{ lineHeight: '1.5' }}>Il Dipartimento Regionale della Protezione Civile o le persone che agiscono per conto di esso non sono responsabili per eventuali danni indiretti, incidentali o derivati connessi con l&rsquo;utilizzo dei dati distribuiti.</p>
            <p style={{ lineHeight: '1.5' }}><strong>&nbsp;</strong></p>
      
            <p style={{ lineHeight: '1.5' }}><strong>MAPPA DI PROPENSIONE AL DISSESTO GEOMORFOLOGICO: NOTE ESPLICATIVE</strong></p>
            <p style={{ lineHeight: '1.5' }}>Il termine "propensione" (o suscettivit&agrave;) indica che esistono "teoriche" condizioni predisponenti, governate dalla natura litologica dei pendii e dall&rsquo;acclivit&agrave;, che rendono i versanti soggetti a fenomeni franosi qualora si presentino fattori innescanti (in genere, le precipitazioni).</p>
            <p style={{ lineHeight: '1.5' }}>Le litologie di riferimento sono classificate nei loro aspetti generali e non tengono conto delle caratterizzazioni geologico-tecniche che possono influenzare in modo determinante l&rsquo;evoluzione gravitativa di un versante. Pertanto, in assenza di parametri identificativi degli altri fattori che governano la stabilit&agrave; dei versanti (caratteristiche idrogeologiche, fisiche e geotecniche delle terre e delle rocce, fattori locali, ecc.)&nbsp; la mappa fornisce la dissestabilit&agrave; in termini probabilistici.</p>
            <p style={{ lineHeight: '1.5' }}>L'elaborato &egrave; il risultato dell'incrocio tra acclivit&agrave; dei versanti, derivata dal Modello Digitale del Terreno della Regione Siciliana (mesh 2x2 metri, versione 2012), e litologia, derivata dalle carte geologiche disponibili alla scala 1:50.000, con le inevitabili approssimazioni che ne derivano per le applicazioni di dettaglio.</p>
            <p style={{ lineHeight: '1.5' }}>I range caratteristici dell'acclivit&agrave; dei versanti sono il risultato di una procedura di "addestramento" del modello, mediante l'input di circa 9000 fenomeni franosi (distinti in deformazioni di versante, scorrimenti, scivolamenti e crolli), riconosciuti dalle immagini di Google Earth, ed estrazione dell'inclinazione media da buffer di dimensioni variabili attorno a ciascun elemento.</p>
            <p style={{ lineHeight: '1.5' }}>Per ovviare alle inevitabili approssimazioni insite nella procedura, i dati sono stati trattati con procedure statistiche (probabilit&agrave; stimata di adesione del modello alla realt&agrave; pari al 90% circa).</p>
            <p style={{ lineHeight: '1.5' }}>Nella rappresentazione tematica &egrave; stata utilizzata una mesh pari a 20x20 metri; di conseguenza, non possono essere visualizzate condizioni di propensione al dissesto geomorfologico la cui proiezione sul piano orizzontale sia inferiore alla dimensione della maglia (per esempio, scarpate con altezza minore di 20 metri e inclinazione maggiore di 45&deg;).</p>
            <p style={{ lineHeight: '1.5' }}>&nbsp;</p>
            <p style={{ lineHeight: '1.5' }}><strong>DICHIARAZIONE DI ESCLUSIONE DI RESPONSABILIT&Agrave;</strong></p>
            <p style={{ lineHeight: '1.5' }}>La mappa di propensione al dissesto geomorfologico &egrave; stata prodotta per individuare le Zone Omogenee di Allerta per finalit&agrave; connesse alla elaborazione degli Avvisi regionali di protezione civile per il Rischio Meteo-Idrogeologico e Idraulico. Pertanto, in assenza di specifici studi di dettaglio, l'utilizzo della mappa per finalit&agrave; diverse deve essere inteso con le connotazioni e i limiti di cui alle note esplicative.</p>
            <p style={{ lineHeight: '1.5' }}>Il Dipartimento Regionale della Protezione Civile o le persone che agiscono per conto di esso non sono responsabili per eventuali danni indiretti, incidentali o derivati connessi con l&rsquo;utilizzo dei dati distribuiti.</p>
            <p style={{ lineHeight: '1.5' }}>&nbsp;</p>

          </Row>
        </Dialog>

      </div>
    );
  }
}

NoteAppDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NoteAppDialog);
