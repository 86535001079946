import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  TextField, MenuItem, Grid, InputAdornment, Typography, Snackbar
} from '@material-ui/core';
import classNames from 'classnames';
import MuiAlert from '@material-ui/lab/Alert';


// API REQUEST
import axios from 'axios';
import {
  GetComboBoxChild, ComboBoxCode
} from '../../../backEnd/requestAPI';

//IsoDate Convert
import moment from 'moment';

const styles = theme => ({
  demo: {
    height: 'auto',
  },
  divider: {
    margin: `${theme.spacing(3)}px 0`,
  },
  input: {
    margin: theme.spacing(3),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(3),
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class InfoGeolocalizzazione extends Component {

  constructor(props) {
    super(props);

    this.state = {

      // COMBO BOX
      pericolositaPAIList: [],
      rischioPAIList: [],

      // CONTROLLER
      closeDialog: props.closeDialog,
      textMessage: "",
      severity: "info",
      isOpenMessage: false
    };

  }

  componentDidMount() {
    this.getPericolositaPAIComboBox()
    this.getRischioPAIComboBox()
  }

  openMessage = (text, type) => {
    this.setState(prevState => ({
      isOpenMessage: !prevState.isOpenMessage,
      textMessage: text,
      severity: type
    }))
  }



  getPericolositaPAIComboBox = () => {
    const { nodoType } = this.props

    const config = {
      params: {
        idEnum: nodoType === "Nodo_frana" ? ComboBoxCode.cl_pai_pericolo_frana : ComboBoxCode.cl_pai_pericolo_idro
      },
      headers: { Authorization: `Bearer ${localStorage.getItem("USER_TOKEN")}` }
    };

    axios.get(GetComboBoxChild,
      config).then(response => {
        this.setState({
          pericolositaPAIList: response.data,
        })
      }).catch(error => {
        this.openMessage("Errore sconosciuto, contattare assistenza", "error")
      });
  }

  getRischioPAIComboBox = () => {
    const config = {
      params: {
        codeAttr: ComboBoxCode.cl_pai_rischio
      },
      headers: { Authorization: `Bearer ${localStorage.getItem("USER_TOKEN")}` }
    };

    axios.get(GetComboBoxChild,
      config).then(response => {
        this.setState({
          rischioPAIList: response.data,
        })
      }).catch(error => {
        this.openMessage("Errore sconosciuto, contattare assistenza", "error")
      });
  }

  render() {
    const { classes, saveValue, nodoType } = this.props;
    const {
      statoScheda,
      viewLng,
      viewLat,
      nodoLocalita,
      nodoNomeBacino,
      nodoCodeBacino,
      nodoCodeDiga,
      nodoQuota,
      nodoDataUltimoEvento,
      nodoCodiceScheda,
      nodoVersione,
      nodoLatitudinePlan,
      nodoLongitudinePlan,
      nodoNote,
      nodoProvince,
      nodoCity,
      nodoPericolositaIdEnumSelected,
      nodoRischioIdEnumSelected,

      IGM125,
      IGM150,
      CTRN110,
      CTRN12,
      nodoDighe

    } = this.props.nodo

    const {

      // COMBO BOX
      pericolositaPAIList,
      rischioPAIList,

      //CONTROLLER
      isOpenMessage,
      textMessage,
      severity,
    } = this.state



    return (
      <Fragment >
        <br />

        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          spacing={2}
          style={{ padding: '21px' }}
        >
          <Grid
            container
            justify="center"
            md={12}
            xl={12}
            xs={12}
          >

            <Grid
              item
              md={12}
              xl={12}
              xs={12}
            >
              <div style={{ background: '#f0f9ff', padding: '8px', marginBottom: '21px', marginTop: '21px' }}>
                <Typography variant="h6" className={classes.title} >
                  GEOLOCALIZZAZIONE NODO E DATI DI CONTESTO
                </Typography>
              </div>
            </Grid>

            <Grid
              container
              spacing={1}
              md={12}
              xl={4}
              xs={12}
            >
              <Grid
                item
                md={6}
                xl={8}
                xs={12}
              >
                <TextField
                  fullWidth
                  disabled
                  label="Provincia"
                  className={classNames(classes.margin, classes.textField)}
                  value={nodoProvince}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid
                item
                md={6}
                xl={8}
                xs={12}
              >
                <TextField
                  fullWidth
                  disabled
                  label="Comune"
                  className={classNames(classes.margin, classes.textField)}
                  value={nodoCity}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid
                item
                md={6}
                xl={8}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Località"
                  className={classNames(classes.margin, classes.textField)}
                  InputLabelProps={{ shrink: true }}
                  value={nodoLocalita}
                  onChange={event => saveValue("nodoLocalita", event.target.value)}
                  margin="normal"
                  variant="outlined"
                />

              </Grid>

              <Grid
                item
                md={6}
                xl={8}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Bacino idrografico principale"
                  className={classNames(classes.margin, classes.textField)}
                  InputLabelProps={{ shrink: true }}
                  value={nodoCodeBacino + "/" + nodoNomeBacino}
                  margin="normal"
                  variant="outlined"
                  disabled
                />

              </Grid>

              {nodoType === "Nodo_idro" &&
                <Grid
                  item
                  md={6}
                  xl={8}
                  xs={12}
                >

                  <TextField
                    required
                    fullWidth
                    select
                    label="Diga a monte"
                    className={classNames(classes.margin, classes.textField)}
                    value={String(nodoCodeDiga)}
                    onChange={event => {
                      saveValue("nodoCodeDiga", event.target.value)
                    }}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    margin="normal"
                    variant="outlined"
                  >
                    {nodoDighe.map(option => (
                      <MenuItem key={option.codeDiga} value={option.codeDiga}>
                        {option.nomeDiga}
                      </MenuItem>
                    ))}
                  </TextField>


                </Grid>
              }


            </Grid>


            <Grid
              container
              spacing={1}
              justify="center"
              md={12}
              xl={8}
              xs={12}
            >

              <Grid
                item
                md={6}
                xl={3}
                xs={6}
                className={classes.demo}
              >

                <TextField
                  fullWidth
                  label="Quota"
                  className={classNames(classes.margin, classes.textField)}
                  value={nodoQuota}
                  onChange={event => saveValue("nodoQuota", event.target.value)}
                  margin="normal"
                  variant="outlined"
                  type="number"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">(m)</InputAdornment>,
                  }}
                />
              </Grid>


              <Grid
                item
                md={6}
                xl={3}
                xs={6}
                className={classes.demo}
              >

                <TextField
                  fullWidth
                  label="Ultimo evento conosciuto"
                  type="date"
                  className={classNames(classes.margin, classes.textField)}
                  value={nodoDataUltimoEvento}
                  onChange={event => saveValue("nodoDataUltimoEvento", event.target.value)}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{ inputProps: { max: moment(new Date()).format('YYYY-MM-DD') } }}
                />
              </Grid>


              <Grid
                item
                md={6}
                xl={3}
                xs={6}
                className={classes.demo}
              >

                <TextField
                  fullWidth
                  label="Codice Scheda"
                  className={classNames(classes.margin, classes.textField)}
                  value={nodoCodiceScheda}
                  margin="normal"
                  variant="outlined"
                  disabled
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              <Grid
                item
                md={6}
                xl={3}
                xs={6}
                className={classes.demo}
              >

                <TextField
                  fullWidth
                  label="Versione"
                  className={classNames(classes.margin, classes.textField)}
                  value={ statoScheda === "Validato" ? nodoVersione + 1 : nodoVersione}
                  type="number"
                  margin="normal"
                  variant="outlined"
                  disabled
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>


              <Grid
                item
                md={6}
                xl={3}
                xs={6}
                className={classes.demo}
              >

                <TextField
                  fullWidth
                  disabled
                  label="Latitudine"
                  className={classNames(classes.margin, classes.textField)}
                  value={viewLat}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid
                item
                md={6}
                xl={3}
                xs={6}
                className={classes.demo}
              >

                <TextField
                  fullWidth
                  disabled
                  label="Longitudine"
                  className={classNames(classes.margin, classes.textField)}
                  value={viewLng}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid
                item
                md={6}
                xl={3}
                xs={6}
                className={classes.demo}
              >

                <TextField
                  fullWidth
                  disabled
                  label="Est"
                  className={classNames(classes.margin, classes.textField)}
                  value={nodoLongitudinePlan}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid
                item
                md={6}
                xl={3}
                xs={6}
                className={classes.demo}
              >

                <TextField
                  fullWidth
                  disabled
                  label="Nord"
                  className={classNames(classes.margin, classes.textField)}
                  value={nodoLatitudinePlan}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid
                item
                md={6}
                xl={3}
                xs={6}
                className={classes.demo}
              >

                <TextField
                  fullWidth
                  disabled
                  label="IGM(1:25.000)"
                  className={classNames(classes.margin, classes.textField)}
                  value={IGM125}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid
                item
                md={6}
                xl={3}
                xs={6}
                className={classes.demo}
              >

                <TextField
                  fullWidth
                  disabled
                  label="IGM(1:50.000)"
                  className={classNames(classes.margin, classes.textField)}
                  value={IGM150}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid
                item
                md={6}
                xl={3}
                xs={6}
                className={classes.demo}
              >

                <TextField
                  fullWidth
                  disabled
                  label="CTRN(1:10.000)"
                  className={classNames(classes.margin, classes.textField)}
                  value={CTRN110}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>


              <Grid
                item
                md={6}
                xl={3}
                xs={6}
                className={classes.demo}
              >

                <TextField
                  fullWidth
                  disabled
                  label="CTRN(1:2.000)"
                  className={classNames(classes.margin, classes.textField)}
                  value={CTRN12}
                  margin="normal"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>

              <Grid
                item
                md={6}
                xl={6}
                xs={12}
                className={classes.demo}
              >
                <TextField
                  required
                  fullWidth
                  select
                  label="Pericolosità PAI di prossimità"
                  className={classNames(classes.margin, classes.textField)}
                  value={String(nodoPericolositaIdEnumSelected)}
                  onChange={event => saveValue("nodoPericolositaIdEnumSelected", event.target.value)}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  margin="normal"
                  variant="outlined"
                >
                  {pericolositaPAIList.map(option => (
                    <MenuItem key={option.idEnum} value={option.idEnum}>
                      {option.comboValue}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>


              <Grid
                item
                md={6}
                xl={6}
                xs={12}
                className={classes.demo}
              >
                <TextField
                  required
                  fullWidth
                  select
                  label="Rischio PAI di prossimità"
                  className={classNames(classes.margin, classes.textField)}
                  value={String(nodoRischioIdEnumSelected)}
                  onChange={event => saveValue("nodoRischioIdEnumSelected", event.target.value)}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  margin="normal"
                  variant="outlined"
                >
                  {rischioPAIList.map(option => (
                    <MenuItem key={option.idEnum} value={option.idEnum}>
                      {option.comboValue}
                    </MenuItem>
                  ))}
                </TextField>

              </Grid>

            </Grid>




            <Grid
              item
              md={12}
              xl={12}
              xs={12}
              className={classes.demo}
              style={{ marginBottom: "21px" }}
            >
              <TextField
                fullWidth
                label="Brevi note sul contesto (max 330 car)"
                className={classNames(classes.margin, classes.textField)}
                value={nodoNote}
                onChange={event => saveValue("nodoNote", event.target.value)}
                margin="normal"
                variant="outlined"
                multiline
                rows={4}
                inputProps={{ maxLength: 330 }}
                helperText={nodoNote.length === 330 ? "Massimo 330 caratteri accettati" : ""}
                error={nodoNote.length === 330}
              />
            </Grid>


          </Grid>
        </Grid>
        <br></br>

        {isOpenMessage && <Snackbar
          open={isOpenMessage}
          autoHideDuration={10000}
          onClose={() => this.openMessage("", "info")}
        >
          <Alert
            onClose={() => this.openMessage("", "info")}
            severity={severity}>
            {textMessage}
          </Alert>
        </Snackbar>}

      </Fragment >
    );
  }
}

InfoGeolocalizzazione.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(InfoGeolocalizzazione);