import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  TextField, MenuItem, Grid, Typography, Snackbar
} from '@material-ui/core';
import classNames from 'classnames';
import MuiAlert from '@material-ui/lab/Alert';



// API REQUEST
import axios from 'axios';
import {
  GetComboBoxChild, ComboBoxCode
} from '../../../backEnd/requestAPI';

const styles = theme => ({
  demo: {
    height: 'auto',
  },
  divider: {
    margin: `${theme.spacing(3)}px 0`,
  },
  input: {
    margin: theme.spacing(3),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(3),
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class infoCommercioRetiServizi extends Component {

  constructor(props) {
    super(props);

    this.state = {

      saveValue: props.saveValue,


      // CONTROLLER
      closeDialog: props.closeDialog,
      textMessage: "",
      severity: "info",
      isOpenMessage: false
    };

  }

  componentDidMount() {
    this.getEsposizioniComboBox()
  }

  getEsposizioniComboBox = () => {
    const { saveValue } = this.state

    const config = {
      params: {
        codeAttr: ComboBoxCode.cl_esp_crs,
      },
      headers: { Authorization: `Bearer ${localStorage.getItem("USER_TOKEN")}` }
    };
    axios.get(GetComboBoxChild,
      config).then(response => {
        saveValue("esposizioniCommercioRetiServiziList", response.data)
      }).catch(error => {
        this.openMessage("Errore sconosciuto, contattare assistenza", "error")
      });
  }

  getVulnerabilitaComboBox = (idEnumEsposizione) => {
    const { saveValue } = this.state
    const config = {
      params: {
        idEnum: idEnumEsposizione
      },
      headers: { Authorization: `Bearer ${localStorage.getItem("USER_TOKEN")}` }
    };
    axios.get(GetComboBoxChild,
      config).then(response => {
        saveValue("vulnerabilitaCommercioRetiServiziList", response.data)
      }).catch(error => {
        this.openMessage("Errore sconosciuto, contattare assistenza", "error")
      });
  }



  openMessage = (text, type) => {
    this.setState(prevState => ({
      isOpenMessage: !prevState.isOpenMessage,
      textMessage: text,
      severity: type
    }))
  }


  render() {
    const { classes } = this.props;

    const {
    statoScheda,
      currentNodoID,
      nodoEsposizioneCommercioRetiServiziSelected,
      nodoVulnerabilitaCommercioRetiServiziSelected,
      esposizioniCommercioRetiServiziList,
      vulnerabilitaCommercioRetiServiziList,
    } = this.props.nodo

    const {
      saveValue,
      //CONTROLLER
      isOpenMessage,
      textMessage,
      severity,
    } = this.state

    if (nodoEsposizioneCommercioRetiServiziSelected && currentNodoID && vulnerabilitaCommercioRetiServiziList.length === 0) this.getVulnerabilitaComboBox(nodoEsposizioneCommercioRetiServiziSelected)


    return (
      <Fragment>
        <br />

        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          style={{ padding: '21px' }}
        >
          <Grid
            container
            justify="center"
            md={12}
            xl={12}
            xs={12}
          >

            <Grid
              item
              md={12}
              xl={12}
              xs={12}
            >
              <div style={{ background: '#f0f9ff', padding: '8px', marginBottom: '21px', marginTop: '21px' }}>
                <Typography variant="h6" className={classes.title} >
                  COMMERCIO - RETI E SERVIZI
                </Typography>
              </div>
            </Grid>

            <Grid
              container
              spacing={1}
              md={12}
              xl={12}
              xs={12}
            >

              <Grid
                item
                md={12}
                xl={12}
                xs={12}
              >
                <TextField
                  required
                  fullWidth
                  select
                  label="Esposizione"
                  className={classNames(classes.margin, classes.textField)}
                  value={String(nodoEsposizioneCommercioRetiServiziSelected)}
                  onChange={event => {
                    saveValue("nodoEsposizioneCommercioRetiServiziSelected", event.target.value)
                    this.getVulnerabilitaComboBox(event.target.value)
                  }}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  margin="normal"
                  variant="outlined"
                >
                  {esposizioniCommercioRetiServiziList.map(option => (
                    <MenuItem key={option.idEnum} value={option.idEnum}>
                      {option.comboValue}
                    </MenuItem>
                  ))}
                </TextField>

              </Grid>

              <Grid
                item
                md={12}
                xl={12}
                xs={12}
              >
                <TextField
                  required
                  fullWidth
                  select
                  label="Vulnerabilità"
                  className={classNames(classes.margin, classes.textField)}
                  value={String(nodoVulnerabilitaCommercioRetiServiziSelected)}
                  onChange={event => saveValue("nodoVulnerabilitaCommercioRetiServiziSelected", event.target.value)}
                  disabled={vulnerabilitaCommercioRetiServiziList.length == 0}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  margin="normal"
                  variant="outlined"
                >
                  {vulnerabilitaCommercioRetiServiziList.map(option => (
                    <MenuItem key={option.idEnum} value={option.idEnum}>
                      {option.comboValue}
                    </MenuItem>
                  ))}
                </TextField>

              </Grid>

            </Grid>

          </Grid>
        </Grid>
        <br></br>

        {isOpenMessage && <Snackbar
          open={isOpenMessage}
          autoHideDuration={10000}
          onClose={() => this.openMessage("", "info")}
        >
          <Alert
            onClose={() => this.openMessage("", "info")}
            severity={severity}>
            {textMessage}
          </Alert>
        </Snackbar>}

      </Fragment >
    );
  }
}

infoCommercioRetiServizi.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(infoCommercioRetiServizi);