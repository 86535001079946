import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import { Grid, Snackbar, CircularProgress } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';



// API REQUEST
import axios from 'axios';
import {
  RecoveryPassword, GetUserInfo, UpdateUserData
} from '../backEnd/requestAPI';


import packageJson from '../../package.json';
import moment from 'moment';
import preval from 'preval.macro';
const buildTimestamp = preval`module.exports = new Date().getTime();`;


const styles = {
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
};

function getDateString() {
  const lastUpdateMoment = moment.unix(buildTimestamp / 1000);
  const formattedDate = lastUpdateMoment.format('DD.MM.YYYY HH:mm:ss');

  return formattedDate;
};


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      <Link color="inherit" href="#">
      <p><b>Versione {packageJson.version} del {getDateString()}  </b> </p>
      </Link>
    </Typography>
  );
}

class ProfileUserDialog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {

      //Dati
      username: null,
      email: null,
      name: null,
      surname: null,
      bornData: null,
      gender: null,
      phone1: null,
      phone2: null,
      workType: null,
      workCompany: null,


      isOpenMessage: false,
      textMessage: '',
      isErrorAuthorized: false,
      isLoadingRecoveryPassword: false,
      isLoadingUpdateUser: false
    };

  };

  componentDidMount() {
    this.getUserInfo()
  }


  //Aggiorna parametri inseriti da form
  setValue = name => event => {
    let value = event.target.value
    this.setState({ [name]: value });
  };



  getUserInfo = () => {

    const config = {
      params: {
        idUtente: localStorage.getItem("USER_ID"),
      },
      headers: { Authorization: `Bearer ${localStorage.getItem("USER_TOKEN")}` }
    };

    axios.get(GetUserInfo,
      config).then(response => {
        let user = response.data;
        this.setState({
          username: user.username,
          email: user.email,
          name: user.nome,
          surname: user.cognome,
          bornData: user.dataDiNascita,
          gender: user.sesso,
          phone1: user.telefono1,
          phone2: user.telefono2,
          workType: user.lavoro,
          workCompany: user.societa,
        })
      }).catch(error => {
        alert(error)
      });
  }


  recoveryPassword = event => {
    event.preventDefault();

    this.setState({
      isLoadingRecoveryPassword: true,
      isErrorAuthorized: false
    })


    const config = {
      params: {
        idUtente: localStorage.getItem("USER_ID"),
      },
      headers: { Authorization: `Bearer ${localStorage.getItem("USER_TOKEN")}` }
    };

    let user = {
      vecchiaPassword: event.target.oldpassword.value,
      nuovaPassword: event.target.newpassword.value
    }

    axios.put(RecoveryPassword,
      user, config).then(response => {
        this.setState({
          isErrorAuthorized: false,
          isLoadingRecoveryPassword: false
        }, () => {
          this.openMessage("Password cambiata con successo!")
        })
      }).catch(error => {
        this.setState({
          isLoadingRecoveryPassword: false,
          isErrorAuthorized: true
        })
        let errorResponse = error.response.data
        if (errorResponse.statusCode == 401) {
          this.openMessage("Utente non autorizzato")
        } else if (errorResponse.statusCode == 403) {
          this.openMessage("Utente non autorizzato su questo Comune")
        } else {
          alert(error);
        }
      });
  }


  updateDataUser = event => {
    event.preventDefault();

    const {
      //Dati
      username,
      email,
      name,
      surname,
      bornData,
      gender,
      phone1,
      phone2,
      workType,
      workCompany,
    } = this.state

    this.setState({ isLoadingUpdateUser: true })

    const config = {
      headers: { Authorization: `Bearer ${localStorage.getItem("USER_TOKEN")}` }
    };

    let user = {
      username: username,
      nome: name,
      cosgnome: surname,
      dataDiNascita: bornData,
      email: email,
      telefono1: phone1,
      telefono2: phone2,
      lavoro: workType,
      sesso: gender,
      societa: workCompany
    }

    axios.put(UpdateUserData,
      user, config).then(response => {
        this.setState({
          isLoadingUpdateUser: false
        }, () => {
          this.openMessage("Dati utente aggiornati con successo!")
        })
      }).catch(error => {
        this.setState({
          isLoadingUpdateUser: false,
        })
        let errorResponse = error.response.data
        if (errorResponse.statusCode == 401) {
          this.openMessage("Utente non autorizzato")
        } else if (errorResponse.statusCode == 403) {
          this.openMessage("Utente non autorizzato su questo Comune")
        } else {
          alert(error);
        }
      });
  }

  //Apre eventuali messaggi di errore
  openMessage = text => {
    this.setState(prevState => ({
      isOpenMessage: !prevState.isOpenMessage,
      textMessage: text,
    }))
  }


  render() {
    const { classes, isOpen, closeDialog } = this.props;
    const { isOpenMessage, textMessage, isErrorAuthorized, isLoadingRecoveryPassword, isLoadingUpdateUser,

      //Dati
      username,
      email, name,
      surname,
      bornData,
      gender,
      phone1,
      phone2,
      workType,
      workCompany,
    } = this.state



    return (
      <Dialog
        fullScreen
        open={isOpen}
        onClose={closeDialog}
      >

        <AppBar className={classes.appBar}>
          <Toolbar style={{background: '#2F2483'}}>
            <IconButton color="inherit" onClick={closeDialog} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.flex}>
              Profilo Utente
                  </Typography>
          </Toolbar>
        </AppBar>

        <br></br>
        <br></br>

        <form onSubmit={this.updateDataUser} className={classes.form}  style={{margin:'13px'}}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
            spacing={3}
          >
            <Grid
              container
              justify="center"
              spacing={3}
              md={10}
              xl={12}
              xs={12}
            >


              <Grid
                item
                xs={12}
                sm={6}>
                <TextField
                  variant="outlined"
                  value={username}
                  onChange={this.setValue("Username")}
                  required
                  fullWidth
                  label="Username"
                  autoFocus
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}>
                <TextField
                  variant="outlined"
                  value={email}
                  onChange={this.setValue("email")}
                  required
                  fullWidth
                  label="E-mail"
                  autoFocus
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}>
                <TextField
                  variant="outlined"
                  value={name}
                  onChange={this.setValue("name")}
                  required
                  fullWidth
                  label="Nome"
                  autoFocus
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}>
                <TextField
                  variant="outlined"
                  value={surname}
                  onChange={this.setValue("surname")}
                  required
                  fullWidth
                  label="Cognome"
                  autoFocus
                />
              </Grid>


              <Grid
                item
                xs={12}
                sm={6}>
                <TextField
                  variant="outlined"
                  value={bornData}
                  onChange={this.setValue("bornData")}
                  fullWidth
                  label="Data di Nascita"
                  autoFocus
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}>
                <TextField
                  variant="outlined"
                  value={gender}
                  onChange={this.setValue("gender")}
                  fullWidth
                  label="Sesso"
                  autoFocus
                />
              </Grid>


              <Grid
                item
                xs={12}
                sm={6}>
                <TextField
                  variant="outlined"
                  value={phone1}
                  onChange={this.setValue("phone1")}
                  fullWidth
                  label="Contatto Telefonico 1"
                  autoFocus
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}>
                <TextField
                  variant="outlined"
                  value={phone2}
                  onChange={this.setValue("phone2")}
                  fullWidth
                  label="Contatto Telefonico 2"
                  autoFocus
                />
              </Grid>


              <Grid
                item
                xs={12}
                sm={6}>
                <TextField
                  variant="outlined"
                  value={workType}
                  onChange={this.setValue("workType")}
                  fullWidth
                  label="Posizione Lavorativa"
                  autoFocus
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}>
                <TextField
                  variant="outlined"
                  value={workCompany}
                  onChange={this.setValue("workCompany")}
                  fullWidth
                  label="Società"
                  autoFocus
                />
              </Grid>


              <Grid item xs={4}>
                {isLoadingUpdateUser ?
                  <CircularProgress style={{ textAlign: "center" }} />
                  :
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    className={classes.submit}
                  >
                    SALVA DATI
                  </Button>}
              </Grid>

              <Grid item xs={12} style={{ marginTop: "21px" }}>
                <Divider />
              </Grid>
            </Grid>
          </Grid>
        </form>



        <form className={classes.form} onSubmit={this.recoveryPassword}  style={{margin:'13px'}}>
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
            spacing={3}
            style={{ marginTop: '55px' }}
          >
            <Grid
              container
              justify="center"
              spacing={3}
              md={10}
              xl={12}
              xs={12}
            >

              <Grid
                item
                xs={12}
                sm={6}>

                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="oldpassword"
                  label="Password Attuale"
                  type="password"
                  id="oldpassword"
                  autoComplete="current-password"
                  error={isErrorAuthorized}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}>

                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="newpassword"
                  label="Nuova Password"
                  type="password"
                  id="newpassword"
                  autoComplete="current-password"
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={4}>


                {isLoadingRecoveryPassword ?

                  <CircularProgress style={{ textAlign: "center" }} />

                  :
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    className={classes.submit}
                  >
                    CAMBIA PASSWORD
          </Button>
                }
              </Grid>
            </Grid>
          </Grid>
        </form>

        <Box mt={5}>
          <Copyright />
        </Box>


        <Snackbar
          open={isOpenMessage}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          autoHideDuration={5000}
          onClose={() => this.openMessage("")}
          message={<span>{textMessage}</span>}
        />

      </Dialog>
    );
  }
}

ProfileUserDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ProfileUserDialog);