import React from "react";
import "../ButtonBar.css";
import DrawButton from "../DrawButton.jsx";
import { Grid } from '@material-ui/core';

//className={props.isEditing ? "sc-mymaps-button-bar sc-disabled" : "sc-mymaps-button-bar"}
const CFDIdroEditingcard = (props) => {
  return (
    <>
     
      <div className="sc-container sc-mymaps-item-container">

        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ padding: '5px' }}
        >

          <Grid item xl={2} md={2}>
            <img height="34px" src={images["editing_labe_cfd_idro.png"]} alt="Logo Protezione Civile Nazionale" />
          </Grid>

          <Grid item xl={10} md={10}>
            <p style={{ fontSize: '12px' }}><b>Protezione Civile - CFD-Idro</b></p>
            <p style={{ fontSize: '12px' }}>Schede Frane e Idro</p>

          </Grid>

        </Grid>


        <div className={props.isEditing ? "sc-mymaps-button-bar sc-disabled" : "sc-mymaps-button-bar"}>

          <DrawButton
            title="Inserisci nuova scheda Frana"
            imageName="triangle_red.png"
            onClick={() => {
              props.onClick("Nodo_frana");
            }}
            isActive={props.activeButton === "Point" ? true : false}
          />


          <DrawButton
            title="Inserisci nuova scheda Idro"
            imageName="circle.png"
            onClick={() => {
              props.onClick("Nodo_idro");
            }}
            isActive={props.activeButton === "Point" ? true : false}
          />


          <DrawButton
            title="PDF scarica scheda vuota censimento frana"
            imageName="pdf_download_red.png"
            onClick={() => {
              // props.onClick("Nodo_frana");
            }}
            isActive={false}
            disabled
          />

          <DrawButton
            title="PDF scarica scheda vuota censimento idro"
            imageName="pdf_download_red.png"
            onClick={() => {
              // props.onClick("Nodo_frana");
            }}
            isActive={false}
            disabled
          />

          <DrawButton
            title="PDF scarica guida al caricamento"
            imageName="identify.png"
            onClick={() => {
              //  props.openInstructionsToolsDialog();
            }}
            disabled
          />
        </div>
      </div>

    </>

  );
};

export default CFDIdroEditingcard;

// IMPORT ALL IMAGES
const images = importAllImages(require.context("../images", false, /\.(png|jpe?g|svg)$/));
function importAllImages(r) {
  let images = {};
  r.keys().map((item, index) => (images[item.replace("./", "")] = r(item)));
  return images;
}