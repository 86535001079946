import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  TextField, MenuItem, Grid, Button, InputAdornment, Typography,
  LinearProgress, CircularProgress, Snackbar
} from '@material-ui/core';
import classNames from 'classnames';
import MuiAlert from '@material-ui/lab/Alert';
import SessionExpiredModal from '../istructionsToolsDialog/SessionExpiredModal';

//Icons
import SaveIcon from '@material-ui/icons/Save';

// API REQUEST
import axios from 'axios';
import {
  GetCityFromLatLng, GetProvinceFromCode,
  GetCoordinateIdentification, GetQuoteIdentification,
  PostPunctualSite, GetPunctualSite, PutPunctualSite,
  getUserNamePublic
} from '../../../backEnd/requestAPI';

//Coordinates Trasformation
import coordsTransform from 'coordinates-transformer';

//IsoDate Convert
import moment from 'moment';

const styles = theme => ({
  demo: {
    height: 'auto',
  },
  divider: {
    margin: `${theme.spacing(3)}px 0`,
  },
  input: {
    margin: theme.spacing(3),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(3),
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class PunctualSiteInfo extends Component {

  constructor(props) {
    super(props);

    let punctualSite = props.punctualSite
    let coordinatesType = props.coordinatesType
    /*
    var coordsEPSG25833 = null
    var coordsEPSG4326 = null*/

    let latitude = punctualSite.Lat ? punctualSite.Lat : punctualSite.Latitudine
    let longitude = punctualSite.Lon ? punctualSite.Lon : punctualSite.Longitudine

    let coordsEPSG4326 = coordsTransform([latitude, longitude], 'EPSG:25833', 'EPSG:4326')

    if (coordinatesType === "EPSG:3857" && latitude != null && longitude != null) {
      let coordsEPSG25833 = coordsTransform([latitude, longitude], 'EPSG:3857', 'EPSG:25833')
      latitude = coordsEPSG25833[1]
      longitude = coordsEPSG25833[0]
    }

    this.state = {

      punctualSiteID: punctualSite.CodeSito ? punctualSite.CodeSito : null,

      //Coordinates in EPSG:25833
      punctualSiteLng: longitude ? longitude : undefined,
      punctualSiteLat: latitude ? latitude : undefined,

      //Coordinates in EPSG:25833
      viewLng: coordsEPSG4326 ? coordsEPSG4326[0] : undefined,
      viewLat: coordsEPSG4326 ? coordsEPSG4326[1] : undefined,

      istatCode: undefined,
      punctualSiteQuote: undefined,
      punctualSiteDate: '',

      punctualSiteProvince: undefined,
      punctualSiteCity: undefined,
      punctualSiteAddress: undefined,
      punctualSiteCoordinatesIdentity: undefined,
      punctualSiteQuoteIdentity: undefined,
      punctualSiteNote: '',

      //ComboBox
      coordinatedIdentifications: [],
      quoteIdentifications: [],

      //CONTROLLER
      onViewInvestigations: props.onViewInvestigations,
      loadingCompleteProcess: 0,
      isLoadingSave: false,
      creationPunctualSiteDate: undefined,
      lastPunctualSiteSaveDate: undefined,
      isOpenMessage: false,
      textMessage: "",
      severity: "info",
      isOpenSessionExpiredModal: false,

      //CREATED OR UPDATE SITE
      lastUserModifiedID: undefined,
      lastUserModifiedName: "",
      lastUserCreatedID: undefined,
      lastUserCreatedName: "",
      isPunctualSiteToUpdate: false,
    };

  }

  componentDidMount() {

    const { punctualSiteID } = this.state

    this.getCity()
    this.getCoordinateComboBox()
    this.getQuoteComboBox()
    if (punctualSiteID != null) this.getPunctualSite(punctualSiteID)

  }

  getUserName(userID, type) {

    const config = {
      params: {
        idUtente: userID,
      },
      headers: { Authorization: `Bearer ${localStorage.getItem("USER_TOKEN")}` }
    };

    axios.get(getUserNamePublic,
      config).then(response => {
        let user = response.data;
        this.setState({
          [type]: user.nome + " " + user.cognome
        })
      }).catch(error => {
        let errorResponse = error.response.data
        if (errorResponse.statusCode == 404) {
          this.setState({
            [type]: "[Utente non trovato]"
          })
          this.openMessage(errorResponse.message, "warning")
        }
        else this.openMessage("Errore sconosciuto, contattare assistenza", "error");
      });
  }

  getPunctualSite = (punctualSiteID) => {

    const config = {
      params: {
        idSitoPuntuale: punctualSiteID,
      },
      headers: { Authorization: `Bearer ${localStorage.getItem("USER_TOKEN")}` }
    };

    axios.get(GetPunctualSite,
      config).then(response => {

        let punctualSite = response.data

        this.setState({
          istatCode: punctualSite.codiceIstat,
          punctualSiteQuote: punctualSite.quota_mt,
          punctualSiteAddress: punctualSite.indirizzo,
          punctualSiteCoordinatesIdentity: punctualSite.idEnumIdentificazioneCoordinate,
          punctualSiteQuoteIdentity: punctualSite.idEnumIdentificazioneQuota,
          punctualSiteNote: punctualSite.note ? punctualSite.note : "",
          punctualSiteDate: punctualSite.dataSito,
          creationPunctualSiteDate: punctualSite.dataCreazione,
          lastPunctualSiteSaveDate: punctualSite.dataModifica,
          lastUserModifiedID: punctualSite.idUtenteModifica,
          lastUserCreatedID: punctualSite.idUtenteCreazione,
          isPunctualSiteToUpdate: true
        }, () => {


          if (punctualSite.idUtenteCreazione) this.getUserName(punctualSite.idUtenteCreazione, "lastUserCreatedName")
          if (punctualSite.idUtenteModifica) this.getUserName(punctualSite.idUtenteModifica, "lastUserModifiedName")

        })

      }).catch(error => {
        this.openMessage("Errore sconosciuto, contattare assistenza", "error");
      });
  }

  getCity() {
    const { punctualSiteLat, punctualSiteLng } = this.state

    const config = {
      params: {
        latitudine: punctualSiteLat,
        longitudine: punctualSiteLng
      },
      headers: { Authorization: `Bearer ${localStorage.getItem("USER_TOKEN")}` }
    };

    axios.get(GetCityFromLatLng,
      config).then(response => {

        let city = response.data;

        this.setState(prevState => ({
          // punctualSiteQuote: city.altitudineCentro,
          punctualSiteCity: city.denominazione,
          istatCode: city.codiceIstat,
          loadingCompleteProcess: prevState.loadingCompleteProcess + 25
        }), () => {
          this.getProvinceAbbreviation(city.codiceProvincia)
        })

      }).catch(error => {
        this.openMessage("Errore sconosciuto, contattare assistenza", "error")
      });
  }

  getProvinceAbbreviation = (provinceCode) => {
    const config = {
      params: {
        codiceProvincia: provinceCode,
      },
      headers: { Authorization: `Bearer ${localStorage.getItem("USER_TOKEN")}` }
    };

    axios.get(GetProvinceFromCode,
      config).then(response => {
        let province = response.data;
        this.setState(prevState => ({
          punctualSiteProvince: province.sigla,
          loadingCompleteProcess: prevState.loadingCompleteProcess + 25
        }))
      }).catch(error => {
        this.openMessage("Errore sconosciuto, contattare assistenza", "error")
      });
  }

  getCoordinateComboBox = () => {
    axios.get(GetCoordinateIdentification).then(response => {
      this.setState(prevState => ({
        coordinatedIdentifications: response.data,
        loadingCompleteProcess: prevState.loadingCompleteProcess + 25
      }))
    }).catch(error => {
      this.openMessage("Errore sconosciuto, contattare assistenza", "error")
    });
  }

  getQuoteComboBox = () => {
    axios.get(GetQuoteIdentification).then(response => {
      this.setState(prevState => ({
        quoteIdentifications: response.data,
        loadingCompleteProcess: prevState.loadingCompleteProcess + 25
      }))
    }).catch(error => {
      this.openMessage("Errore sconosciuto, contattare assistenza", "error")
    });
  }


  savePunctulaSiteinfo = (info) => {
    info.preventDefault();

    this.setState({
      isLoadingSave: true
    })

    const {
      onViewInvestigations,
      istatCode, punctualSiteLat,
      punctualSiteLng, punctualSiteAddress,
      coordinatedIdentifications, punctualSiteCoordinatesIdentity,
      quoteIdentifications, punctualSiteQuoteIdentity,
      punctualSiteQuote, punctualSiteDate, punctualSiteNote,
      isPunctualSiteToUpdate, punctualSiteID
    } = this.state

    const config = {
      params: {
        idSitoPuntuale: punctualSiteID
      },
      headers: { Authorization: `Bearer ${localStorage.getItem("USER_TOKEN")}` }
    };

    const identityCoord = coordinatedIdentifications.filter(item => item.idEnum == punctualSiteCoordinatesIdentity)
    const identityQuote = quoteIdentifications.filter(item => item.idEnum == punctualSiteQuoteIdentity)

    const newPunctualSite =
    {
      codiceIstat: istatCode,
      latitudine: punctualSiteLat,
      longitudine: punctualSiteLng,
      indirizzo: punctualSiteAddress,
      idEnumIdentificazioneCoordinate: identityCoord[0].idEnum,
      descrizioneIdentificazioneCoordinate: identityCoord[0].codePc,
      quota_mt: parseInt(punctualSiteQuote),
      idEnumIdentificazioneQuota: identityQuote[0].idEnum,
      descrizioneIdentificazioneQuota: identityQuote[0].codePc,
      dataSito: punctualSiteDate,
      note: punctualSiteNote
    };

    if (isPunctualSiteToUpdate) {
      axios.put(PutPunctualSite,
        newPunctualSite, config).then(response => {

          let punctualSiteUpdate = response.data

          this.setState({
            lastPunctualSiteSaveDate: punctualSiteUpdate.dataModifica,
            lastUserModifiedID: punctualSiteUpdate.idUtenteModifica,
            isLoadingSave: false
          }, () => {
            this.openMessage("Sito Puntuale aggiornato", "success")
          })

        }).catch(error => {
          this.setState({ isLoadingSave: false })
          let errorResponse = error.response.data
          if (errorResponse.statusCode == 401) {
          this.openSessionExpiredModal()
        } else if (errorResponse.statusCode == 403) {
          this.openMessage("Utente non autorizzato su questo Comune", "error")
        } else if (errorResponse.statusCode == 400) {
          this.openMessage(errorResponse.message, "warning")
        } else {
          this.openMessage("Errore sconosciuto, contattare assistenza", "error")
        }
        });
    } else {

      axios.post(PostPunctualSite,
        newPunctualSite, config).then(response => {

          let newPunctualSiteSaved = response.data

          this.setState({
            punctualSiteID: newPunctualSiteSaved.idSitoPuntuale,
            creationPunctualSiteDate: newPunctualSiteSaved.dataCreazione,
            lastPunctualSiteSaveDate: newPunctualSiteSaved.dataModifica,
            lastUserModifiedID: newPunctualSiteSaved.idUtenteModifica,
            lastUserCreatedID: newPunctualSiteSaved.idUtenteCreazione,
            isLoadingSave: false,
            isPunctualSiteToUpdate: true
          }, () => {
            this.openMessage("Sito Puntuale creato", "success")
          })

        }).catch(error => {
          this.setState({ isLoadingSave: false })
          let errorResponse = error.response.data
          if (errorResponse.statusCode == 401) {
          this.openSessionExpiredModal()
        } else if (errorResponse.statusCode == 403) {
          this.openMessage("Utente non autorizzato su questo Comune", "error")
        } else if (errorResponse.statusCode == 400) {
          this.openMessage(errorResponse.message, "warning")
        } else {
          this.openMessage("Errore sconosciuto, contattare assistenza", "error")
        }
        });
    }
  }

  openSessionExpiredModal = () => {
    this.setState({
      isOpenSessionExpiredModal: true
    })
  }

  closeSessionExpiredModal = () => {
    this.setState({
      isOpenSessionExpiredModal: false
    })
  }

  openMessage = (text, type) => {
    this.setState(prevState => ({
      isOpenMessage: !prevState.isOpenMessage,
      textMessage: text,
      severity: type
    }))
  }

  setValue = name => event => {
    let value = event.target.value
    this.setState({ [name]: value });
  };


  render() {
    const { classes } = this.props;
    const {
      punctualSiteID,
      viewLng,
      viewLat,
      punctualSiteQuote,
      punctualSiteDate,
      punctualSiteProvince,
      punctualSiteCity,
      punctualSiteAddress,
      punctualSiteCoordinatesIdentity,
      punctualSiteQuoteIdentity,
      punctualSiteNote,

      //Last Modified PunctualSite
      creationPunctualSiteDate,
      lastPunctualSiteSaveDate,
      lastUserModifiedName,
      lastUserCreatedName,
      //ComboBox
      coordinatedIdentifications,
      quoteIdentifications,

      //CONTROLLER
      loadingCompleteProcess,
      isLoadingSave,
      isPunctualSiteToUpdate,
      isOpenMessage,
      textMessage,
      severity,
      isOpenSessionExpiredModal,

      //FUNCTION
      onViewInvestigations

    } = this.state


    const identityCoord = coordinatedIdentifications.filter(item => item.idEnum == punctualSiteCoordinatesIdentity)
    const identityQuote = quoteIdentifications.filter(item => item.idEnum == punctualSiteQuoteIdentity)


    return (
      <Fragment>
        <br />

        <form onSubmit={this.savePunctulaSiteinfo} autoComplete="on">

          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
          >
            <Grid
              container
              justify="center"
              md={10}
              xl={12}
              xs={12}
            >

              {loadingCompleteProcess < 99 ?
                <Grid
                  item
                  md={12}
                  xl={12}
                  xs={12}
                >
                  <LinearProgress variant="determinate" value={loadingCompleteProcess == 99 ? 0 : loadingCompleteProcess} />
                  <Typography variant="h6" className={classes.title} style={{ textAlign: 'center' }}>
                    Caricamento in corso {loadingCompleteProcess}%
            </Typography>
                </Grid>
                :
                <>
                  <Grid
                    item
                    md={12}
                    xl={12}
                    xs={12}
                  >
                    <div style={{ background: '#f0f9ff', padding: '8px', marginBottom: '21px', marginTop: '21px' }}>
                      <Typography variant="h6" className={classes.title} >
                        INFORMAZIONI SITO PUNTUALE
            </Typography>
                    </div>
                  </Grid>

                  <Grid
                    container
                    spacing={1}
                    md={3}
                    xl={3}
                    xs={12}
                  >
                    <Grid
                      item
                      md={8}
                      xl={8}
                      xs={12}
                    >
                      <TextField
                        disabled
                        fullWidth
                        label="ID Sito"
                        className={classNames(classes.margin, classes.textField)}
                        value={punctualSiteID}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      md={8}
                      xl={8}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        disabled
                        label="Longitudine"
                        className={classNames(classes.margin, classes.textField)}
                        value={viewLng}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      md={8}
                      xl={8}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        disabled
                        label="Latitudine"
                        className={classNames(classes.margin, classes.textField)}
                        value={viewLat}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      md={8}
                      xl={8}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label="Quota SLM"
                        className={classNames(classes.margin, classes.textField)}
                        value={punctualSiteQuote}
                        onChange={this.setValue("punctualSiteQuote")}
                        helperText={punctualSiteQuote % 1 != 0 ? "Inserisci un numero intero" : ""}
                        error={punctualSiteQuote % 1 != 0}
                        margin="normal"
                        variant="outlined"
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">(m)</InputAdornment>,
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      md={8}
                      xl={8}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        required
                        id="date"
                        label="Data"
                        type="date"
                        className={classNames(classes.margin, classes.textField)}
                        value={ punctualSiteDate }
                        onChange={this.setValue("punctualSiteDate")}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={1}
                    justify="center"
                    md={9}
                    xl={9}
                    xs={12}
                  >
                    <Grid
                      item
                      md={6}
                      xl={6}
                      xs={12}
                      className={classes.demo}
                    >

                      <TextField
                        fullWidth
                        disabled
                        label="Provincia"
                        className={classNames(classes.margin, classes.textField)}
                        value={punctualSiteProvince}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      md={6}
                      xl={6}
                      xs={12}
                      className={classes.demo}
                    >

                      <TextField
                        fullWidth
                        disabled
                        label="Comune"
                        className={classNames(classes.margin, classes.textField)}
                        value={punctualSiteCity}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      md={12}
                      xl={12}
                      xs={12}
                      className={classes.demo}
                    >

                      <TextField
                        fullWidth
                        label="Indirizzo"
                        className={classNames(classes.margin, classes.textField)}
                        InputLabelProps={{ shrink: true }}
                        value={punctualSiteAddress}
                        onChange={this.setValue("punctualSiteAddress")}
                        margin="normal"
                        variant="outlined"
                      />
                    </Grid>

                    <Grid
                      item
                      md={6}
                      xl={6}
                      xs={12}
                      className={classes.demo}
                    >
                      <TextField
                        required
                        fullWidth
                        select
                        label="Modalità identificazione coordinate"
                        className={classNames(classes.margin, classes.textField)}
                        value={punctualSiteCoordinatesIdentity}
                        onChange={this.setValue("punctualSiteCoordinatesIdentity")}
                        SelectProps={{
                          MenuProps: {
                            className: classes.menu,
                          },
                        }}
                        margin="normal"
                        variant="outlined"
                      >
                        {coordinatedIdentifications.map(option => (
                          <MenuItem key={option.idEnum} value={option.idEnum}>
                            {option.comboValue}
                          </MenuItem>
                        ))}
                      </TextField>

                    </Grid>

                    <Grid
                      item
                      md={6}
                      xl={6}
                      xs={12}
                      className={classes.demo}
                    >

                      <TextField
                        fullWidth
                        disabled
                        label="Codice"
                        className={classNames(classes.margin, classes.textField)}
                        value={identityCoord[0] ? identityCoord[0].codePc : null}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>

                    <Grid
                      item
                      md={6}
                      xl={6}
                      xs={12}
                      className={classes.demo}
                    >
                      <TextField
                        required
                        fullWidth
                        select
                        label="Modalità identificazione quota"
                        className={classNames(classes.margin, classes.textField)}
                        value={punctualSiteQuoteIdentity}
                        onChange={this.setValue("punctualSiteQuoteIdentity")}
                        SelectProps={{
                          MenuProps: {
                            className: classes.menu,
                          },
                        }}
                        margin="normal"
                        variant="outlined"
                      >
                        {quoteIdentifications.map(option => (
                          <MenuItem key={option.idEnum} value={option.idEnum}>
                            {option.comboValue}
                          </MenuItem>
                        ))}
                      </TextField>

                    </Grid>

                    <Grid
                      item
                      md={6}
                      xl={6}
                      xs={12}
                      className={classes.demo}
                    >

                      <TextField
                        fullWidth
                        disabled
                        label="Codice"
                        className={classNames(classes.margin, classes.textField)}
                        value={identityQuote[0] ? identityQuote[0].codePc : null}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      md={12}
                      xs={12}
                      className={classes.demo}
                      style={{ marginBottom: "21px" }}
                    >

                      <TextField
                        fullWidth
                        label="Note"
                        className={classNames(classes.margin, classes.textField)}
                        value={punctualSiteNote}
                        onChange={this.setValue("punctualSiteNote")}
                        margin="normal"
                        variant="outlined"
                        multiline
                        inputProps={{ maxLength: 255 }}
                        helperText={punctualSiteNote.length === 255 ? "Massimo 255 caratteri accettati" : ""}
                        error={punctualSiteNote.length === 255}
                      />
                    </Grid>

                  </Grid>

                  {!isLoadingSave &&
                    <>
                      <Grid
                        container
                        justify="flex-end"
                        alignContent="center"
                        md={6}
                        xl={6}
                        xs={12}
                      >
                        <Button
                          type="submit"
                          style={{ margin: "3px" }}
                          color="primary" variant="outlined" className={classes.button}>
                          <SaveIcon />
                          {isPunctualSiteToUpdate ? 'Modifica' : 'Salva'} Sito
                        </Button>
                      </Grid>

                      <Grid
                        container
                        justify="flex-start"
                        alignContent="center"
                        alignItems="center"
                        md={6}
                        xl={6}
                        xs={12}
                      >
                        <Button
                          disabled={!isPunctualSiteToUpdate}
                          onClick={() => onViewInvestigations(punctualSiteID)}
                          color="secondary" variant="outlined"
                          style={{ margin: "3px" }}
                          className={classes.button}>
                          Visualizza Indagini
                      </Button>
                      </Grid>
                    </>
                  }
                </>
              }

              <Grid
                container
                spacing={1}
                justify="center"
                md={12}
                xl={12}
                xs={12}
                style={{ textAlign: 'center', marginTop: "13px" }}
              >

                {isLoadingSave && <CircularProgress />}

                {lastPunctualSiteSaveDate && !isLoadingSave &&
                  <Typography variant="p" className={classes.title} >
                    Ultimo salvataggio: {moment(lastPunctualSiteSaveDate).format('YYYY-MM-DD HH:mm')} di {lastUserModifiedName}
                  </Typography>
                }
                {!lastPunctualSiteSaveDate && creationPunctualSiteDate && !isLoadingSave &&
                  <Typography variant="p" className={classes.title} >
                    Creazione: {moment(creationPunctualSiteDate).format('YYYY-MM-DD HH:mm')} di {lastUserCreatedName}
                  </Typography>
                }
              </Grid>

            </Grid>
          </Grid>
        </form>
        <br></br>

        { isOpenMessage && <Snackbar
          open={isOpenMessage}
          autoHideDuration={10000}
          onClose={() => this.openMessage("", "info")}
        >
          <Alert
            onClose={() => this.openMessage("", "info")}
            severity={severity}>
            {textMessage}
          </Alert>
        </Snackbar>}

        <SessionExpiredModal
          handleClose={this.closeSessionExpiredModal}
          open={isOpenSessionExpiredModal}
        />

      </Fragment >
    );
  }
}

PunctualSiteInfo.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PunctualSiteInfo);