import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  TextField, MenuItem, Grid, Button, InputAdornment, Typography,
  LinearProgress, CircularProgress, Snackbar
} from '@material-ui/core';
import classNames from 'classnames';
import MuiAlert from '@material-ui/lab/Alert';
import SessionExpiredModal from '../istructionsToolsDialog/SessionExpiredModal';

//Icons
import SaveIcon from '@material-ui/icons/Save';

// API REQUEST
import axios from 'axios';
import {
  GetCityFromLatLng, GetProvinceFromCode,
  GetTracedIdentifications, GetLinearSite,
  PostLinearSite, PutLinearSite,
  getUserNamePublic
} from '../../../backEnd/requestAPI';

//Coordinates Trasformation
import coordsTransform from 'coordinates-transformer';

//IsoDate Convert
import moment from 'moment';

const styles = theme => ({
  demo: {
    height: 'auto',
  },
  divider: {
    margin: `${theme.spacing(3)}px 0`,
  },
  input: {
    margin: theme.spacing(3),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(3),
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class linearSiteInfo extends Component {

  constructor(props) {
    super(props);

    let linearSite = props.linearSite

    let linearSiteCoord = linearSite.coordinates ? linearSite.coordinates : []
    let coordinatesType = props.coordinatesType

    let pointACoords = linearSiteCoord[0]
    let pointBCoords = linearSiteCoord[linearSiteCoord.length - 1]

    // Coordinate Punti di Inizio e fine tracciato
    let latitudeA = linearSite.Lat_PuntoA ? linearSite.Lat_PuntoA : pointACoords[0]
    let longitudeA = linearSite.Lon_PuntoA ? linearSite.Lon_PuntoA : pointACoords[1]
    let latitudeB = linearSite.Lat_PuntoB ? linearSite.Lat_PuntoB : pointBCoords[0]
    let longitudeB = linearSite.Lon_PuntoB ? linearSite.Lon_PuntoB : pointBCoords[1]

    // Conversione Coordinate in EPSG:4326 per la visualizzazione
    let pointAcoordsEPSG4326 = coordsTransform([longitudeA, latitudeA], 'EPSG:25833', 'EPSG:4326')
    let pointBcoordsEPSG4326 = coordsTransform([longitudeB, latitudeB], 'EPSG:25833', 'EPSG:4326')

    // Conversione Coordinate in EPSG:25833 se provenienti dalla creazione nuovo sito in formato EPSG:3857
    if (coordinatesType === "EPSG:3857") {
      if (pointACoords[0] != null && pointACoords[1] != null) {
        let coordsEPSG25833 = coordsTransform([pointACoords[0], pointACoords[1]], 'EPSG:3857', 'EPSG:25833')
        latitudeA = coordsEPSG25833[1]
        longitudeA = coordsEPSG25833[0]
      }
      if (pointBCoords[0] != null && pointBCoords[1] != null) {
        let coordsEPSG25833 = coordsTransform([pointBCoords[0], pointBCoords[1]], 'EPSG:3857', 'EPSG:25833')
        latitudeB = coordsEPSG25833[1]
        longitudeB = coordsEPSG25833[0]
      }
    }

    this.state = {

      linearSiteID: linearSite.CodeSito ? linearSite.CodeSito : null,

      //Point
      pointList: this.createObjectPointList(linearSiteCoord),

      //Coordinates in EPSG:25833
      linearSitePointALat: latitudeA ? latitudeA : undefined,
      linearSitePointALng: longitudeA ? longitudeA : undefined,

      //Coordinates in EPSG:25833
      viewPointALng: pointAcoordsEPSG4326 ? pointAcoordsEPSG4326[0] : undefined,
      viewPointALat: pointAcoordsEPSG4326 ? pointAcoordsEPSG4326[1] : undefined,
      viewPointBLng: pointBcoordsEPSG4326 ? pointBcoordsEPSG4326[0] : undefined,
      viewPointBLat: pointBcoordsEPSG4326 ? pointBcoordsEPSG4326[1] : undefined,

      istatCode: undefined,
      linearSitePointAQuote: undefined,
      linearSitePointBQuote: undefined,

      linearSiteDate: undefined,

      linearSiteProvince: undefined,
      linearSiteCity: undefined,
      linearSiteTracedIdentity: undefined,
      linearSiteNote: '',

      //ComboBox
      tracedIdentifications: [],

      //CONTROLLER
      onViewInvestigations: props.onViewInvestigations,
      loadingCompleteProcess: 0,
      isLoadingSave: false,
      creationLinearSiteDate: undefined,
      lastLinearSiteSaveDate: undefined,
      lastUserModifiedID: undefined,
      lastUserModifiedName: "",
      lastUserCreatedID: undefined,
      lastUserCreatedName: "",
      isLinearSiteToUpdate: false,
      isOpenMessage: false,
      textMessage: "",
      severity: "info",
      isOpenSessionExpiredModal: false,
    };

  }

  componentDidMount() {

    const { linearSiteID } = this.state

    this.getTracedComboBox()
    if (linearSiteID != null) this.getLinearSite(linearSiteID)
    else this.getCity()

  }

  getUserName(userID, type) {

    const config = {
      params: {
        idUtente: userID,
        headers: { Authorization: `Bearer ${localStorage.getItem("USER_TOKEN")}` }
      }
    };

    axios.get(getUserNamePublic,
      config).then(response => {
        let user = response.data;
        this.setState({
          [type]: user.nome + " " + user.cognome
        })
      }).catch(error => {
        let errorResponse = error.response.data
        if (errorResponse.statusCode == 404) {
          this.setState({
            [type]: "[Utente non trovato]"
          })
          this.openMessage(errorResponse.message, "warning")
        }
        else this.openMessage("Errore sconosciuto, contattare assistenza", "error");
      });
  }

  createObjectPointList = (linearSiteCoord) => {

    let newPointList = []

    linearSiteCoord.map(coordinate => {

      let coordsEPSG25833 = coordsTransform([coordinate[0], coordinate[1]], 'EPSG:3857', 'EPSG:25833')
      let point = { latitudine: coordsEPSG25833[1], longitudine: coordsEPSG25833[0], quota_mt: 0 }
      newPointList.push(point)
    })



    return newPointList
  }

  //Lettura province ComboBox
  getProvinceAbbreviation = (provinceCode) => {
    const config = {
      params: {
        codiceProvincia: provinceCode,
      },
      headers: { Authorization: `Bearer ${localStorage.getItem("USER_TOKEN")}` }
    };
    axios.get(GetProvinceFromCode,
      config).then(response => {
        let province = response.data;
        this.setState(prevState => ({
          linearSiteProvince: province.sigla,
          loadingCompleteProcess: prevState.loadingCompleteProcess + 25
        }))
      }).catch(error => {
         this.openMessage("Errore sconosciuto, contattare assistenza", "error")
      });
  }

  //Lettura tracciati ComboBox
  getTracedComboBox = () => {
    axios.get(GetTracedIdentifications).then(response => {
      this.setState(prevState => ({
        tracedIdentifications: response.data,
        loadingCompleteProcess: prevState.loadingCompleteProcess + 50
      }))
    }).catch(error => {
       this.openMessage("Errore sconosciuto, contattare assistenza", "error")
    });
  }

  //Lettura città ComboBox
  getCity() {
    const { linearSitePointALat, linearSitePointALng } = this.state

    const config = {
      params: {
        latitudine: linearSitePointALat,
        longitudine: linearSitePointALng
      },
      headers: { Authorization: `Bearer ${localStorage.getItem("USER_TOKEN")}` }
    };
    axios.get(GetCityFromLatLng,
      config).then(response => {

        let city = response.data;
        this.setState(prevState => ({
          //linearSitePointAQuote: city.altitudineCentro,
          //linearSitePointBQuote: city.altitudineCentro,
          linearSiteCity: city.denominazione,
          istatCode: city.codiceIstat,
          loadingCompleteProcess: prevState.loadingCompleteProcess + 25
        }), () => {
          this.getProvinceAbbreviation(city.codiceProvincia)
        })

      }).catch(error => {
         this.openMessage("Errore sconosciuto, contattare assistenza", "error")
      });
  }

  //Lettura dati Sito Lineare Selezionato
  getLinearSite = (linearSiteID) => {
    const config = {
      params: {
        idSitoLineare: linearSiteID,
      },
      headers: { Authorization: `Bearer ${localStorage.getItem("USER_TOKEN")}` }
    };

    axios.get(GetLinearSite,
      config).then(response => {
        let linearSite = response.data

        let puntoA = linearSite ? linearSite.puntoA : null
        let puntoB = linearSite ? linearSite.puntoB : null

        this.setState({
          linearSitePointALat: puntoA.latitudine,
          linearSitePointALng: puntoA.longitudine,
          istatCode: linearSite.codiceIstat,
          linearSitePointAQuote: puntoA.quota_mt,
          linearSitePointBQuote: puntoB.quota_mt,
          linearSiteTracedIdentity: linearSite.idEnumIdentificazioneTracciato,
          linearSiteNote: linearSite.note ? linearSite.note : "",
          linearSiteDate: linearSite.dataSito,
          creationLinearSiteDate: linearSite.dataCreazione,
          lastLinearSiteSaveDate: linearSite.dataModifica,
          lastUserModifiedID: linearSite.idUtenteModifica,
          lastUserCreatedID: linearSite.idUtenteCreazione,
          isLinearSiteToUpdate: true
        }, () => {
          if (linearSite.idUtenteModifica) this.getUserName(linearSite.idUtenteModifica, "lastUserModifiedName")
          if (linearSite.idUtenteCreazione) this.getUserName(linearSite.idUtenteCreazione, "lastUserCreatedName")
          this.getCity()
        })

      }).catch(error => {
        this.openMessage("Errore sconosciuto, contattare assistenza", "error");
      });
  }

  //Salva o Aggiorna Sito Lineare
  saveLinearSiteinfo = (info) => {
    info.preventDefault();

    this.setState({
      isLoadingSave: true
    })

    const {
      istatCode, pointList,
      tracedIdentifications, linearSiteTracedIdentity,
      linearSiteDate, linearSiteNote,
      isLinearSiteToUpdate, linearSiteID,
      linearSitePointAQuote, linearSitePointBQuote
    } = this.state



    const configPOST = {
      headers: { Authorization: `Bearer ${localStorage.getItem("USER_TOKEN")}` }
    };

    const configPUT = {
      params: {
        idSitoLineare: linearSiteID
      },
      headers: { Authorization: `Bearer ${localStorage.getItem("USER_TOKEN")}` }
    };

    const identityTraced = tracedIdentifications.filter(item => item.idEnum == linearSiteTracedIdentity)


    if (isLinearSiteToUpdate) {

      const updateLinearSite =
      {
        codiceIstat: istatCode,
        idEnumIdentificazioneTracciato: identityTraced[0].idEnum,
        descrizioneIdentificazioneTracciato: identityTraced[0].codePc,
        dataSito: linearSiteDate,
        note: linearSiteNote,
        quotaPuntoA: linearSitePointAQuote,
        quotaPuntoB: linearSitePointBQuote
      };


      axios.put(PutLinearSite,
        updateLinearSite, configPUT).then(response => {

          let linearSiteUpdate = response.data

          this.setState({
            lastLinearSiteSaveDate: linearSiteUpdate.dataModifica,
            lastUserModifiedID: linearSiteUpdate.idUtenteModifica,
            isLoadingSave: false
          }, () => {
            this.openMessage("Sito Lineare aggiornato")
          })

        }).catch(error => {
          this.setState({ isLoadingSave: false })
          let errorResponse = error.response.data
          if (errorResponse.statusCode == 401) {
          this.openSessionExpiredModal()
        } else if (errorResponse.statusCode == 403) {
          this.openMessage("Utente non autorizzato su questo Comune", "error")
        } else if (errorResponse.statusCode == 400) {
          this.openMessage(errorResponse.message, "warning")
        } else {
          this.openMessage("Errore sconosciuto, contattare assistenza", "error")
        }
        });
    } else {

      pointList[0].quota_mt = linearSitePointAQuote ? linearSitePointAQuote : 0
      pointList[pointList.length - 1].quota_mt = linearSitePointBQuote ? linearSitePointBQuote : 0

      const newLinearSite =
      {
        codiceIstat: istatCode,
        punti: pointList,
        idEnumIdentificazioneTracciato: identityTraced[0].idEnum,
        descrizioneIdentificazioneTracciato: identityTraced[0].codePc,
        dataSito: linearSiteDate,
        note: linearSiteNote
      };

      axios.post(PostLinearSite,
        newLinearSite, configPOST).then(response => {
          let newLinearSiteSaved = response.data
          this.setState({
            linearSiteID: newLinearSiteSaved.idSitoLineare,
            creationLinearSiteDate: newLinearSiteSaved.dataCreazione,
            lastLinearSiteSaveDate: newLinearSiteSaved.dataModifica,
            lastUserModifiedID: newLinearSiteSaved.idUtenteModifica,
            lastUserCreatedID: newLinearSiteSaved.idUtenteCreazione,
            isLoadingSave: false,
            isLinearSiteToUpdate: true
          }, () => {
            this.openMessage("Sito Lineare creato", "success")
          })

        }).catch(error => {
          this.setState({ isLoadingSave: false })

          let errorResponse = error.response.data
          if (errorResponse.statusCode == 401) {
          this.openSessionExpiredModal()
        } else if (errorResponse.statusCode == 403) {
          this.openMessage("Utente non autorizzato su questo Comune", "error")
        } else if (errorResponse.statusCode == 400) {
          this.openMessage(errorResponse.message, "warning")
        } else {
          this.openMessage("Errore sconosciuto, contattare assistenza", "error")
        }
        });
    }
  }

  openSessionExpiredModal = () => {
    this.setState({
      isOpenSessionExpiredModal: true
    })
  }

  closeSessionExpiredModal = () => {
    this.setState({
      isOpenSessionExpiredModal: false
    })
  }

  openMessage = (text, type) => {
    this.setState(prevState => ({
      isOpenMessage: !prevState.isOpenMessage,
      textMessage: text,
      severity: type
    }))
  }

  //Aggiorna parametri inseriti da form
  setValue = name => event => {
    const { tracedIdentifications } = this.state
    let value = event.target.value
    this.setState({ [name]: value });
  };


  render() {
    const { classes } = this.props;
    const {

      //Dati form
      linearSiteID,
      viewPointALng,
      viewPointALat,
      viewPointBLng,
      viewPointBLat,
      linearSitePointAQuote,
      linearSitePointBQuote,
      linearSiteDate,
      linearSiteProvince,
      linearSiteCity,
      linearSiteTracedIdentity,
      linearSiteNote,

      //Info ultime modifiche sito lineare
      creationLinearSiteDate,
      lastLinearSiteSaveDate,
      lastUserModifiedName,
      lastUserCreatedName,

      //Liste ComboBox
      tracedIdentifications,

      //Controller
      loadingCompleteProcess,
      isLoadingSave,
      isLinearSiteToUpdate,
      isOpenMessage,
      textMessage,
      severity,
      isOpenSessionExpiredModal,

      //Funzioni
      onViewInvestigations

    } = this.state


    const identityTraced = tracedIdentifications.filter(item => item.idEnum == linearSiteTracedIdentity)

    return (
      <Fragment>
        <br />

        <form onSubmit={this.saveLinearSiteinfo} autoComplete="on">

          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
          >
            <Grid
              container
              justify="center"
              md={10}
              xl={12}
              xs={12}
            >

              {loadingCompleteProcess < 99 ?
                <Grid
                  item
                  md={12}
                  xl={12}
                  xs={12}
                >
                  <LinearProgress variant="determinate" value={loadingCompleteProcess == 99 ? 0 : loadingCompleteProcess} />
                  <Typography variant="h6" className={classes.title} style={{ textAlign: 'center' }}>
                    Caricamento in corso {loadingCompleteProcess}%
            </Typography>
                </Grid>
                :
                <>
                  <Grid
                    item
                    md={12}
                    xl={12}
                    xs={12}
                  >
                    <div style={{ background: '#f0f9ff', padding: '8px', marginBottom: '21px', marginTop: '21px' }}>
                      <Typography variant="h6" className={classes.title} >
                        INFORMAZIONI SITO LINEARE
            </Typography>
                    </div>
                  </Grid>

                  <Grid
                    container
                    alignItems="flex-start"
                    spacing={1}
                    md={3}
                    xl={3}
                    xs={12}
                  >
                    <Grid
                      item
                      md={8}
                      xl={8}
                      xs={12}
                    >
                      <TextField
                        disabled
                        fullWidth
                        label="ID Sito"
                        className={classNames(classes.margin, classes.textField)}
                        value={linearSiteID}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />

                      <TextField
                        fullWidth
                        required
                        label="Data"
                        type="date"
                        className={classNames(classes.margin, classes.textField)}
                        value={linearSiteDate}
                        onChange={this.setValue("linearSiteDate")}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />

                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={1}
                    justify="center"
                    md={9}
                    xl={9}
                    xs={12}
                  >
                    <Grid
                      item
                      md={6}
                      xl={6}
                      xs={12}
                      className={classes.demo}
                    >

                      <TextField
                        fullWidth
                        disabled
                        label="Provincia"
                        className={classNames(classes.margin, classes.textField)}
                        value={linearSiteProvince}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      md={6}
                      xl={6}
                      xs={12}
                      className={classes.demo}
                    >

                      <TextField
                        fullWidth
                        disabled
                        label="Comune"
                        className={classNames(classes.margin, classes.textField)}
                        value={linearSiteCity}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>



                    <Grid
                      item
                      md={6}
                      xl={6}
                      xs={12}
                      className={classes.demo}
                    >
                      <TextField
                        required
                        fullWidth
                        select
                        label="Modalità identificazione tracciato"
                        className={classNames(classes.margin, classes.textField)}
                        value={linearSiteTracedIdentity}
                        onChange={this.setValue("linearSiteTracedIdentity")}
                        SelectProps={{
                          MenuProps: {
                            className: classes.menu,
                          },
                        }}
                        margin="normal"
                        variant="outlined"
                      >
                        {tracedIdentifications.map(option => (
                          <MenuItem key={option.idEnum} value={option.idEnum}>
                            {option.comboValue}
                          </MenuItem>
                        ))}
                      </TextField>

                    </Grid>

                    <Grid
                      item
                      md={6}
                      xl={6}
                      xs={12}
                      className={classes.demo}
                    >

                      <TextField
                        fullWidth
                        disabled
                        label="Codice"
                        className={classNames(classes.margin, classes.textField)}
                        value={identityTraced[0] ? identityTraced[0].codePc : null}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      md={4}
                      xl={4}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        disabled
                        label="Latitudine Punto A"
                        className={classNames(classes.margin, classes.textField)}
                        value={viewPointALat}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      md={4}
                      xl={4}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        disabled
                        label="Longitudine Punto A"
                        className={classNames(classes.margin, classes.textField)}
                        value={viewPointALng}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      md={4}
                      xl={4}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label="Quota Punto A"
                        className={classNames(classes.margin, classes.textField)}
                        value={linearSitePointAQuote}
                        onChange={this.setValue("linearSitePointAQuote")}
                        helperText={linearSitePointAQuote % 1 != 0 ? "Inserisci un numero intero" : ""}
                        error={linearSitePointAQuote % 1 != 0}
                        margin="normal"
                        variant="outlined"
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">(m)</InputAdornment>,
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      md={4}
                      xl={4}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        disabled
                        label="Latitudine Punto B"
                        className={classNames(classes.margin, classes.textField)}
                        value={viewPointBLat}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      md={4}
                      xl={4}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        disabled
                        label="Longitudine Punto B"
                        className={classNames(classes.margin, classes.textField)}
                        value={viewPointBLng}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      md={4}
                      xl={4}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label="Quota Punto B"
                        className={classNames(classes.margin, classes.textField)}
                        value={linearSitePointBQuote}
                        onChange={this.setValue("linearSitePointBQuote")}
                        helperText={linearSitePointBQuote % 1 != 0 ? "Inserisci un numero intero" : ""}
                        error={linearSitePointBQuote % 1 != 0}
                        margin="normal"
                        variant="outlined"
                        type="number"
                        InputProps={{
                          startAdornment: <InputAdornment position="start">(m)</InputAdornment>,
                        }}
                      />
                    </Grid>



                    <Grid
                      item
                      md={12}
                      xs={12}
                      className={classes.demo}
                      style={{ marginBottom: "21px" }}
                    >

                      <TextField
                        fullWidth
                        label="Note"
                        className={classNames(classes.margin, classes.textField)}
                        value={linearSiteNote}
                        onChange={this.setValue("linearSiteNote")}
                        margin="normal"
                        variant="outlined"
                        multiline
                        inputProps={{ maxLength: 255 }}
                        helperText={linearSiteNote.length === 255 ? "Massimo 255 caratteri accettati" : ""}
                        error={linearSiteNote.length === 255}
                      />
                    </Grid>

                  </Grid>

                  {!isLoadingSave &&
                    <>
                      <Grid
                        container
                        justify="flex-end"
                        alignContent="center"
                        md={6}
                        xl={6}
                        xs={12}
                      >
                        <Button
                          type="submit"
                          style={{ margin: "3px" }}
                          color="primary" variant="outlined" className={classes.button}>
                          <SaveIcon />
                          {isLinearSiteToUpdate ? 'Modifica' : 'Salva'} Sito
                        </Button>
                      </Grid>

                      <Grid
                        container
                        justify="flex-start"
                        alignContent="center"
                        alignItems="center"
                        md={6}
                        xl={6}
                        xs={12}
                      >
                        <Button
                          disabled={!isLinearSiteToUpdate}
                          onClick={() => onViewInvestigations(linearSiteID)}
                          color="secondary" variant="outlined"
                          style={{ margin: "3px" }}
                          className={classes.button}>
                          Visualizza Indagini
                      </Button>
                      </Grid>
                    </>
                  }
                </>
              }

              <Grid
                container
                spacing={1}
                justify="center"
                md={12}
                xl={12}
                xs={12}
                style={{ textAlign: 'center', marginTop: "13px" }}
              >

                {isLoadingSave && <CircularProgress />}

                {lastLinearSiteSaveDate && !isLoadingSave &&
                  <Typography variant="p" className={classes.title} >
                    Ultimo salvataggio: {moment(lastLinearSiteSaveDate).format('YYYY-MM-DD HH:mm')} di {lastUserModifiedName}
                  </Typography>
                }
                {!lastLinearSiteSaveDate && creationLinearSiteDate && !isLoadingSave &&
                  <Typography variant="p" className={classes.title} >
                    Creazione: {moment(creationLinearSiteDate).format('YYYY-MM-DD HH:mm')} di {lastUserCreatedName}
                  </Typography>
                }
              </Grid>

            </Grid>
          </Grid>
        </form>
        <br></br>

        { isOpenMessage && <Snackbar
          open={isOpenMessage}
          autoHideDuration={10000}
          onClose={() => this.openMessage("", "info")}
        >
          <Alert
            onClose={() => this.openMessage("", "info")}
            severity={severity}>
            {textMessage}
          </Alert>
        </Snackbar>}

        <SessionExpiredModal
          handleClose={this.closeSessionExpiredModal}
          open={isOpenSessionExpiredModal}
        />

      </Fragment >
    );
  }
}

linearSiteInfo.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(linearSiteInfo);