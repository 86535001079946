import React, { Component } from "react";
import ReactDOM from "react-dom";
import "ol/ol.css";
import Map from "ol/Map";
import View from "ol/View";
import "./SCMap.css";
import "./OLOverrides.css";
import "./Navigation";
import Navigation from "./Navigation";
import { defaults as defaultInteractions } from "ol/interaction.js";
import Popup from "../helpers/Popup.jsx";
import FooterTools from "./FooterTools.jsx";
import { defaults as defaultControls, ScaleLine, FullScreen, Rotate } from "ol/control.js";
import BasemapSwitcher from "./BasemapSwitcher";
import PropertyReportClick from "./PropertyReportClick.jsx";
import "ol-contextmenu/dist/ol-contextmenu.css";
import { fromLonLat, transform } from "ol/proj";

import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { MouseWheelZoom } from "ol/interaction";

import FloatingMenu, { FloatingMenuItem } from "../helpers/FloatingMenu.jsx";
import { Item as MenuItem } from "rc-menu";
import Portal from "../helpers/Portal.jsx";
import * as helpers from "../helpers/helpers";
import mainConfig from "../config.json";
import Identify from "./Identify";
import AttributeTable from "../helpers/AttributeTable.jsx";
import FloatingImageSlider from "../helpers/FloatingImageSlider.jsx";

//Lettura Indagine
import { GeoJSON } from "ol/format.js";
import { LayerHelpers, OL_LAYER_TYPES } from "../helpers/OLHelpers";

//Maschere
import NewPunctualSiteDialog from "../sidebar/components/punctualSite/punctualSiteDialogView";
import NewLinearSiteDialog from "../sidebar/components/linearSite/linearSiteDialogView";
import NewNodoFranaDialog from "../sidebar/components/nodiIdroFrane/nodoFranaDialogView";
import NewNodoIdroDialog from "../sidebar/components/nodiIdroFrane/nodoIdroDialogView";


const scaleLineControl = new ScaleLine({
  minWidth: mainConfig.mapTheme === "MTO" ? 100 : 80,
});
const feedbackTemplate = (xmin, xmax, ymin, ymax, centerx, centery, scale) =>
  `${mainConfig.feedbackUrl}/?xmin=${xmin}&xmax=${xmax}&ymin=${ymin}&ymax=${ymax}&centerx=${centerx}&centery=${centery}&scale=${scale}&REPORT_PROBLEM=True`;
const googleMapsTemplate = (pointx, pointy) => `https://www.google.com/maps?q=${pointy},${pointx}`;
class SCMap extends Component {
  constructor(props) {
    super(props);
    this.storageMapDefaultsKey = "Map Defaults";
    this.contextCoords = null;
    this.storageExtentKey = "Map Extent";
    this.state = {
      mapClassName: "sc-map",
      shareURL: null,
      parcelClickText: "Disable Property Click",
      isIE: false,
      mapBottom: 0,
      layerSelected: undefined,

      //Apertura Maschera Siti Puntuali
      isOpenPunctualSiteView: false,
      punctualSite: {},

      //Apertura Maschera Siti Lineari
      isOpenSettingLinearSiteDialog: false,
      linearSite: {},

      //Apertura Maschera Nodo Frane
      isOpenNodoFranaView: false,
      nodoFrana: {},


      //Apertura Maschera Nodo Idro
      isOpenNodoIdroView: false,
      nodoIdro: {},


    };

    // LISTEN FOR MAP CURSOR TO CHANGE
    if (!mainConfig.onlyStandardCursor) window.emitter.addListener("changeCursor", (cursorStyle) => this.changeCursor(cursorStyle));

    // LISTEN FOR TOC TO LOAD
    window.emitter.addListener("tocLoaded", () => this.handleUrlParameters());

    // LISTEN FOR ATTRIBUTE TABLE SIZE
    window.emitter.addListener("attributeTableResize", (height) => this.onAttributeTableResize(height));

  }

  componentDidMount() {

    const rolesUser = JSON.parse(localStorage.getItem("ROLES_USER"))


    if (mainConfig.leftClickIdentify) {
      this.setState({ mapClassName: "sc-map identify" });
    }
    let centerCoords = mainConfig.centerCoords;
    let defaultZoom = mainConfig.defaultZoom;
    const defaultsStorage = sessionStorage.getItem(this.storageMapDefaultsKey);
    const extent = helpers.getItemsFromStorage(this.storageExtentKey);

    if (defaultsStorage !== null && extent === undefined) {
      const detaults = JSON.parse(defaultsStorage);
      if (detaults.zoom !== undefined) defaultZoom = detaults.zoom;
      if (detaults.center !== undefined) centerCoords = detaults.center;
    }

    var controls = [];
    if (window.mapControls.scaleLine) controls.push(scaleLineControl);
    if (window.mapControls.fullScreen) controls.push(new FullScreen());
    if (window.mapControls.rotate) controls.push(new Rotate());

    var map = new Map({
      controls: defaultControls().extend(controls.concat([])),
      layers: [],
      target: mainConfig.mapTheme === "MTO" ? "map-mto" : "map-simcoe-county",
      view: new View({
        center: centerCoords,
        zoom: defaultZoom,
        maxZoom: mainConfig.maxZoom,
        //resolutions: resolutions
      }),
      interactions: defaultInteractions({ keyboard: true, altShiftDragRotate: window.mapControls.rotate, pinchRotate: window.mapControls.rotate, mouseWheelZoom: false }).extend([
        new MouseWheelZoom({
          duration: 0,
          constrainResolution: true,
        }),
      ]),
      keyboardEventTarget: document,
    });
    if (!window.mapControls.zoomInOut) helpers.removeMapControl(map, "zoom");
    if (!window.mapControls.rotate) helpers.removeMapControl(map, "rotate");

    if (extent !== undefined) {
      map.getView().fit(extent, map.getSize(), { duration: 1000 });
    }

    window.map = map;
    window.popup = new Popup();
    window.map.addOverlay(window.popup);


    /**
    * Richiamo evento al click del tasto 
    * destro del mouse sulla mappa visualizzando
    * il menù di scelta azioni
     */
    window.map.getViewport().addEventListener("contextmenu", (evt) => {
      evt.preventDefault();
      this.contextCoords = window.map.getEventCoordinate(evt);

      /**
      * Richiedo la tipologia dei layer attivi sulla mappa
      * al fine di identificare le azioni possibili sul punto cliccato
       */
      let arrayActiveLayersType = this.getActiveLayersType()
      console.log("arrayActiveLayersType: ", arrayActiveLayersType)


      const menu = (
        <Portal>
          <FloatingMenu key={helpers.getUID()} buttonEvent={evt} onMenuItemClick={this.onMenuItemClick} autoY={true} autoX={true}>
            {/*  <MenuItem
              className={helpers.isMobile() || !mainConfig.rightClickMenuVisibility["sc-floating-menu-basic-mode"] ? "sc-hidden" : "sc-floating-menu-toolbox-menu-item"}
              key="sc-floating-menu-basic-mode"
            >
              <FloatingMenuItem imageName={"collased.png"} label="Chiudi" />
            </MenuItem>*/}

            {/*  <MenuItem className={mainConfig.rightClickMenuVisibility["sc-floating-menu-property-click"] ? "sc-floating-menu-toolbox-menu-item" : "sc-hidden"} key="sc-floating-menu-property-click">
              <FloatingMenuItem imageName={"report.png"} label="Report" />
            </MenuItem>*/}

            {/*<MenuItem className={mainConfig.rightClickMenuVisibility["sc-floating-menu-add-mymaps"] ? "sc-floating-menu-toolbox-menu-item" : "sc-hidden"} key="sc-floating-menu-add-mymaps">
              <FloatingMenuItem imageName={"point.png"} label="Aggiungi Marker" />
            </MenuItem>*/}

            {/* <MenuItem className={mainConfig.rightClickMenuVisibility["sc-floating-menu-save-map-extent"] ? "sc-floating-menu-toolbox-menu-item" : "sc-hidden"} key="sc-floating-menu-save-map-extent">
              <FloatingMenuItem imageName={"globe-icon.png"} label="Salva come estensione" />
            </MenuItem>*/}

            {/*  <MenuItem className={mainConfig.rightClickMenuVisibility["sc-floating-menu-report-problem"] ? "sc-floating-menu-toolbox-menu-item" : "sc-hidden"} key="sc-floating-menu-report-problem">
              <FloatingMenuItem imageName={"error.png"} label="Report a problem" />
            </MenuItem>*/}

            {rolesUser.some(role => role.ruolo !== "VIEWER" && role.ruolo !== "USER") && (
              arrayActiveLayersType.includes("v_s93_msp_indagine")
              || arrayActiveLayersType.includes("v_s93_msp_sito")
              || arrayActiveLayersType.includes("v_s93_msl_indagine")
              || arrayActiveLayersType.includes("v_s93_msl_sito")) ?
              <MenuItem className={mainConfig.rightClickMenuVisibility["sc-floating-menu-site-edit"] ? "sc-floating-menu-toolbox-menu-item" : "sc-hidden"} key="sc-floating-menu-site-edit">
                <FloatingMenuItem imageName={"edit.png"} label="Modifica Sito" />
              </MenuItem>
              : null}

            {rolesUser.some(role => role.ruolo !== "VIEWER" && role.ruolo !== "USER") && (
              arrayActiveLayersType.includes("v_s92_scheda_frana_davalidare")
              || arrayActiveLayersType.includes("v_s92_scheda_frana_proposte")
              || arrayActiveLayersType.includes("v_s92_scheda_frana_validate")
              || arrayActiveLayersType.includes("v_s92_scheda_idro_davalidare")
              || arrayActiveLayersType.includes("v_s92_scheda_idro_proposte")
              || arrayActiveLayersType.includes("v_s92_scheda_idro_validate")) ?
              <MenuItem className={mainConfig.rightClickMenuVisibility["sc-floating-menu-site-edit"] ? "sc-floating-menu-toolbox-menu-item" : "sc-hidden"} key="sc-floating-menu-node-edit">
                <FloatingMenuItem imageName={"edit.png"} label="Modifica" />
              </MenuItem>
              : null}



            <MenuItem className={mainConfig.rightClickMenuVisibility["sc-floating-menu-identify"] ? "sc-floating-menu-toolbox-menu-item" : "sc-hidden"} key="sc-floating-menu-identify">
              <FloatingMenuItem imageName={"identify.png"} label="Identifica" />
            </MenuItem>
            <MenuItem className={mainConfig.rightClickMenuVisibility["sc-floating-menu-google-maps"] ? "sc-floating-menu-toolbox-menu-item" : "sc-hidden"} key="sc-floating-menu-google-maps">
              <FloatingMenuItem imageName={"google.png"} label="Visualizza in Google Maps" />
            </MenuItem>

            {/* <MenuItem className={mainConfig.rightClickMenuVisibility["sc-floating-menu-more"] ? "sc-floating-menu-toolbox-menu-item" : "sc-hidden"} key="sc-floating-menu-more">
              <FloatingMenuItem imageName={"more-16.png"} label="Altro..." />
            </MenuItem>
            */}
          </FloatingMenu>
        </Portal>
      );
      ReactDOM.render(menu, document.getElementById("portal-root"));
    });

    // APP STAT
    helpers.addAppStat("STARTUP", "MAP_LOAD");

    // WARNING FOR IE
    var ua = window.navigator.userAgent;
    var msie = ua.indexOf("MSIE ");

    // eslint-disable-next-line
    if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
      // If Internet Explorer, return version number
      this.setState({ isIE: true });
      helpers.showURLWindow(mainConfig.ieWarningUrl);
    } else {
      // SHOW TERMS
      if (helpers.isMobile()) {
        window.emitter.emit("setSidebarVisiblity", "CLOSE");
      }
    }

    // MAP LOADED
    this.initialLoad = false;
    window.map.once("rendercomplete", (event) => {
      if (!this.initialLoad) {
        window.emitter.emit("mapLoaded");
        this.initialLoad = true;
      }
    });

    window.map.on("change:size", () => {
      if (!window.isAttributeTableResizing) {
        window.emitter.emit("mapResize");
      }
    });
  }

  changeCursor = (cursorStyle) => {
    let cursorStyles = ["standard", "identify", "draw"];
    cursorStyles.splice(cursorStyles.indexOf(cursorStyle), 1);
    let classes = this.state.mapClassName.split(" ");
    if (classes.indexOf(cursorStyle) === -1) {
      cursorStyles.forEach((styleName) => {
        if (classes.indexOf(styleName) !== -1) classes.splice(classes.indexOf(styleName), 1);
      });
      classes.push(cursorStyle);
      this.setState({ mapClassName: classes.join(" ") });
    }
  };

  onAttributeTableResize = (height) => {
    this.setState({ mapBottom: Math.abs(height) }, () => {
      window.map.updateSize();
    });
  };

  handleUrlParameters = () => {
    const storage = localStorage.getItem(this.storageExtentKey);

    // GET URL PARAMETERS (ZOOM TO XY)
    const x = helpers.getURLParameter("X");
    const y = helpers.getURLParameter("Y");
    const sr = helpers.getURLParameter("SR") === null ? "WEB" : helpers.getURLParameter("SR");

    // GET URL PARAMETERS (ZOOM TO EXTENT)
    const xmin = helpers.getURLParameter("XMIN");
    const ymin = helpers.getURLParameter("YMIN");
    const xmax = helpers.getURLParameter("XMAX");
    const ymax = helpers.getURLParameter("YMAX");

    if (x !== null && y !== null) {
      // URL PARAMETERS (ZOOM TO XY)
      let coords = [x, y];
      if (sr === "WGS84") coords = fromLonLat([Math.round(x * 100000) / 100000, Math.round(y * 100000) / 100000]);

      setTimeout(() => {
        helpers.flashPoint(coords);
      }, 1000);
    } else if (xmin !== null && ymin !== null && xmax !== null && ymax !== null) {
      //URL PARAMETERS (ZOOM TO EXTENT)
      const extent = [parseFloat(xmin), parseFloat(ymin), parseFloat(xmax), parseFloat(ymax)];
      window.map.getView().fit(extent, window.map.getSize(), { duration: 1000 });
    } else if (storage !== null) {
      // ZOOM TO SAVED EXTENT
      const extent = JSON.parse(storage);
      window.map.getView().fit(extent, window.map.getSize(), { duration: 1000 });
    }

    window.emitter.emit("mapParametersComplete");
  };

  onMenuItemClick = (key) => {
    switch (key) {
      case "sc-floating-menu-zoomin":
        window.map.getView().setZoom(window.map.getView().getZoom() + 1);
        break;
      case "sc-floating-menu-zoomout":
        window.map.getView().setZoom(window.map.getView().getZoom() - 1);
        break;
      case "sc-floating-menu-property-click":
        window.emitter.emit("showPropertyReport", this.contextCoords);
        break;
      case "sc-floating-menu-add-mymaps":
        this.addMyMaps();
        break;
      case "sc-floating-menu-save-map-extent":
        this.saveMapExtent();
        break;
      case "sc-floating-menu-report-problem":
        this.reportProblem();
        break;
      case "sc-floating-menu-identify":
        this.identify();
        break;
      case "sc-floating-menu-google-maps":
        this.googleLink();
        break;
      case "sc-floating-menu-more":
        this.moreOptions();
        break;
      case "sc-floating-menu-basic-mode":
        this.basicMode();
        break;
      case "sc-floating-menu-site-edit":
        this.editSite();
        break;
      case "sc-floating-menu-node-edit":
        this.editNode();
        break;
      default:
        break;
    }

    helpers.addAppStat("Right Click", key);
  };

  basicMode = () => {
    window.emitter.emit("setSidebarVisiblity", "CLOSE");
  };

  moreOptions = () => {
    // EMIT A CHANGE IN THE SIDEBAR (IN OR OUT)
    window.emitter.emit("setSidebarVisiblity", "CLOSE");

    // OPEN MORE MENU
    window.emitter.emit("openMoreMenu");
  };

  identify = () => {
    const point = new Point(this.contextCoords);
    window.emitter.emit("loadReport", <Identify geometry={point} />);
  };

  //Lettura dati sito o indagine puntuale selezionata su mappa e apertura maschera
  editSite = () => {
    const geometry = new Point(this.contextCoords);
    const layers = window.map.getLayers().getArray();

    for (let index = 0; index < layers.length; index++) {
      const layer = layers[index];


      if (layer.getVisible() && LayerHelpers.getLayerType(layer) !== OL_LAYER_TYPES.Vector) {
        const queryable = layer.get("queryable");
        if (queryable) {
          const name = layer.get("name");
          let type = layer.get("displayName");
          let wfsUrl = layer.get("wfsUrl");

          if (wfsUrl !== undefined && geometry.getType() !== "Point") {
            const feature = new Feature(geometry);
            const wktString = helpers.getWKTStringFromFeature(feature);
            wfsUrl += "INTERSECTS(geom," + wktString + ")";
            // QUERY USING WFS
            // eslint-disable-next-line
            helpers.getJSON(wfsUrl, (result) => {
              const featureList = new GeoJSON().readFeatures(result);
              if (featureList[0] != undefined) {
                const featureID = featureList[0].getId()
                let isPunctualSite = featureID.includes('v_s93_msp_sito')
                let isLinearSite = featureID.includes('v_s93_msl_sito')

                if (isPunctualSite) this.openNewPunctualSiteDialog(featureList[0])
                if (isLinearSite) this.openNewLinearSiteDialog(featureList[0])

              }
            });
          } else {
            let infoFormat = layer.get("INFO_FORMAT");
            let xslTemplate = mainConfig.wmsGeoJsonTemplate;
            let getInfoOption = { INFO_FORMAT: "application/json" };
            if (infoFormat !== undefined && infoFormat !== "") getInfoOption["INFO_FORMAT"] = infoFormat;
            if (xslTemplate !== undefined && xslTemplate !== "") getInfoOption["XSL_TEMPLATE"] = xslTemplate;

            var url = layer.getSource().getFeatureInfoUrl(geometry.flatCoordinates, window.map.getView().getResolution(), "EPSG:3857", getInfoOption);

            if (url) {
              url += "&feature_count=1000000";

              helpers.httpGetText(url, (result) => {
                let tempResult = helpers.tryParseJSON(result);

                if (tempResult !== false) {
                  result = tempResult;
                } else {
                  return;
                }

                const featureList = new GeoJSON().readFeatures(result);

                if (featureList[0] != undefined) {
                  const featureID = featureList[0].getId()

                  let isPunctualSite = featureID.includes('v_s93_msp_sito') || featureID.includes('v_s93_msp_indagine')
                  let isLinearSite = featureID.includes('v_s93_msl_sito') || featureID.includes('v_s93_msl_indagine')

                  if (isPunctualSite) this.openNewPunctualSiteDialog(featureList[0])
                  if (isLinearSite) this.openNewLinearSiteDialog(featureList[0])

                }
              });
            }
          }
        }
      }
    }
  };



  //Lettura dati nodo frana o idro selezionato su mappa e apertura maschera
  editNode = () => {
    const geometry = new Point(this.contextCoords);
    const layers = window.map.getLayers().getArray();

    for (let index = 0; index < layers.length; index++) {
      const layer = layers[index];

      if (layer.getVisible() && LayerHelpers.getLayerType(layer) !== OL_LAYER_TYPES.Vector) {
        const queryable = layer.get("queryable");
        if (queryable) {

          let infoFormat = layer.get("INFO_FORMAT");
          let xslTemplate = mainConfig.wmsGeoJsonTemplate;
          let getInfoOption = { INFO_FORMAT: "application/json" };
          if (infoFormat !== undefined && infoFormat !== "") getInfoOption["INFO_FORMAT"] = infoFormat;
          if (xslTemplate !== undefined && xslTemplate !== "") getInfoOption["XSL_TEMPLATE"] = xslTemplate;

          var url = layer.getSource().getFeatureInfoUrl(
            geometry.flatCoordinates,
            window.map.getView().getResolution(),
            'EPSG:3857',
            { 'INFO_FORMAT': 'application/json' }
          );

          if (url) {

            url += "&feature_count=1000000";

            helpers.httpGetText(url, (result) => {
              let tempResult = helpers.tryParseJSON(result);

              if (tempResult !== false) {
                result = tempResult;
              } else {
                return;
              }

              const featureList = new GeoJSON().readFeatures(result);

              if (featureList[0] != undefined) {
                const featureID = featureList[0].getId()

                let isNodoFrane = featureID.includes("v_s92_scheda_frana_davalidare") || featureID.includes("v_s92_scheda_frana_proposte") || featureID.includes("v_s92_scheda_frana_validate");
                let isNodeidro = featureID.includes("v_s92_scheda_idro_davalidare") || featureID.includes("v_s92_scheda_idro_proposte") || featureID.includes("v_s92_scheda_idro_validate");

                if (isNodoFrane) this.openNewNodoFranaDialog(featureList[0])
                if (isNodeidro) this.openNewNodoIdroDialog(featureList[0])

              }
            });
          }
        }
      }
    }
  };

  /**
* Metodo per l'apertura della maschera
* per la creazione e modifica sito puntuale
* @param {object} punctualSite 
*/
  openNewPunctualSiteDialog = (selectedPunctualSite) => {

    if (selectedPunctualSite != null) {
      this.setState({
        isOpenPunctualSiteView: true,
        punctualSite: selectedPunctualSite.values_
      });
    } else {
      this.setState({
        isOpenPunctualSiteView: false,
      });
    }
  }

  /**
* Metodo per l'apertura della maschera
* per la creazione e modifica sito lineare
* @param {object} punctualSite 
*/
  openNewLinearSiteDialog = (selectedLinearSite) => {

    if (selectedLinearSite != null) {
      this.setState({
        isOpenSettingLinearSiteDialog: true,
        linearSite: selectedLinearSite.values_
      });
    } else {
      this.setState({
        isOpenSettingLinearSiteDialog: false,
      });
    }
  }

  /**
* Metodo per l'apertura della maschera
* per la creazione e modifica nodo frana
* @param {object} nodoFrana 
*/
  openNewNodoFranaDialog = (selectedNodo) => {

    if (selectedNodo != null) {
      this.setState({
        isOpenNodoFranaView: true,
        nodoFrana: selectedNodo.values_,
        layerSelected: selectedNodo
      });
    } else {
      this.setState({
        isOpenNodoFranaView: false,
      });
    }
  }
  /**
* Metodo per l'apertura della maschera
* per la creazione e modifica nodo idro
* @param {object} nodoFrana 
*/
  openNewNodoIdroDialog = (selectedNodo) => {

    if (selectedNodo != null) {
      this.setState({
        isOpenNodoIdroView: true,
        nodoIdro: selectedNodo.values_,
        layerSelected: selectedNodo
      });
    } else {
      this.setState({
        isOpenNodoIdroView: false,
      });
    }
  }


    /**
   * Metodo per ottenere l'elenco delle tipologie
   * dei layers attivi sulla mappa
   */
  getActiveLayersType = () => {

    const layers = window.map.getLayers().getArray();
    let activeLayersType = []

    for (let index = 0; index < layers.length; index++) {
      const layer = layers[index];
      if (layer.getVisible() && LayerHelpers.getLayerType(layer) !== OL_LAYER_TYPES.Vector) {
        const queryable = layer.get("queryable");
        if (queryable) {
          let name = (layer.get("name")).split(":")
          let tableName = name[1]
          activeLayersType.push(tableName)
        }
      }
    }
    return activeLayersType;
  }



  reportProblem = () => {
    // APP STATS
    helpers.addAppStat("Report Problem", "Right Click Map");

    const scale = helpers.getMapScale();
    const extent = window.map.getView().calculateExtent(window.map.getSize());
    const xmin = extent[0];
    const xmax = extent[1];
    const ymin = extent[2];
    const ymax = extent[3];
    const center = window.map.getView().getCenter();

    const feedbackUrl = feedbackTemplate(xmin, xmax, ymin, ymax, center[0], center[1], scale);

    helpers.showURLWindow(feedbackUrl, false, "full");
  };
  googleLink = () => {
    // APP STATS
    helpers.addAppStat("Google Maps", "Right Click Map");

    const latLongCoords = transform(this.contextCoords, "EPSG:3857", "EPSG:4326");
    const googleMapsUrl = googleMapsTemplate(latLongCoords[0], latLongCoords[1]);

    helpers.showURLWindow(googleMapsUrl, false, "full");
  };

  saveMapExtent = () => {
    const extent = window.map.getView().calculateExtent(window.map.getSize());
    helpers.saveToStorage(this.storageExtentKey, extent);
    helpers.showMessage("Map Extent", "Your map extent has been saved.");
  };

  addMyMaps = () => {
    var marker = new Feature(new Point(this.contextCoords));
    window.emitter.emit("addMyMapsFeature", marker, this.contextCoords[0] + "," + this.contextCoords[1]);
  };

  onContextDisableParcelClick = () => {
    if (window.disableParcelClick) {
      window.disableParcelClick = false;
      this.setState({ parcelClickText: "Disable Property Click" });
    } else {
      window.disableParcelClick = true;
      this.setState({ parcelClickText: "Enable Property Click" });
    }
    this.contextmenu.close();

    // this.contextmenu.clear();
    // this.contextmenu.extend(this.getContextMenuItems())
  };

  getPropertyClickText = () => {
    if (window.disableParcelClick) return "Enable Property Click";
    else return "Disable Property Click";
  };

  sidebarChanged(isSidebarOpen) {
    let mapClassName = "sc-map";
    //  SIDEBAR IN AND OUT
    if (isSidebarOpen) {
      mapClassName = "sc-map sc-map-slideout";
    } else {
      mapClassName = "sc-map sc-map-closed sc-map-slidein";
    }
    this.setState({ mapClassName: mapClassName }, () => {
      window.map.updateSize();

      this.forceUpdate();
    });
  }

  render() {
    const {
      layerSelected,
      isOpenPunctualSiteView, punctualSite,
      isOpenSettingLinearSiteDialog, linearSite,
      isOpenNodoFranaView, nodoFrana,
      isOpenNodoIdroView, nodoIdro
    } = this.state
    window.emitter.addListener("sidebarChanged", (isSidebarOpen) => this.sidebarChanged(isSidebarOpen));

    return (
      <div>
        <div id="map-theme-mto" className={mainConfig.mapTheme === "MTO" ? "" : "sc-hidden"}>
          <div id={mainConfig.mapTheme === "MTO" ? "map-modal-window-mto" : ""} />
          <div id="map-mto" className={this.state.mapClassName} tabIndex="0" style={{ bottom: this.state.mapBottom }} />
          <Navigation />
          <FooterTools />
          <BasemapSwitcher />
          <AttributeTable />
          <FloatingImageSlider />
        </div>
        <div id="map-theme-simcoe-county" className={mainConfig.mapTheme === "THEME_DEFAULT" ? "" : "sc-hidden"}>
          <div id={mainConfig.mapTheme === "THEME_DEFAULT" ? "map-modal-window-simcoe-county" : ""} />
          <div id="map-simcoe-county" className={this.state.mapClassName} tabIndex="0" style={{ bottom: this.state.mapBottom }} />
          <Navigation />
          <FooterTools />
          <BasemapSwitcher />
          <PropertyReportClick />
          <AttributeTable />
          <FloatingImageSlider />
        </div>

        {isOpenPunctualSiteView &&
          <NewPunctualSiteDialog
            isOpen={isOpenPunctualSiteView}
            closeDialog={this.openNewPunctualSiteDialog}
            punctualSite={punctualSite}
            isNewSite={false}
            coordinatesType="EPSG:25833"
          />}

        {isOpenSettingLinearSiteDialog &&
          <NewLinearSiteDialog
            isOpen={isOpenSettingLinearSiteDialog}
            closeDialog={this.openNewLinearSiteDialog}
            linearSite={linearSite}
            isNewSite={false}
            coordinatesType="EPSG:25833"
          />}


        {isOpenNodoFranaView &&
          <NewNodoFranaDialog
            isOpen={isOpenNodoFranaView}
            closeDialog={this.openNewNodoFranaDialog}
            nodo={nodoFrana}
            isNewNodo={false}
            coordinatesType="EPSG:25833"
            layer={layerSelected}
          />}

        {isOpenNodoIdroView &&
          <NewNodoIdroDialog
            isOpen={isOpenNodoIdroView}
            closeDialog={this.openNewNodoIdroDialog}
            nodo={nodoIdro}
            isNewNodo={false}
            coordinatesType="EPSG:25833"
            layer={layerSelected}
          />
        }

      </div>
    );
  }
}

export default SCMap;
