import React, { useState } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, IconButton } from '@material-ui/core';
import { CloudDownload } from '@material-ui/icons';

const columns = [
  { id: 'progetto', label: 'Progetto', minWidth: 170 },
  { id: 'tipoDocumento', label: 'Tipo', minWidth: 100 },
  { id: 'autore', label: 'Autore', minWidth: 100 },
  { id: 'titolo', label: 'Titolo', minWidth: 100 },
  { id: 'versione', label: 'Versione', minWidth: 100 },
  { id: 'dataDocumento', label: 'Data', minWidth: 100 },
  { id: 'scala', label: 'Scala', minWidth: 100 },
  { id: 'action', label: 'Azioni', minWidth: 10 },
];

function collect(props) {
  return { data: props.data };
}

const AttachmentListView = ({
  items,
  currentPage,
  totalPage,
  onChangePage,
  downloadAttachment
}) => {

  return (
    <Paper sx={{ width: '100%' }}>
      <TableContainer>
        <Table stickyHeader >
          <TableHead>
          
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {items
              .slice(currentPage * 5, currentPage * 5 + 5)
              .map((entity) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={entity.id}>
                    {columns.map((column) => {
                      const value = entity[column.id];
                      return (
                        column.id === 'action' ?
                          <TableCell key={column.id} align={column.align}>
                            <IconButton
                              onClick={() => downloadAttachment(entity)}
                              edge="start" color="inherit" aria-label="downloas">
                              <CloudDownload />
                            </IconButton>
                          </TableCell>
                          :
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value}
                          </TableCell>
                      );
                    })}

                  </TableRow>
                );
              })}
          </TableBody>
  
        </Table>
      </TableContainer>
         <TablePagination
        component="div"
        count={items.length}
        rowsPerPage={5}
        page={currentPage}
        onPageChange={onChangePage}
      />
    </Paper>
  );
};

export default React.memo(AttachmentListView);

