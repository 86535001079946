import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  TextField, MenuItem, Grid, Typography, Snackbar
} from '@material-ui/core';
import classNames from 'classnames';
import MuiAlert from '@material-ui/lab/Alert';

//IsoDate Convert
import moment from 'moment';

const styles = theme => ({
  demo: {
    height: 'auto',
  },
  divider: {
    margin: `${theme.spacing(3)}px 0`,
  },
  input: {
    margin: theme.spacing(3),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(3),
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class InfoAmbitoProgettuale extends Component {

  constructor(props) {
    super(props);

    let nodo = props.nodo

    this.state = {

      // CONTROLLER
      closeDialog: props.closeDialog,
      textMessage: "",
      severity: "info",
      isOpenMessage: false

    };

  }



  openMessage = (text, type) => {
    this.setState(prevState => ({
      isOpenMessage: !prevState.isOpenMessage,
      textMessage: text,
      severity: type
    }))
  }

  render() {
    const { classes, saveValue } = this.props;
    const {
      statoScheda,
      nodoAttivita,
      nodoRilevatore,
      nodoValidatore,
      nodoDataRilievo,
      nodoDataValidazione,
    } = this.props.nodo

    const {
      //CONTROLLER
      isOpenMessage,
      textMessage,
      severity,
    } = this.state

    return (
      <Fragment>
        <br />

        <form onSubmit={this.saveNodoinfo} autoComplete="on">

          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
            style={{ padding: '21px' }}
          >
            <Grid
              container
              justify="center"
              md={12}
              xl={12}
              xs={12}
            >

              <Grid
                item
                md={12}
                xl={12}
                xs={12}
              >
                <div style={{ background: '#f0f9ff', padding: '8px', marginBottom: '21px', marginTop: '21px' }}>
                  <Typography variant="h6" className={classes.title} >
                    AMBITO DI ATTIVITA'
                  </Typography>
                </div>
              </Grid>

              <Grid
                container
                spacing={1}
                md={12}
                xl={4}
                xs={12}
              >

                <Grid
                  item
                  md={12}
                  xl={8}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    label="Attività"
                    className={classNames(classes.margin, classes.textField)}
                    value={nodoAttivita}
                    onChange={event => saveValue("nodoAttivita", event.target.value)}
                    rows={4}
                    multiline
                    margin="normal"
                    variant="outlined"
                    required
                  />

                </Grid>

              </Grid>

              <Grid
                container
                spacing={1}
                justify="center"
                md={12}
                xl={8}
                xs={12}
              >


                <Grid
                  item
                  md={6}
                  xl={6}
                  xs={12}
                  className={classes.demo}
                >

                  <TextField
                    fullWidth
                    label="Rilevatore"
                    disabled
                    className={classNames(classes.margin, classes.textField)}
                    value={nodoRilevatore}
                    onChange={event => saveValue("nodoRilevatore", event.target.value)}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid
                  item
                  md={6}
                  xl={6}
                  xs={12}
                >
                  <TextField
                    fullWidth
                    required
                    id="date"
                    label="Data rilievo"
                    type="date"
                    className={classNames(classes.margin, classes.textField)}
                    value={nodoDataRilievo}
                    onChange={event => saveValue("nodoDataRilievo", event.target.value)}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{ inputProps: { max: moment(new Date()).format('YYYY-MM-DD') } }}
                  />
                </Grid>

                <Grid
                  item
                  md={6}
                  xl={6}
                  xs={12}
                  className={classes.demo}
                >

                  <TextField
                    disabled
                    fullWidth
                    label="Validatore"
                    className={classNames(classes.margin, classes.textField)}
                    value={nodoValidatore}
                    onChange={event => saveValue("nodoValidatore", event.target.value)}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid
                  item
                  md={6}
                  xl={6}
                  xs={12}
                >
                  <TextField
                    disabled
                    fullWidth
                    required
                    id="date"
                    label="Data validazione"
                    type="date"
                    className={classNames(classes.margin, classes.textField)}
                    value={nodoDataValidazione}
                    onChange={event => saveValue("nodoDataValidazione", event.target.value)}
                    margin="normal"
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>

            </Grid>
          </Grid>
        </form>
        <br></br>

        {isOpenMessage && <Snackbar
          open={isOpenMessage}
          autoHideDuration={10000}
          onClose={() => this.openMessage("", "info")}
        >
          <Alert
            onClose={() => this.openMessage("", "info")}
            severity={severity}>
            {textMessage}
          </Alert>
        </Snackbar>}

      </Fragment >
    );
  }
}

InfoAmbitoProgettuale.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(InfoAmbitoProgettuale);