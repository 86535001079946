import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import {
  TextField, MenuItem, Grid,
  Button, Typography,
  List, ListItem, ListItemText, ListItemSecondaryAction,
  IconButton, CircularProgress, LinearProgress, Snackbar
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import DeleteConfirmDialog from '../istructionsToolsDialog/DeleteConfirmDialog';
import SessionExpiredModal from '../istructionsToolsDialog/SessionExpiredModal';

//Icons
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/LibraryAdd';
import SaveIcon from '@material-ui/icons/Save';
import DownloadIcon from '@material-ui/icons/CloudDownload'

// API REQUEST
import axios from 'axios';
import {
  GetInvestigationLinearSiteClass,
  GetInvestigationLinearSiteType,
  GetLinearSiteInvestigations,
  GetLinearSiteInvestigation,
  GetLinearSiteInvestigationDocument,
  PutLinearSiteInvestigation,
  PostLinearSiteInvestigation,
  PostLinearSiteInvestigationDocument,
  DeleteLinearSiteInvestigation,
  DeleteLinearSiteInvestigationDocument
} from '../../../backEnd/requestAPI';


//IsoDate Convert
import moment from 'moment';

//Download
var fileDownload = require('js-file-download');

const styles = theme => ({
  demo: {
    height: 'auto',
  },
  divider: {
    margin: `${theme.spacing(3)}px 0`,
  },
  input: {
    margin: theme.spacing(3),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(3),
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class linearSiteInvestigations extends Component {

  constructor(props) {
    super(props);

    this.state = {

      linearSiteID: props.LinearSiteID,
      investigationSelectID: '',

      //LIST
      investigationsList: [],
      investigationClassList: [],
      investigationTypesList: [],

      //Data
      previouID: '',
      previouNameID: '',
      investigationClassID: '',
      investigationType: '',
      investigationNote: '',
      investigationDate: '',
      investigationDocument: '',
      investigationDocumentValue: '',
      investigationDocumentPage: '',
      documentName: '',

      //CONTROLLER
      openViewInvestigationParams: props.openViewInvestigationParams,
      closeViewInvestigationParams: props.closeViewInvestigationParams,
      loadingCompleteProcess: 0,
      isLoadingSave: false,
      isInvestigationToUpdate: false,
      isOpenMessage: false,
      textMessage: "",
      severity: "info",
      isOpenDeleteConfirmDialog: false,
      investigationIdToDelete: '',
      isOpenSessionExpiredModal: false

    };
  }

  componentDidMount() {
    this.getInvestigations()
    this.getClassComboBox()
  }

  //Lettura classi di indagine sito lineare
  getClassComboBox = () => {
    axios.get(GetInvestigationLinearSiteClass).then(response => {
      this.setState(prevState => ({
        investigationClassList: response.data,
        loadingCompleteProcess: prevState.loadingCompleteProcess + 50
      }))
    }).catch(error => {
      alert(error);
    });
  }

  //Lettura tipo di indagine sito lineare
  getTypeComboBox = () => {
    const { investigationClassID } = this.state

    const config = {
      params: {
        idClasseIndagine: investigationClassID
      },
      headers: { Authorization: `Bearer ${localStorage.getItem("USER_TOKEN")}` }
    };
    axios.get(GetInvestigationLinearSiteType,
      config).then(response => {
        this.setState({
          investigationTypesList: response.data,
        })
      }).catch(error => {
        alert(error);
      });
  }

  //Lettura indagini sito lineare
  getInvestigations = () => {
    const { linearSiteID } = this.state
    const config = {
      params: {
        idSitoLineare: linearSiteID,
      },
      headers: { Authorization: `Bearer ${localStorage.getItem("USER_TOKEN")}` }
    };

    axios.get(GetLinearSiteInvestigations,
      config).then(response => {
        let investigations = response.data;
        this.setState(prevState => ({
          investigationsList: investigations,
          loadingCompleteProcess: prevState.loadingCompleteProcess + 50
        }))
      }).catch(error => {
        alert(error);
      });
  }

  //Lettura dati indagine selezionata
  getInvestigation = (investigationID) => {

    const { closeViewInvestigationParams } = this.state
    const config = {
      params: {
        idIndagine: investigationID,
      },
      headers: { Authorization: `Bearer ${localStorage.getItem("USER_TOKEN")}` }
    };

    axios.get(GetLinearSiteInvestigation,
      config).then(response => {

        let investigation = response.data;
        this.setState({
          previouID: investigation.precedenteId,
          previouNameID: investigation.nomePrecedenteId,
          investigationClassID: investigation.idEnumClasseIndagine,
          investigationType: investigation.idEnumTipoIndagine,
          investigationNote: investigation.note ? investigation.note : "",
          investigationDate: moment(investigation.dataIndagine).format('YYYY-MM-DD'),
          investigationDocumentPage: investigation.paginaDocumento,
          isInvestigationToUpdate: true,
          documentName: investigation.nomeDocumento ? investigation.nomeDocumento : ''


        }, () => {
          closeViewInvestigationParams()
          this.getTypeComboBox()

        })

      }).catch(error => {
        alert(error);
      });
  }



  // Salva o aggiorna indagine
  saveinvestigation = info => {
    info.preventDefault();

    this.setState({ isLoadingSave: true })

    const {
      linearSiteID,
      previouID,
      previouNameID,
      investigationClassID,
      investigationType,
      investigationNote,
      investigationDate,
      investigationDocumentPage,
      investigationDocument,
      isInvestigationToUpdate,
      investigationSelectID
    } = this.state

    const config = {
      params: {
        idIndagine: investigationSelectID
      },
      headers: { Authorization: `Bearer ${localStorage.getItem("USER_TOKEN")}` }
    };

    const newInvestigation =
    {
      idSitoLineare: linearSiteID,
      precedenteId: previouID,
      nomePrecedenteId: previouNameID,
      idEnumClasseIndagine: parseInt(investigationClassID),
      idEnumTipoIndagine: parseInt(investigationType),
      note: investigationNote,
      dataIndagine: investigationDate,
      paginaDocumento: investigationDocumentPage ? parseInt(investigationDocumentPage) : 0
    };


    if (isInvestigationToUpdate) {

      axios.put(PutLinearSiteInvestigation,
        newInvestigation, config).then(response => {
          let newInvestigationSaved = response.data
          this.setState({
            investigationSelectID: newInvestigationSaved.idIndagine,
            isLoadingSave: false,
          }, () => {
            if (investigationDocument) {
              this.postInvestigationDocument(newInvestigationSaved)
            } else {
              this.getInvestigations()
              this.openMessage("Indagine aggiornata", "success");
            }
          })
        }).catch(error => {
          this.setState({ isLoadingSave: false })
          let errorResponse = error.response.data
          if (errorResponse.statusCode === 401) {
            this.openSessionExpiredModal()
          } else if (errorResponse.statusCode === 403) {
            this.openMessage("Utente non autorizzato su questo Comune", "error")
          } else if (errorResponse.statusCode === 400) {
            this.openMessage(errorResponse.message, "warning")
          } else {
            this.openMessage("Errore sconosciuto, contattare assistenza", "error")
          }
        });

    } else {
      axios.post(PostLinearSiteInvestigation,
        newInvestigation, config).then(response => {
          let newInvestigationSaved = response.data

          this.setState({
            investigationSelectID: newInvestigationSaved.idIndagine,
            isLoadingSave: false,
            isInvestigationToUpdate: true
          }, () => {
            if (investigationDocument) {
              this.postInvestigationDocument(newInvestigationSaved)
            } else {
              this.getInvestigations()
              this.openMessage("Indagine creata", "success");
            }
          })
        }).catch(error => {
          this.setState({ isLoadingSave: false })
          let errorResponse = error.response.data
          if (errorResponse.statusCode === 401) {
            this.openSessionExpiredModal()
          } else if (errorResponse.statusCode === 403) {
            this.openMessage("Utente non autorizzato su questo Comune", "error")
          } else if (errorResponse.statusCode === 400) {
            this.openMessage(errorResponse.message, "warning")
          } else {
            this.openMessage("Errore sconosciuto, contattare assistenza", "error")
          }
        });
    }
  }

  /**
   * 
   * CRUD Documento
   */

  setDocumentFile = event => {
    this.setState({
      investigationDocument: event.target.files[0],
      investigationDocumentValue: event.target.value
    })
  }

  getInvestigationDocument = () => {
    const { investigationSelectID } = this.state
    const config = {
      params: {
        idIndagine: investigationSelectID
      },
      responseType: 'blob',
      headers: { Authorization: `Bearer ${localStorage.getItem("USER_TOKEN")}` }
    };
    axios.get(GetLinearSiteInvestigationDocument,
      config).then(response => {
        //Download file
        fileDownload(response.data, "indagine_" + investigationSelectID + ".pdf");
      }).catch(error => {
        alert(error);
      });
  }

  postInvestigationDocument = (investigation) => {
    const { investigationDocument } = this.state

    let data = new FormData()
    data.append('documento', investigationDocument)

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem("USER_TOKEN")}`
      },
      params: {
        idIndagine: investigation.idIndagine,
      },
    };

    axios.post(PostLinearSiteInvestigationDocument, data,
      config).then(response => {

        let infoSite = response.data

        this.setState({
          isLoadingSave: false,
          investigationSelectID: investigation.idIndagine,
          documentName: infoSite.nomeDocumento

        }, () => {
          this.getInvestigations()
          //this.getInvestigation(investigation.idIndagine)
        })

      }).catch(error => {
        this.setState({ isLoadingSave: false })
        let errorResponse = error.response.data
        if (errorResponse.statusCode === 401) {
          this.openSessionExpiredModal()
        } else if (errorResponse.statusCode === 403) {
          this.openMessage("Utente non autorizzato su questo Comune", "error")
        } else if (errorResponse.statusCode === 400) {
          this.openMessage(errorResponse.message, "warning")
        } else {
          this.openMessage("Errore sconosciuto, contattare assistenza", "error")
        }
      });
  }

  deleteInvestigationDocument = () => {
    const { investigationSelectID } = this.state
    const config = {
      params: {
        idIndagine: investigationSelectID
      },
      headers: { Authorization: `Bearer ${localStorage.getItem("USER_TOKEN")}` }
    };
    console.log("TEST DELETE", investigationSelectID)
    axios.delete(DeleteLinearSiteInvestigationDocument,
      config).then(response => {
        this.setState({
          investigationDocumentValue: '',
          investigationDocument: '',
          documentName: '',
          investigationDocumentPage: ''
        }, () => {
          this.openMessage("Documento eliminato", "success");
        })
      }).catch(error => {
        alert(error);
      });
  }

  deleteInvestigation = (investigationID) => {

    const config = {
      params: {
        idIndagine: investigationID
      },
      headers: { Authorization: `Bearer ${localStorage.getItem("USER_TOKEN")}` }
    };

    axios.delete(DeleteLinearSiteInvestigation,
      config).then(response => {
        this.openDeleteConfirmDialog("")
        this.openMessage("Indagine eliminata", "success")
        this.resetDataInvestigation()
        this.getInvestigations()
      }).catch(error => {
        alert(error);
      });
  }

  setValue = name => event => {
    this.setState({ [name]: event.target.value }, () => {
      if (name === "investigationClassID") {
        this.getTypeComboBox()
      }
    });
  };

  openDeleteConfirmDialog = (idIndagine) => {
    this.setState(prevState => ({
      isOpenDeleteConfirmDialog: !prevState.isOpenDeleteConfirmDialog,
      investigationIdToDelete: idIndagine
    }))
  }

  openSessionExpiredModal = () => {
    this.setState({
      isOpenSessionExpiredModal: true
    })
  }

  closeSessionExpiredModal = () => {
    this.setState({
      isOpenSessionExpiredModal: false
    })
  }

  openMessage = (text, type) => {
    this.setState(prevState => ({
      isOpenMessage: !prevState.isOpenMessage,
      textMessage: text,
      severity: type
    }))
  }

  resetDataInvestigation = () => {
    // document.getElementById("linear-site-investigation-form").reset();

    this.setState({
      //LIST
      investigationClassList: [],
      investigationTypesList: [],

      //Data
      previouID: '',
      previouNameID: '',
      investigationClassID: '',
      investigationType: '',
      investigationNote: '',
      investigationDate: '',
      investigationDocument: '',
      investigationDocumentPage: '',
      documentName: '',
      investigationDocumentValue: '',

      //Controller
      isInvestigationToUpdate: false
    }, () => {
      this.getInvestigations()
      this.getClassComboBox()
    });
  }

  investigationSelect = value => () => {

    this.setState({
      investigationSelectID: value,
    }, () => {
      this.getInvestigation(value)
    })
  };

  render() {
    const { classes } = this.props;
    const {

      linearSiteID,
      investigationSelectID,
      investigationsList,
      investigationClassList,
      investigationTypesList,
      previouID,
      previouNameID,
      investigationClassID,
      investigationType,
      investigationNote,
      investigationDate,
      investigationDocumentValue,
      investigationDocumentPage,
      documentName,

      //CONTROLLER
      loadingCompleteProcess,
      isLoadingSave,
      isInvestigationToUpdate,
      openViewInvestigationParams,
      isOpenMessage,
      textMessage,
      severity,
      isOpenDeleteConfirmDialog,
      investigationIdToDelete,
      isOpenSessionExpiredModal
    } = this.state


    return (
      <Fragment>
        <br />
        <form id="linear-site-investigation-form" onSubmit={this.saveinvestigation} >

          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
          >
            <Grid
              container
              justify="center"
              md={10}
              xl={12}
              xs={12}
              alignItems="flex-start"
            >

              {loadingCompleteProcess < 99 ?
                <Grid
                  item
                  md={12}
                  xl={12}
                  xs={12}
                >
                  <LinearProgress variant="determinate" value={loadingCompleteProcess === 99 ? 0 : loadingCompleteProcess} />
                  <Typography variant="h6" className={classes.title} style={{ textAlign: 'center' }}>
                    Caricamento in corso {loadingCompleteProcess}%
                  </Typography>
                </Grid>
                :
                <>

                  <Grid
                    item
                    md={12}
                    xl={12}
                    xs={12}
                  >
                    <div style={{ background: '#f0f9ff', padding: '8px', marginBottom: '21px', marginTop: '21px' }}>
                      <Typography variant="h6" className={classes.title}>
                        INDAGINI SITO: <b>{linearSiteID}</b>
                      </Typography>
                    </div>

                  </Grid>

                  {/** PRIMA COLONNA PRINCIPALE */}
                  <Grid
                    container
                    spacing={1}
                    md={3}
                    xl={3}
                    xs={12}
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                  >

                    <Grid
                      item
                      md={6}
                      xl={6}
                      xs={10}
                    >
                      <Typography variant="h6" className={classes.title}>
                        Lista Indagini
                      </Typography>

                    </Grid>

                    <Grid
                      item
                      md={2}
                      xl={2}
                      xs={2}
                    >
                      <IconButton color="primary"
                        aria-label="Nuova indagine"
                        onClick={() => this.resetDataInvestigation()}
                      >
                        <AddIcon />
                      </IconButton>

                    </Grid>

                    <Grid
                      item
                      md={10}
                      xl={10}
                      xs={12}
                    >
                      <List component="nav">
                        {investigationsList.map((investigation) => {
                          return (
                            <ListItem
                              button
                              divider
                              key={investigation.idIndagine}
                              onClick={this.investigationSelect(investigation.idIndagine)}>

                              <ListItemSecondaryAction>

                                {/* <IconButton aria-label="Copy">
                                  <CopyIcon />
                                </IconButton>*/}
                                <IconButton aria-label="Delete" onClick={() => this.openDeleteConfirmDialog(investigation.idIndagine)}>
                                  <DeleteIcon />
                                </IconButton>

                              </ListItemSecondaryAction>

                              <ListItemText primary={investigation.idIndagine} />

                            </ListItem>
                          )
                        })}
                      </List>
                    </Grid>

                  </Grid>

                  {/** SECONDA COLONNA PRINCIPALE */}

                  <Grid
                    container
                    spacing={1}
                    justify="center"
                    md={9}
                    xl={9}
                    xs={12}
                  >

                    <Grid
                      item
                      md={12}
                      xl={12}
                      xs={12}
                    >
                      <Typography variant="h6" className={classes.title}>
                        Dettaglio Indagine
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      md={6}
                      xl={6}
                      xs={12}
                      className={classes.demo}
                    >

                      <TextField
                        fullWidth
                        label="Preced. id"
                        className={classNames(classes.margin, classes.textField)}
                        value={previouID}
                        onChange={this.setValue("previouID")}
                        margin="normal"
                        variant="outlined"
                        inputProps={{ maxLength: 28 }}
                        helperText={previouID.length === 28 ? "Massimo 28 caratteri accettati" : ""}
                        error={previouID.length === 28}
                      />
                    </Grid>

                    <Grid
                      item
                      md={6}
                      xl={6}
                      xs={12}
                      className={classes.demo}
                    >

                      <TextField
                        fullWidth
                        label="Nome preced. id"
                        className={classNames(classes.margin, classes.textField)}
                        value={previouNameID}
                        onChange={this.setValue("previouNameID")}
                        margin="normal"
                        variant="outlined"
                        inputProps={{ maxLength: 30 }}
                        helperText={previouNameID.length === 30 ? "Massimo 30 caratteri accettati" : ""}
                        error={previouNameID.length === 30} />
                    </Grid>

                    <Grid
                      item
                      md={6}
                      xl={6}
                      xs={12}
                      className={classes.demo}
                    >
                      <TextField
                        required
                        fullWidth
                        select
                        label="Classe indagine"
                        className={classNames(classes.margin, classes.textField)}
                        value={investigationClassID}
                        onChange={this.setValue("investigationClassID")}
                        InputLabelProps={{ shrink: true, }}
                        disabled={investigationClassID}
                        SelectProps={{
                          MenuProps: {
                            className: classes.menu,
                          },
                        }}
                        margin="normal"
                        variant="outlined"
                      >
                        {investigationClassList.map(option => (
                          <MenuItem key={option.idEnum} value={option.idEnum}>
                            {option.comboValue}
                          </MenuItem>
                        ))}
                      </TextField>

                    </Grid>

                    <Grid
                      item
                      md={6}
                      xl={6}
                      xs={12}
                      className={classes.demo}
                    >
                      <TextField
                        disabled={investigationTypesList.length === 0}
                        required
                        fullWidth
                        select
                        label="Tipo indagine"
                        className={classNames(classes.margin, classes.textField)}
                        value={investigationType}
                        onChange={this.setValue("investigationType")}
                        InputLabelProps={{ shrink: true, }}
                        SelectProps={{
                          MenuProps: {
                            className: classes.menu,
                          },
                        }}
                        margin="normal"
                        variant="outlined"
                      >
                        {investigationTypesList.map(option => (
                          <MenuItem key={option.idEnum} value={option.idEnum}>
                            {option.comboValue}
                          </MenuItem>
                        ))}
                      </TextField>

                    </Grid>

                    <Grid
                      item
                      md={3}
                      xl={3}
                      xs={12}
                    >
                      <TextField
                        required
                        fullWidth
                        label="Data indagine"
                        type="date"
                        className={classNames(classes.margin, classes.textField)}
                        value={investigationDate}
                        onChange={this.setValue("investigationDate")}
                        margin="normal"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid
                      item
                      md={9}
                      xl={9}
                      xs={12}
                      className={classes.demo}
                    >

                      <TextField
                        fullWidth
                        label="Note"
                        className={classNames(classes.margin, classes.textField)}
                        value={investigationNote}
                        onChange={this.setValue("investigationNote")}
                        margin="normal"
                        variant="outlined"
                        multiline
                        inputProps={{ maxLength: 255 }}
                        helperText={investigationNote.length === 255 ? "Massimo 255 caratteri accettati" : ""}
                        error={investigationNote.length === 255}
                      />
                    </Grid>

                    {documentName === null || documentName === '' ?

                      <Grid
                        item
                        md={6}
                        xl={6}
                        xs={12}
                      >
                        <TextField
                          fullWidth
                          className={classNames(classes.margin, classes.textField)}
                          value={investigationDocumentValue}
                          onChange={this.setDocumentFile}
                          margin="normal"
                          variant="outlined"
                          type="file"
                          name="upload_file"
                          inputProps={{ accept: '.pdf', size: 2048 }}

                        />
                      </Grid>
                      :

                      <>

                        <Grid
                          item
                          md={6}
                          xl={6}
                          xs={12}
                        >

                          <TextField
                            fullWidth
                            disabled
                            label="Documento"
                            className={classNames(classes.margin, classes.textField)}
                            value={documentName}
                            margin="normal"
                            variant="outlined"
                            InputProps={{
                              endAdornment: <>
                                <IconButton
                                  onClick={() => this.getInvestigationDocument()}
                                  className={classes.button} aria-label="Download" color="primary">
                                  <DownloadIcon />
                                </IconButton>

                                <IconButton
                                  onClick={this.deleteInvestigationDocument}
                                  className={classes.button} aria-label="Delete" color="secondary">
                                  <DeleteIcon />
                                </IconButton>
                              </>

                            }}
                          />


                        </Grid>

                      </>

                    }

                    <Grid
                      item
                      md={6}
                      xl={6}
                      xs={12}
                    >
                      <TextField
                        fullWidth
                        label="Pagina documento"
                        className={classNames(classes.margin, classes.textField)}
                        value={investigationDocumentPage}
                        onChange={this.setValue("investigationDocumentPage")}
                        margin="normal"
                        variant="outlined"
                        type="number"
                        helperText={investigationDocumentPage % 1 !== 0 ? "Inserisci un numero intero" : ""}
                        error={investigationDocumentPage % 1 !== 0}
                        InputLabelProps={{ shrink: true, }}
                      />
                    </Grid>

                  </Grid>

                  {!isLoadingSave &&
                    <>
                      <Grid
                        container
                        justify="flex-end"
                        alignContent="center"
                        md={6}
                        xl={6}
                        xs={12}
                        style={{ marginTop: "21px" }}
                      >
                        <Button
                          type="submit"
                          style={{ margin: "3px" }}
                          color="primary" variant="outlined" className={classes.button}>
                          <SaveIcon />
                          {isInvestigationToUpdate ? 'Modifica' : 'Salva'} Indagine
                        </Button>
                      </Grid>

                      <Grid
                        container
                        justify="flex-start"
                        alignContent="center"
                        alignItems="center"
                        md={6}
                        xl={6}
                        xs={12}
                        style={{ marginTop: "21px" }}
                      >
                        <Button
                          disabled={!isInvestigationToUpdate}
                          onClick={() => openViewInvestigationParams(investigationSelectID, investigationType)}
                          color="secondary" variant="outlined"
                          style={{ margin: "3px" }}
                          className={classes.button}>
                          Visualizza Parametri
                        </Button>
                      </Grid>
                    </>
                  }

                  <Grid
                    container
                    spacing={1}
                    justify="center"
                    md={12}
                    xl={12}
                    xs={12}
                    style={{ textAlign: 'center', marginTop: "13px" }}
                  >
                    {isLoadingSave && <CircularProgress />}
                  </Grid>

                </>
              }
            </Grid>
          </Grid>
        </form>
        <br></br>

        {isOpenMessage && <Snackbar
          open={isOpenMessage}
          autoHideDuration={10000}
          onClose={() => this.openMessage("", "info")}
        >
          <Alert
            onClose={() => this.openMessage("", "info")}
            severity={severity}>
            {textMessage}
          </Alert>
        </Snackbar>}

        <DeleteConfirmDialog
          handleClose={this.openDeleteConfirmDialog}
          open={isOpenDeleteConfirmDialog}
          title={"Sei sicuro di voler eliminare l'indagine " + investigationIdToDelete + " ?"}
          text="Eliminandol'indagine, verrano eliminati i parametri correlati"
          confirmFunction={() => this.deleteInvestigation(investigationIdToDelete)}
        />


        <SessionExpiredModal
          handleClose={this.closeSessionExpiredModal}
          open={isOpenSessionExpiredModal}
        />


      </Fragment >
    );
  }
}

linearSiteInvestigations.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(linearSiteInvestigations);