import React, { Component } from "react";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, Snackbar, Grid } from '@material-ui/core/';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import MuiAlert from '@material-ui/lab/Alert';


//COMPONENTS
import CommonActionDialog from '../istructionsToolsDialog/CommonActionDialog';

// API REQUEST
import axios from 'axios';
import {
  PostNodoFrana, PutNodoFrana, PutReopenNodoFrana, GetNodoFrana,
  GetCityFromInstatCode, GetBacinoFromLatLng,
  GetCityFromLatLng, GetProvinceFromCode, getUserNamePublic, GetCalcoloPr,
  GetQuadroUnione, CodeStatus, PutUpdateFranaStatus, PostAttachment, ReportFranaDownload
} from '../../../backEnd/requestAPI';
import NodoInfoGeolocalizzazione from "./nodoInfoGeolocalizzazione";
import NodoInfoAmbitoAttivita from "./nodoInfoAmbitoAttivita";
import NodoInfoElementiDissesto from "./nodoInfoElementiDissesto";
import NodoInfoViabilita from "./nodoInfoViabilita";
import NodoInfoEdificato from "./nodoInfoEdificato";
import NodoInfoCommercioRetiServizi from "./nodoInfoCommercioRetiServizi";
import NodoInfoAltriBeni from "./nodoInfoAltriBeni";
import NodoInfoEsitiClassificazione from "./nodoInfoEsitiClassificazione";

//Coordinates Trasformation
import coordsTransform from 'coordinates-transformer';

//IsoDate Convert
import moment from 'moment';

//Download
var fileDownload = require('js-file-download');

const styles = {
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
  toolbarButtons: {
    marginLeft: 'auto',
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


class nodoFranaDialogView extends Component {

  constructor(props) {
    super(props);
    let nodo = props.nodo
    console.log("TESTAPP-nodo", nodo)
    let coordinatesType = props.coordinatesType

    let latitude = nodo.Lat ? nodo.Lat : nodo.lat_etrf89
    let longitude = nodo.Lon ? nodo.Lon : nodo.lon_etrf89

    let utmEst = nodo ? nodo.utm_est : ""
    let utmNord = nodo ? nodo.utm_nord : ""

    let coordsEPSG4326
    let coordsEPSG25833

    if (coordinatesType === "EPSG:3857" && latitude != null && longitude != null) {
      coordsEPSG25833 = coordsTransform([latitude, longitude], 'EPSG:3857', 'EPSG:25833')
      coordsEPSG4326 = coordsTransform([latitude, longitude], 'EPSG:3857', 'EPSG:4326')

      latitude = coordsEPSG25833[1]
      longitude = coordsEPSG25833[0]
    }

    this.state = {

      //Data
      currentNodoID: nodo.id ? nodo.id : null,
      statoScheda: nodo.stato_scheda ? nodo.stato_scheda : undefined,

      //Coordinates in EPSG:25833
      nodoLng: longitude ? longitude : undefined,
      nodoLat: latitude ? latitude : undefined,

      //Coordinates in EPSG:4326
      viewLat: coordsEPSG4326 ? Number((coordsEPSG4326[1]).toFixed(5)) : latitude,
      viewLng: coordsEPSG4326 ? Number((coordsEPSG4326[0]).toFixed(5)) : longitude,

      // ----- GEOLOCALIZZAZIONE NODO E DATI DI CONTESTO -----
      istatCode: nodo.code_comune ? nodo.code_comune : undefined,
      nodoLocalita: nodo.localita ? nodo.localita : undefined,
      nodoNomeBacino: nodo.bacino ? nodo.bacino : "",
      nodoCodeBacino: nodo.code_bacino ? nodo.code_bacino : "",
      nodoQuota: nodo.quota_mslm ? nodo.quota_mslm : undefined,
      nodoCodiceScheda: nodo.code_scheda ? nodo.code_scheda : undefined,
      nodoVersione: nodo.versione,
      nodoLatitudinePlan: utmNord ? utmNord : Number((latitude).toFixed(0)),
      nodoLongitudinePlan: utmEst ? utmEst : Number((longitude).toFixed(0)),
      nodoNote: nodo.note_sul_contesto ? nodo.note_sul_contesto : "",
      nodoProvince: nodo.provincia ? nodo.provincia : undefined,
      nodoCity: nodo.comune ? nodo.comune : undefined,
      nodoPericolositaIdEnumSelected: nodo.idEnumPaiPericolo ? nodo.idEnumPaiPericolo : undefined,
      nodoRischioIdEnumSelected: nodo.idEnumPaiRischio ? nodo.idEnumPaiRischio : undefined,
      IGM125: nodo.foglio_igm_25000 ? nodo.foglio_igm_25000 : undefined,
      IGM150: nodo.foglio_igm_50000 ? nodo.foglio_igm_50000 : undefined,
      CTRN110: nodo.foglio_ctr_10000 ? nodo.foglio_ctr_10000 : undefined,
      CTRN12: nodo.foglio_ctr_2000 ? nodo.foglio_ctr_2000 : undefined,
      // ----- /GEOLOCALIZZAZIONE NODO E DATI DI CONTESTO -----

      // ----- AMBITO DI ATTIVITA' -----
      nodoAttivita: nodo.attivita ? nodo.attivita : undefined,
      nodoRilevatore: nodo.rilevatore ? nodo.rilevatore : undefined,
      nodoValidatore: nodo.validatore ? nodo.validatore : undefined,
      // ----- /AMBITO DI ATTIVITA' -----


      // ----- ELEMENTI DEL DISSESTO IDROGEOLOGICO -----
      nodoDimensionamentoSelected: nodo.idEnumDisDim ? nodo.idEnumDisDim : undefined,
      nodoTipoDiDissestoSelected: nodo.idEnumDisTipo ? nodo.idEnumDisTipo : undefined,
      nodoOpereStrutturaliSelected: nodo.idEnumDisOpere ? nodo.idEnumDisOpere : undefined,
      // ----- /ELEMENTI DEL DISSESTO IDROGEOLOGICO -----


      // ----- VIABILITA' -----
      nodoEsposizioneViabilitaSelected: nodo.idEnumEspViabilita ? nodo.idEnumEspViabilita : undefined,
      nodoVulnerabilitaViabilitaSelected: nodo.idEnumVulViabilita ? nodo.idEnumVulViabilita : undefined,
      esposizioniViabilitaList: [],
      vulnerabilitaViabilitaList: [],
      // ----- /VIABILITA' -----

      // ----- EDIFICATO -----
      nodoEsposizioneEdificatoSelected: nodo.idEnumEspEdificato ? nodo.idEnumEspEdificato : undefined,
      nodoVulnerabilitaEdificatoSelected: nodo.idEnumVulEdificato ? nodo.idEnumVulEdificato : undefined,
      esposizioniEdificatoList: [],
      vulnerabilitaEdificatoList: [],
      // ----- /EDIFICATO -----

      // ----- COMMERCIO / RETI / SERVIZI -----
      nodoEsposizioneCommercioRetiServiziSelected: nodo.idEnumEspCrs ? nodo.idEnumEspCrs : undefined,
      nodoVulnerabilitaCommercioRetiServiziSelected: nodo.idEnumVulCrs ? nodo.idEnumVulCrs : undefined,
      esposizioniCommercioRetiServiziList: [],
      vulnerabilitaCommercioRetiServiziList: [],
      // ----- /COMMERCIO / RETI / SERVIZI -----

      // ----- ALTRO -----
      nodoEsposizioneAltroSelected: nodo.idEnumEspAltro ? nodo.idEnumEspAltro : undefined,
      nodoVulnerabilitaAltroSelected: nodo.idEnumVulAltro ? nodo.idEnumVulAltro : undefined,
      esposizioniAltriBeniList: [],
      vulnerabilitaAltriBeniList: [],

      // ----- /ALTRO -----

      // ----- ESITI DELLA CLASSIFICAZIONE PER FINALITA' DI PROTEZIONE CIVILE (DRPC SICILIA) -----
      nodoValorePericolosita: nodo.pericolo_drpc_valore ? nodo.pericolo_drpc_valore : undefined,
      nodoValoreRischio: nodo.rischio_drpc_valore ? nodo.rischio_drpc_valore : undefined,
      nodoPericolosita: nodo.pericolo_drpc ? nodo.pericolo_drpc : undefined,
      nodoRischioSpecifico: nodo.rischio_drpc ? nodo.rischio_drpc : undefined,
      nodoPericolositaComboValue: "",
      nodoRischioComboValue: "",

      // ----- /ESITI DELLA CLASSIFICAZIONE PER FINALITA' DI PROTEZIONE CIVILE (DRPC SICILIA) -----

      //CONTROLLER
      closeDialog: props.closeDialog,
      isOpenDeleteConfirmDialog: false,
      isOpenValidConfirmDialog: false,
      isReOpenConfirmDialog: false,
      isReOpenValidateConfirmDialog: false,

      isNewNodo: nodo.id ? false : true,
      isLoadingSave: false,
      isLoadingCalcolo: false,
      isOpenMessage: false,
      textMessage: "",
      severity: "info",
      isOpenSessionExpiredModal: false,

      //Attachments
      newFileList: []


    };
  }

  componentDidMount() {
    const { currentNodoID, nodoVersione } = this.state
    if (currentNodoID != null) this.getInfoNodo(currentNodoID, nodoVersione)
    else {
      this.getUserName(localStorage.getItem("USER_ID"), "nodoRilevatore")
      this.getCity()
      this.getBacino()
      this.getQuadroUnione()
    }

  }

  handleSaveData = (name, newData) => {
    this.setState({ [name]: newData })
  }


  calcolaEsiti = () => {
    const { nodoValorePericolosita, nodoEsposizioneViabilitaSelected, nodoVulnerabilitaViabilitaSelected,
      nodoEsposizioneEdificatoSelected, nodoVulnerabilitaEdificatoSelected, nodoEsposizioneCommercioRetiServiziSelected,
      nodoVulnerabilitaCommercioRetiServiziSelected, nodoEsposizioneAltroSelected, nodoVulnerabilitaAltroSelected
    } = this.state

    this.setState({
      isLoadingCalcolo: true
    })

    if (
      (nodoValorePericolosita
        || nodoEsposizioneViabilitaSelected
        || nodoVulnerabilitaViabilitaSelected
        || nodoEsposizioneEdificatoSelected
        || nodoVulnerabilitaEdificatoSelected
        || nodoEsposizioneCommercioRetiServiziSelected
        || nodoVulnerabilitaCommercioRetiServiziSelected
        || nodoEsposizioneAltroSelected
        || nodoVulnerabilitaAltroSelected)
    ) {

      this.getCalcoloPr("pericolo")
      this.getRischioValore()
    }
    else {
      this.openMessage("Controlla di aver compilato tutti i campi obbligatori *", "error")

    }
  }

  getRischioValore = () => {

    this.setState({
      isLoadingCalcolo: true
    })

    const {
      // ----- VIABILITA' -----
      nodoEsposizioneViabilitaSelected,
      nodoVulnerabilitaViabilitaSelected,
      esposizioniViabilitaList,
      vulnerabilitaViabilitaList,
      // ----- EDIFICATO -----
      nodoEsposizioneEdificatoSelected,
      nodoVulnerabilitaEdificatoSelected,
      esposizioniEdificatoList,
      vulnerabilitaEdificatoList,
      // ----- COMMERCIO / RETI / SERVIZI -----
      nodoEsposizioneCommercioRetiServiziSelected,
      nodoVulnerabilitaCommercioRetiServiziSelected,
      esposizioniCommercioRetiServiziList,
      vulnerabilitaCommercioRetiServiziList,
      // ----- ALTRO -----
      nodoEsposizioneAltroSelected,
      nodoVulnerabilitaAltroSelected,
      esposizioniAltriBeniList,
      vulnerabilitaAltriBeniList,
      // ----- /ALTRO -----}

      nodoValorePericolosita
    } = this.state


    const nodoEsposizioneViabilita = esposizioniViabilitaList.filter(item => item.idEnum == nodoEsposizioneViabilitaSelected)[0]
    const nodoVulnerabilitaViabilita = vulnerabilitaViabilitaList.filter(item => item.idEnum == nodoVulnerabilitaViabilitaSelected)[0]
    const nodoEsposizioneEdificato = esposizioniEdificatoList.filter(item => item.idEnum == nodoEsposizioneEdificatoSelected)[0]
    const nodoVulnerabilitaEdificato = vulnerabilitaEdificatoList.filter(item => item.idEnum == nodoVulnerabilitaEdificatoSelected)[0]
    const nodoEsposizioneCommercioRetiServizi = esposizioniCommercioRetiServiziList.filter(item => item.idEnum == nodoEsposizioneCommercioRetiServiziSelected)[0]
    const nodoVulnerabilitaCommercioRetiServizi = vulnerabilitaCommercioRetiServiziList.filter(item => item.idEnum == nodoVulnerabilitaCommercioRetiServiziSelected)[0]
    const nodoEsposizioneAltro = esposizioniAltriBeniList.filter(item => item.idEnum == nodoEsposizioneAltroSelected)[0]
    const nodoVulnerabilitaAltro = vulnerabilitaAltriBeniList.filter(item => item.idEnum == nodoVulnerabilitaAltroSelected)[0]

    // CodePC
    let codePCEsposizioneViabilita = nodoEsposizioneViabilita ? parseFloat(nodoEsposizioneViabilita.codePc.replace(",", ".")) : 0
    let codePCVulnerabilitaViabilita = nodoVulnerabilitaViabilita ? parseFloat(nodoVulnerabilitaViabilita.codePc.replace(",", ".")) : 0
    let codePCEsposizioneEdificato = nodoEsposizioneEdificato ? parseFloat(nodoEsposizioneEdificato.codePc.replace(",", ".")) : 0
    let codePCVulnerabilitaEdificato = nodoVulnerabilitaEdificato ? parseFloat(nodoVulnerabilitaEdificato.codePc.replace(",", ".")) : 0
    let codePCEsposizioneCommercioRetiServizi = nodoEsposizioneCommercioRetiServizi ? parseFloat(nodoEsposizioneCommercioRetiServizi.codePc.replace(",", ".")) : 0
    let codePCVulnerabilitaCommercioRetiServizi = nodoVulnerabilitaCommercioRetiServizi ? parseFloat(nodoVulnerabilitaCommercioRetiServizi.codePc.replace(",", ".")) : 0
    let codePCEsposizioneAltro = nodoEsposizioneAltro ? parseFloat(nodoEsposizioneAltro.codePc.replace(",", ".")) : 0
    let codePCVulnerabilitaAltro = nodoVulnerabilitaAltro ? parseFloat(nodoVulnerabilitaAltro.codePc.replace(",", ".")) : 0


    /* Formula
    Rischio valore ->Campo uguale a: (([26]*[27] + [28]*[29] + [30]*[31] + [32]*[33])*[Valore pericolo]) / 3,75 
    arrotondato alla quarta cifra
    */
    let moltViabilita = codePCEsposizioneViabilita * codePCVulnerabilitaViabilita
    let moltEdificato = codePCEsposizioneEdificato * codePCVulnerabilitaEdificato
    let moltCommercioRetiServizi = codePCEsposizioneCommercioRetiServizi * codePCVulnerabilitaCommercioRetiServizi
    let moltAltro = codePCEsposizioneAltro * codePCVulnerabilitaAltro

    let somma = moltViabilita + moltEdificato + moltCommercioRetiServizi + moltAltro
    console.log("TEST-getRischioValore-nodoValorePericolosita", nodoValorePericolosita)

    let risultato = Number(((somma * nodoValorePericolosita) / 3.75).toFixed(4));

    this.setState({ nodoValoreRischio: risultato }, () => {
      this.getCalcoloPr("rischio")
    })

  }

  getCalcoloPr = (campoRichiesto) => {

    const { nodoValoreRischio, nodoValorePericolosita } = this.state
    var nodoValore = campoRichiesto == "pericolo" ? nodoValorePericolosita : nodoValoreRischio

    const config = {
      params: {
        tipo: "frana",
        campo: campoRichiesto,
        valore: nodoValore
      },
      headers: { Authorization: `Bearer ${localStorage.getItem("USER_TOKEN")}` }
    };

    axios.get(GetCalcoloPr,
      config).then(response => {


        let calcolo = response.data
        if (campoRichiesto == "pericolo") {

          this.handleSaveData("nodoPericolosita", calcolo.cl_drpc_pericolo)
          this.handleSaveData("nodoPericolositaComboValue", calcolo.pericoloValue)
          this.setState({
            isLoadingCalcolo: false
          })
        }
        else {
          this.handleSaveData("nodoRischioSpecifico", calcolo.cl_drpc_rischio)
          this.handleSaveData("nodoRischioComboValue", calcolo.rischioValue)
          this.setState({
            isLoadingCalcolo: false
          })
        }
      }).catch(error => {
        this.openMessage("Errore sconosciuto, contattare assistenza", "error");
      });
  }


  updateStatus = (status, id) => {

    const { currentNodoID, closeDialog } = this.state

    var statusMessage = status == CodeStatus.valida ? "Scheda validata" : "Scheda annullata"
    const config = {
      params: {
        idFrana: id ? id : currentNodoID,
        status: status,
      },
      headers: { Authorization: `Bearer ${localStorage.getItem("USER_TOKEN")}` }
    };

    axios.put(PutUpdateFranaStatus, null,
      config).then(response => {

        this.openMessage(statusMessage, "success")
        closeDialog()
      }).catch(error => {
        this.setState({ isLoadingSave: false })
        let errorResponse = error.response.data
        if (errorResponse.statusCode == 403) {
          this.openMessage("Utente non autorizzato su questo Comune", "error")
        } else {
          let message = errorResponse.message
          if (message) this.openMessage(message, "error")
          else this.openMessage("Errore sconosciuto, contattare assistenza", "error")
        }
      });

    this.setState({
      isOpenDeleteConfirmDialog: false,
      isOpenValidConfirmDialog: false,
    })
  }

  getInfoNodo = (nodoID, version) => {

    const config = {
      params: {
        idFrana: nodoID,
        versione: version
      },
      headers: { Authorization: `Bearer ${localStorage.getItem("USER_TOKEN")}` }
    };

    axios.get(GetNodoFrana,
      config).then(response => {

        let nodo = response.data


        this.setState({

          currentNodoID: nodo.idFrana ? nodo.idFrana : null,

          // ----- GEOLOCALIZZAZIONE NODO E DATI DI CONTESTO -----
          nodoPericolositaIdEnumSelected: nodo.idEnumPaiPericolo ? nodo.idEnumPaiPericolo : undefined,
          nodoRischioIdEnumSelected: nodo.idEnumPaiRischio ? nodo.idEnumPaiRischio : undefined,
          nodoDataUltimoEvento: nodo.dataUltimoEvento ? moment(nodo.dataUltimoEvento).format('YYYY-MM-DD') : undefined,
          // ----- /GEOLOCALIZZAZIONE NODO E DATI DI CONTESTO -----

          // ----- AMBITO DI ATTIVITA' -----
          nodoDataRilievo: nodo.dataModifica ? moment(nodo.dataModifica).format('YYYY-MM-DD') : undefined,
          nodoDataValidazione: nodo.dataValidazione ? moment(nodo.dataValidazione).format('YYYY-MM-DD') : undefined,
          // ----- /AMBITO DI ATTIVITA' -----

          // ----- ELEMENTI DEL DISSESTO IDROGEOLOGICO -----
          nodoDimensionamentoSelected: nodo.idEnumDisDim ? nodo.idEnumDisDim : undefined,
          nodoTipoDiDissestoSelected: nodo.idEnumDisTipo ? nodo.idEnumDisTipo : undefined,
          nodoOpereStrutturaliSelected: nodo.idEnumDisOpere ? nodo.idEnumDisOpere : undefined,
          // ----- /ELEMENTI DEL DISSESTO IDROGEOLOGICO -----

          // ----- VIABILITA' -----
          nodoEsposizioneViabilitaSelected: nodo.idEnumEspViabilita ? nodo.idEnumEspViabilita : undefined,
          nodoVulnerabilitaViabilitaSelected: nodo.idEnumVulViabilita ? nodo.idEnumVulViabilita : undefined,
          // ----- /VIABILITA' -----

          // ----- EDIFICATO -----
          nodoEsposizioneEdificatoSelected: nodo.idEnumEspEdificato ? nodo.idEnumEspEdificato : undefined,
          nodoVulnerabilitaEdificatoSelected: nodo.idEnumVulEdificato ? nodo.idEnumVulEdificato : undefined,
          // ----- /EDIFICATO -----

          // ----- COMMERCIO / RETI / SERVIZI -----
          nodoEsposizioneCommercioRetiServiziSelected: nodo.idEnumEspCrs ? nodo.idEnumEspCrs : undefined,
          nodoVulnerabilitaCommercioRetiServiziSelected: nodo.idEnumVulCrs ? nodo.idEnumVulCrs : undefined,
          // ----- /COMMERCIO / RETI / SERVIZI -----

          // ----- ALTRO -----
          nodoEsposizioneAltroSelected: nodo.idEnumEspAltro ? nodo.idEnumEspAltro : undefined,
          nodoVulnerabilitaAltroSelected: nodo.idEnumVulAltro ? nodo.idEnumVulAltro : undefined,
        })

      }).catch(error => {
        this.openMessage("Errore sconosciuto, contattare assistenza", "error");
      });
  }


  getQuadroUnione() {
    const { nodoLat, nodoLng } = this.state
    let config = {
      params: {
        latitudine: nodoLat,
        longitudine: nodoLng
      },
      headers: { Authorization: `Bearer ${localStorage.getItem("USER_TOKEN")}` }
    };
    axios.get(GetQuadroUnione,
      config).then(response => {
        let quadroUnione = response.data;

        this.setState({
          IGM125: quadroUnione.igm_25k ? quadroUnione.igm_25k : undefined,
          IGM150: quadroUnione.igm_50k ? quadroUnione.igm_50k : undefined,
          CTRN110: quadroUnione.ctr_10k ? quadroUnione.ctr_10k : undefined,
          CTRN12: quadroUnione.ctr_2k ? quadroUnione.ctr_2k : undefined,
        })


      }).catch(error => {
        this.openMessage("Errore sconosciuto, contattare assistenza", "error")
      });
  }

  getBacino() {
    const { nodoLat, nodoLng } = this.state
    let config = {
      params: {
        latitudine: nodoLat,
        longitudine: nodoLng
      },
      headers: { Authorization: `Bearer ${localStorage.getItem("USER_TOKEN")}` }
    };
    axios.get(GetBacinoFromLatLng,
      config).then(response => {
        let bacino = response.data;
        this.handleSaveData("nodoCodeBacino", bacino.codebacino)
        this.handleSaveData("nodoNomeBacino", bacino.nomebacino)
      }).catch(error => {
        this.openMessage("Errore sconosciuto, contattare assistenza", "error")
      });
  }

  getCity() {
    const { nodoLat, nodoLng, istatCode } = this.state
    let config = {}
    let endPoint = ""
    if (istatCode != undefined) {
      endPoint = GetCityFromInstatCode
      config = {
        params: {
          codiceIstat: istatCode,
        },
        headers: { Authorization: `Bearer ${localStorage.getItem("USER_TOKEN")}` }
      };
    } else {
      endPoint = GetCityFromLatLng
      config = {
        params: {
          latitudine: nodoLat,
          longitudine: nodoLng
        },
        headers: { Authorization: `Bearer ${localStorage.getItem("USER_TOKEN")}` }
      };
    }
    axios.get(endPoint,
      config).then(response => {
        let city = response.data;
        this.handleSaveData("nodoCity", city.denominazione)
        this.handleSaveData("istatCode", city.codiceIstat)
        this.getProvinceAbbreviation(city.codiceProvincia)
      }).catch(error => {
        this.openMessage("Errore sconosciuto, contattare assistenza", "error")
      });
  }
  getProvinceAbbreviation = (provinceCode) => {
    const config = {
      params: {
        codiceProvincia: provinceCode,
      },
      headers: { Authorization: `Bearer ${localStorage.getItem("USER_TOKEN")}` }
    };
    axios.get(GetProvinceFromCode,
      config).then(response => {
        let province = response.data;

        this.handleSaveData("nodoProvince", province.sigla)

      }).catch(error => {
        this.openMessage("Errore sconosciuto, contattare assistenza", "error")
      });
  }

  getUserName(userID, type) {
    const config = {
      params: {
        idUtente: userID,
        headers: { Authorization: `Bearer ${localStorage.getItem("USER_TOKEN")}` }
      }
    };
    axios.get(getUserNamePublic,
      config).then(response => {
        let user = response.data;
        this.setState({
          [type]: user.nome + " " + user.cognome
        })
      }).catch(error => {
        let errorResponse = error.response.data
        if (errorResponse.statusCode == 404) {
          this.setState({
            [type]: "[Utente non trovato]"
          })
          this.openMessage(errorResponse.message, "warning")
        }
        else this.openMessage("Errore sconosciuto, contattare assistenza", "error");
      });
  }

  saveNodo = (isToValidate) => {

    this.setState({
      isLoadingSave: true
    })

    const {
      //Data
      currentNodoID,

      //Coordinates in EPSG:25833
      viewLat,
      viewLng,

      // ----- GEOLOCALIZZAZIONE NODO E DATI DI CONTESTO -----
      istatCode,
      nodoLocalita,
      nodoQuota,
      nodoDataUltimoEvento,
      nodoNote,
      nodoPericolositaIdEnumSelected,
      nodoRischioIdEnumSelected,
      nodoLatitudinePlan,
      nodoLongitudinePlan,
      // ----- /GEOLOCALIZZAZIONE NODO E DATI DI CONTESTO -----

      // ----- AMBITO DI ATTIVITA' -----
      nodoAttivita,
      nodoDataRilievo,
      // ----- /AMBITO DI ATTIVITA' -----


      // ----- ELEMENTI DEL DISSESTO IDROGEOLOGICO -----
      nodoDimensionamentoSelected,
      nodoTipoDiDissestoSelected,
      nodoOpereStrutturaliSelected,
      // ----- /ELEMENTI DEL DISSESTO IDROGEOLOGICO -----


      // ----- VIABILITA' -----
      nodoEsposizioneViabilitaSelected,
      nodoVulnerabilitaViabilitaSelected,
      // ----- /VIABILITA' -----

      // ----- EDIFICATO -----
      nodoEsposizioneEdificatoSelected,
      nodoVulnerabilitaEdificatoSelected,
      // ----- /EDIFICATO -----

      // ----- COMMERCIO / RETI / SERVIZI -----
      nodoEsposizioneCommercioRetiServiziSelected,
      nodoVulnerabilitaCommercioRetiServiziSelected,
      // ----- /COMMERCIO / RETI / SERVIZI -----

      // ----- ALTRO -----
      nodoEsposizioneAltroSelected,
      nodoVulnerabilitaAltroSelected,
      // ----- /ALTRO -----

      // -----   ESITI DELLA CLASSIFICAZIONE -----
      nodoPericolosita,
      nodoValorePericolosita,
      nodoRischioSpecifico,
      nodoValoreRischio,
      // -----   /ESITI DELLA CLASSIFICAZIONE -----
      closeDialog
    } = this.state


    if (
      !nodoValorePericolosita ||
      !nodoPericolosita ||
      !nodoValoreRischio ||
      !nodoRischioSpecifico
    ) this.calcolaEsiti()

    const config = {
      params: {
        idFrana: currentNodoID
      },
      headers: { Authorization: `Bearer ${localStorage.getItem("USER_TOKEN")}` }
    };

    const newNodo =
    {
      codiceIstat: istatCode,
      latitudine: viewLat,
      longitudine: viewLng,
      latitudinePlan: nodoLatitudinePlan,
      longitudinePlan: nodoLongitudinePlan,
      localita: nodoLocalita,
      quota_mt: nodoQuota,
      dataUltimoEvento: nodoDataUltimoEvento,
      note: nodoNote,
      attivita: nodoAttivita,
      dataRilievo: nodoDataRilievo,
      idEnumPaiPericolo: nodoPericolositaIdEnumSelected,
      idEnumPaiRischio: nodoRischioIdEnumSelected,
      idEnumDisDim: nodoDimensionamentoSelected,
      idEnumDisTipo: nodoTipoDiDissestoSelected,
      idEnumDisOpere: nodoOpereStrutturaliSelected,
      idEnumEspViabilita: nodoEsposizioneViabilitaSelected,
      idEnumVulViabilita: nodoVulnerabilitaViabilitaSelected,
      idEnumEspEdificato: nodoEsposizioneEdificatoSelected,
      idEnumVulEdificato: nodoVulnerabilitaEdificatoSelected,
      idEnumEspAltro: nodoEsposizioneAltroSelected,
      idEnumVulAltro: nodoVulnerabilitaAltroSelected,
      idEnumEspCrs: nodoEsposizioneCommercioRetiServiziSelected,
      idEnumVulCrs: nodoVulnerabilitaCommercioRetiServiziSelected,
      drpcPericolo: nodoPericolosita,
      drpcPericoloValore: nodoValorePericolosita,
      drpcRischio: nodoRischioSpecifico,
      drpcRischioValore: nodoValoreRischio
    };

    if (currentNodoID != null) {
      axios.put(PutNodoFrana,
        newNodo, config).then(response => {

          this.setState({
            isLoadingSave: false,
            isNewNodo: false
          }, () => {
            if (isToValidate) this.updateStatus(CodeStatus.valida, response.data.idFrana)
            else {
              this.openMessage("Scheda aggiornata", "success")
              closeDialog()
            }
          })

        }).catch(error => {
          this.setState({ isLoadingSave: false })
          let errorResponse = error.response.data
          if (errorResponse.statusCode == 403) {
            this.openMessage("Utente non autorizzato su questo Comune", "error")
          } else {
            let message = errorResponse.message
            if (message) this.openMessage(message, "error")
            else this.openMessage("Errore sconosciuto, contattare assistenza", "error")
          }
        });
    } else {

      axios.post(PostNodoFrana,
        newNodo, config).then(response => {
          console.log("TEST-post----->", response)

          this.setState({
            isLoadingSave: false,
            isNewNodo: false
          }, () => {
            if (isToValidate) this.updateStatus(CodeStatus.valida, response.data.idFrana)
            else {
              this.openMessage("Scheda creata", "success")
              closeDialog()
            }
          })

        }).catch(error => {
          this.setState({ isLoadingSave: false })
          let errorResponse = error.response.data
          if (errorResponse.statusCode == 403) {
            this.openMessage("Utente non autorizzato su questo Comune", "error")
          } else {
            let message = errorResponse.message
            if (message) this.openMessage(message, "error")
            else this.openMessage("Errore sconosciuto, contattare assistenza", "error")
          }
        });
    }

  }

  reOpenNodo = (isToValidate) => {
    const { closeDialog, currentNodoID } = this.state
    const config = {
      params: {
        idFrana: currentNodoID
      },
      headers: { Authorization: `Bearer ${localStorage.getItem("USER_TOKEN")}` }
    };

    axios.put(PutReopenNodoFrana, null,
      config).then(response => {
        this.setState({
          currentNodoID: response.data.idFrana
        }, () => {
          this.saveNodo(isToValidate)
        })
        this.openMessage("Scheda aggiornata", "success")
        setTimeout(() => {
          this.openDeleteConfirmDialog("")
          closeDialog()
        }, 2000);
      }).catch(error => {
        let errorResponse = error.response.data
        if (errorResponse.statusCode == 404) {
          this.openMessage("Scheda nodo non trovata", "error")
        } else {
          let message = errorResponse.message
          if (message) this.openMessage(message, "error")
          else this.openMessage("Errore sconosciuto, contattare assistenza", "error")
        }
      });

    this.setState({
      isReOpenConfirmDialog: false,
      isReOpenValidateConfirmDialog: false
    })
  }

  postAttachment = (code) => {
    const { newFileList } = this.state

    newFileList.map(fileItem => {
      const data = new FormData()
      data.append('file', fileItem.file)
      data.append('codeScheda', code)
      data.append('descrizione', fileItem.fileDescription)

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("USER_TOKEN")}`,
          'Content-Type': 'multipart/form-data'
        }
      };

      axios.post(PostAttachment, data,
        config).then(response => {

          this.getAttachments()

        }).catch(error => {


          this.setState({ isLoadingSave: false })
          let errorResponse = error.response.data
          console.log("TESTAPP-error", errorResponse)

          if (errorResponse.statusCode === 401) {
            this.openSessionExpiredModal()
          } else if (errorResponse.statusCode === 403) {
            this.openMessage("Utente non autorizzato su questo Comune", "error")
          } else if (errorResponse.statusCode === 400) {
            this.openMessage(errorResponse.message, "warning")
          } else if (errorResponse.statusCode === 404) {
            this.openMessage(errorResponse.message, "warning")
          } else {
            this.openMessage("Errore sconosciuto, contattare assistenza", "error")
          }
        });
    })
  }


  downloadReport = () => {
    const { currentNodoID, nodoVersione } = this.state
    const config = {
      params: {
        idFrana: currentNodoID,
      },
      responseType: 'blob',
      headers: { Authorization: `Bearer ${localStorage.getItem("USER_TOKEN")}` }
    };

    axios.get(
      ReportFranaDownload,
      config)
      .then(response => {

        if (response.status === 200) {
          fileDownload(response.data, currentNodoID + "_" + nodoVersione + ".pdf");
          this.openMessage("Download file", "success")
        } else if (response.status === 404) {
          this.openMessage("File non trovato", "error")
        }
      })
      .catch(err => {
        const errorMessage = err.response.data.message
        const message = errorMessage ? errorMessage : "Errore sconosciuto, contattare assistenza"
        this.openMessage(message, "error")
      });
  }


  openDeleteConfirmDialog = () => {
    this.setState(prevState => ({
      isOpenDeleteConfirmDialog: !prevState.isOpenDeleteConfirmDialog,
    }))
  }
  openValidConfirmDialog = () => {
    this.setState(prevState => ({
      isOpenValidConfirmDialog: !prevState.isOpenValidConfirmDialog,
    }))
  }

  reOpenConfirmDialog = () => {
    this.setState(prevState => ({
      isReOpenConfirmDialog: !prevState.isReOpenConfirmDialog,
    }))
  }

  reOpenValidateConfirmDialog = () => {
    this.setState(prevState => ({
      isReOpenValidateConfirmDialog: !prevState.isReOpenValidateConfirmDialog,
    }))
  }

  openMessage = (text, type) => {
    this.setState(prevState => ({
      isOpenMessage: !prevState.isOpenMessage,
      textMessage: text,
      severity: type
    }))
  }

  setNewFileList = (newList) => {
    this.setState({
      newFileList: newList
    })
  }



  render() {
    const { classes, isOpen, nodo, isNewNodo, coordinatesType } = this.props;
    const {
      closeDialog,
      isOpenDeleteConfirmDialog,
      isOpenValidConfirmDialog,
      isReOpenConfirmDialog,
      isReOpenValidateConfirmDialog,
      isOpenMessage, textMessage, severity,
      statoScheda, newFileList, isLoadingCalcolo
    } = this.state;

    const rolesUser = JSON.parse(localStorage.getItem("ROLES_USER"))
    console.log("ROLES_USER------> ", rolesUser)


    return (
      <div>
        <Dialog fullScreen open={isOpen} onClose={closeDialog} TransitionComponent={Transition}>
          <AppBar className={classes.appBar} style={{ background: '#ac4450' }}>
            <Toolbar style={{background: '#2F2483'}}>
              <IconButton edge="start" color="inherit" onClick={() => (closeDialog())} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Scheda Nodo Frana
              </Typography>
              <div className={classes.toolbarButtons}>


                {!isNewNodo &&
                  <Typography variant="p" className={classes.title}>
                    Stato scheda: <b>{statoScheda}</b>
                  </Typography>}

              </div>
            </Toolbar>
          </AppBar>

          <NodoInfoGeolocalizzazione
            nodo={this.state}
            saveValue={this.handleSaveData}
            coordinatesType={coordinatesType}
            nodoType="Nodo_frana"

          />

          <NodoInfoAmbitoAttivita
            nodo={this.state}
            saveValue={this.handleSaveData}
          />

          <NodoInfoElementiDissesto
            nodo={this.state}
            saveValue={this.handleSaveData}
            nodoType="Nodo_frana"

          />

          <NodoInfoViabilita
            nodo={this.state}
            saveValue={this.handleSaveData}
          />

          <NodoInfoEdificato
            nodo={this.state}
            saveValue={this.handleSaveData}
          />

          <NodoInfoCommercioRetiServizi
            nodo={this.state}
            saveValue={this.handleSaveData}
          />

          <NodoInfoAltriBeni
            nodo={this.state}
            saveValue={this.handleSaveData}
          />


          <NodoInfoEsitiClassificazione
            nodo={this.state}
            saveValue={this.handleSaveData}
            nodoType="Nodo_frana"
            calcolaEsiti={this.calcolaEsiti}
            isLoading={isLoadingCalcolo}
          />

          {/* <UploadAttachmentsComponent
            attachmentTotal={3}
            attachmentList={[{
              file: "Test",
              fileValue: "Test",
              fileName: "Test",
              fileDescription: "Test"
            }]}
            newFileList={newFileList}
            setNewFileList={this.setNewFileList}
          />*/}

          {isOpenMessage && <Snackbar
            open={isOpenMessage}
            autoHideDuration={10000}
            onClose={() => this.openMessage("", "info")}
          >
            <Alert
              onClose={() => this.openMessage("", "info")}
              severity={severity}>
              {textMessage}
            </Alert>
          </Snackbar>}




          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
            style={{ padding: '21px' }}
          >

            <Grid
              container
              justify="center"
              style={{ marginTop: '55px' }}
              md={8}
              xl={8}
              xs={12}
            >

              <Grid
                container
                justify="flex-end"
                alignContent="center"
                md={4}
                xl={4}
                xs={12}
              >
                <Button
                  fullWidth
                  type="submit"
                  style={{ margin: "3px" }}
                  onClick={statoScheda === "Validato" ? () => this.reOpenConfirmDialog() : () => this.saveNodo(false)}
                  color="primary" variant="outlined" className={classes.button}>
                  SALVA SENZA VALIDARE
                </Button>
              </Grid>

              <Grid
                container
                justify="flex-end"
                alignContent="center"
                md={4}
                xl={4}
                xs={12}
              >
                <Button
                  disabled={!rolesUser.some(role => role.ruolo == "VALIDATORE")}
                  fullWidth
                  type="submit"
                  style={{ margin: "3px" }}
                  onClick={statoScheda === "Validato" ? () => this.reOpenValidateConfirmDialog() : () => this.openValidConfirmDialog()}
                  color="primary" variant="outlined" className={classes.button}>
                  VALIDA E SALVA
                </Button>
              </Grid>

              <Grid
                container
                justify="flex-end"
                alignContent="center"
                md={4}
                xl={4}
                xs={12}
              >
                <Button
                  fullWidth
                  type="submit"
                  style={{ margin: "3px" }}
                  onClick={this.openDeleteConfirmDialog}
                  disabled={isNewNodo || (!rolesUser.some(role => role.ruolo == "VALIDATORE") && statoScheda === "Validato")}
                  color="primary" variant="outlined" className={classes.button}>
                  ELIMINA
                </Button>
              </Grid>

            </Grid>
          </Grid>



          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
            style={{ padding: '21px' }}
          >



            <Grid
              container
              direction="row"
              justify="center"
              alignItems="flex-start"
            >

              <Grid
                container
                justify="center"
                style={{ marginBottom: '55px' }}
                md={8}
                xl={8}
                xs={8}
              >

                <Grid
                  container
                  justify="flex-end"
                  alignContent="center"
                  md={12}
                  xl={12}
                  xs={12}
                >
                  <Button
                    fullWidth
                    type="submit"
                    style={{ margin: "3px" }}
                    onClick={() => this.downloadReport()}
                    disabled={isNewNodo}
                    color="primary" variant="outlined" className={classes.button}>
                    SCARICA PDF SCHEDA CORRENTE
                  </Button>
                </Grid>


              </Grid>
            </Grid>
          </Grid>
        </Dialog>

        <CommonActionDialog
          handleClose={() => this.openDeleteConfirmDialog()}
          open={isOpenDeleteConfirmDialog}
          title={"Sei sicuro di voler eliminare la scheda " + nodo.id + " ?"}
          text="Eliminando la scheda, verrano eliminate tutti i parametri correlati"
          confirmFunction={() => this.updateStatus(CodeStatus.annulla)}
        />

        <CommonActionDialog
          handleClose={() => this.openValidConfirmDialog()}
          open={isOpenValidConfirmDialog}
          title={"Sei sicuro di voler validare la scheda  ?"}
          text=""
          confirmFunction={() => this.saveNodo(true)}
        />

        <CommonActionDialog
          handleClose={() => this.reOpenConfirmDialog()}
          open={isReOpenConfirmDialog}
          title={"Sei sicuro di voler salvare la scheda " + nodo.id + " ?"}
          text="Verrà creata una scheda identica con stato 'Da Validare'"
          confirmFunction={() => this.reOpenNodo(false)}
        />

        <CommonActionDialog
          handleClose={() => this.reOpenValidateConfirmDialog()}
          open={isReOpenValidateConfirmDialog}
          title={"Sei sicuro di voler salvare la scheda " + nodo.id + " ?"}
          text="Verrà creata una scheda identica con stato 'Validata'"
          confirmFunction={() => this.reOpenNodo(true)}
        />



      </div>
    );
  }
}

nodoFranaDialogView.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(nodoFranaDialogView);
