import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import { Snackbar } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Logo from './image/logo_app.png'

import Background from './image/image_login.jpeg'

import AppMap from '../App'
import packageJson from '../../package.json';
import preval from 'preval.macro';
import moment from 'moment';

import NoteAppDialog from './noteAppDialog'
// API REQUEST
import axios from 'axios';
import {
  authLogin
} from '../backEnd/requestAPI';

const buildTimestamp = preval`module.exports = new Date().getTime();`;



function getDateString() {
  const lastUpdateMoment = moment.unix(buildTimestamp / 1000);
  const formattedDate = lastUpdateMoment.format('DD.MM.YYYY HH:mm:ss');

  return formattedDate;
};


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      <p><b>Versione {packageJson.version} del {getDateString()}
      </b> </p>
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: `url(${Background})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));



function SignInSide(props) {
  const classes = useStyles();

  const { openNodeApp, guestLogin } = props


  return (
    <Grid container component="main" className={classes.root}>
      <Grid item xs={false} sm={4} md={9} className={classes.image} />

      <Grid item xs={12} sm={8} md={3} component={Paper} elevation={6} square>
        <div className={classes.paper}>


          <table style={{ marginBottom: '55px' }}>
            <tbody>
              <tr>
                <td style={{ textAlign: 'center', marginTop: '55px' }} >  <img src={Logo} height="144px" style={{ padding: '13px' }} alt='Logo app'></img></td>
              </tr>
              <tr>
                <td colspan="2" style={{ textAlign: 'center', marginTop: '55px' }}>
                  <Typography component="h1" variant="h4" style={{ color: '#2F2483' }}>
                    <b>WebGIS</b>
                  </Typography>
                </td>
              </tr>
              <tr>
                <td colspan="2" style={{ textAlign: 'center', marginTop: '55px' }}>
                  <h2>
                    <b>Comune di Vittoria</b>
                  </h2>
                </td>
              </tr>
            </tbody>
          </table>


          <Button
            fullWidth
            variant="contained"
            style={{ background: '#2F2483', color: '#FFFFFF', fontSize: '21px' }}
            onClick={() => guestLogin()}
          >
            <b>ACCEDI</b>
          </Button>



          <Typography component="h1" variant="h6" style={{ marginTop: '34px' }}>
            <u><b>AVVERTENZE</b></u>
          </Typography>
          <p style={{ textAlign: 'center', lineHeight: '1.5' }}>
            Il WebGIS ha carattere meramente informativo e di supporto cognitivo; pertanto, gli utilizzatori delle informazioni hanno l’onere dei riscontri sullo stato reale del territorio.
            <br></br>
            Tutti i dati in esso contenuti e rappresentati non sono esaustivi e sono soggetti a periodici aggiornamenti.
            <br></br>
            Vedere le <b><a onClick={() => openNodeApp()} style={{ cursor: 'pointer' }}>Note esplicative</a></b>.
          </p>
          <p style={{ textAlign: 'center', lineHeight: '1.5' }}>
            Con l’accesso al WebGIS si accettano le condizioni di cui alle superiori avvertenze
          </p>
          <Box mt={5}>
            <Copyright />
          </Box>


        </div>
      </Grid>
    </Grid>
  );
}


class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenWebGis: false,
      isOpenMessage: false,
      isOpenNoteApp: false,
      textMessage: '',
      isErrorAuthorized: false,
      isLoadingLogin: false,
    };
  };

  componentDidMount() {
    this.checkSessionActive()
  }

  onOpenWebGis = () => {
    this.setState({
      isOpenWebGis: true
    })
  }

  //Apre eventuali messaggi di errore
  openMessage = text => {
    this.setState(prevState => ({
      isOpenMessage: !prevState.isOpenMessage,
      textMessage: text,
    }))
  }


  openNoteApp = () => {
    this.setState(prevState => ({
      isOpenNoteApp: !prevState.isOpenNoteApp,
    }))
  }



  // ACCEDI COME OSPITE
  guestLogin = () => {

    this.setState({
      isLoadingLogin: true,
      isErrorAuthorized: false
    })

    let user = {
      username: "ospite",
      password: "ospite"
    }

    axios.post(authLogin,
      user).then(response => {
        let userAuth = response.data
        let userRole = userAuth.ruoli

        this.setState({
          isErrorAuthorized: false,
          isLoadingLogin: false
        }, () => {
          localStorage.setItem("USER_TOKEN", userAuth.access_token);
          localStorage.setItem("USER_ID", userAuth.utente.idUtente);
          localStorage.setItem("USER_NAME", userAuth.utente.nome + " " + userAuth.utente.cognome)
          localStorage.setItem("ROLES_USER", JSON.stringify(userRole));
          localStorage.setItem("USER_TOKEN_EXPIRATION_DATE", userAuth.expiration_date)
          localStorage.setItem("USER_TYPE", "GUEST")
          this.onOpenWebGis()
        })


      }).catch(error => {
        this.setState({
          isLoadingLogin: false,
          isErrorAuthorized: true
        })

        let statusCode = error.response.data.statusCode
        if (statusCode == 401) this.openMessage("Utente non autorizzato")
        else alert(error);

      });
  }

  checkSessionActive = () => {

    if (localStorage.getItem("USER_TOKEN")) {
      var dateToken = new Date(localStorage.getItem("USER_TOKEN_EXPIRATION_DATE"));
      this.setState({
        isOpenWebGis: new Date() < dateToken
      })
    }
  }

  render() {
    const { isOpenWebGis, isOpenMessage, textMessage, isErrorAuthorized, isLoadingLogin, isOpenNoteApp } = this.state
    return (
      <div>
        {isOpenWebGis ?
          <AppMap />
          :
          <SignInSide
            openWebGis={this.onOpenWebGis}
            openNodeApp={this.openNoteApp}
            guestLogin={this.guestLogin}
            isErrorAuthorized={isErrorAuthorized}
            isLoadingLogin={isLoadingLogin} />
        }


        {isOpenNoteApp &&
          <NoteAppDialog
            isOpen={isOpenNoteApp}
            closeDialog={this.openNoteApp}
          />}

        <Snackbar
          open={isOpenMessage}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          autoHideDuration={5000}
          onClose={() => this.openMessage("")}
          message={<span>{textMessage}</span>}
        />
      </div>
    );
  }
}

export default LoginPage;