import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  TextField, MenuItem, Grid, Typography, Snackbar
} from '@material-ui/core';
import classNames from 'classnames';
import MuiAlert from '@material-ui/lab/Alert';



// API REQUEST
import axios from 'axios';
import {
  GetComboBoxChild, ComboBoxCode
} from '../../../backEnd/requestAPI';

const styles = theme => ({
  demo: {
    height: 'auto',
  },
  divider: {
    margin: `${theme.spacing(3)}px 0`,
  },
  input: {
    margin: theme.spacing(3),
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(3),
  },
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class InfoElementiDissesto extends Component {

  constructor(props) {
    super(props);

    this.state = {

      // COMBO BOX
      dimensionamentiList: [],
      tipiDiDissentoList: [],
      opereStrutturaliList: [],

      // CONTROLLER
      closeDialog: props.closeDialog,
      textMessage: "",
      severity: "info",
      isOpenMessage: false,
      isCodePcUpdate: false,

      // CodePC
      dimensionamentoCodePc: undefined,
      tipoDissestoCodePc: undefined,
      operaStrutturaleCodePc: undefined
    };

  }

  componentDidMount() {
    this.getDimensionamentoComboBox()
    this.getTipiDiDisstestoComboBox()
    this.getOpereStrutturaliComboBox()
  }

  getDimensionamentoComboBox = () => {

    const config = {
      params: {
        codeAttr: ComboBoxCode.cl_dis_dim,
      },
      headers: { Authorization: `Bearer ${localStorage.getItem("USER_TOKEN")}` }
    };
    axios.get(GetComboBoxChild,
      config).then(response => {
        this.setState({
          dimensionamentiList: response.data
        })
      }).catch(error => {
        this.openMessage("Errore sconosciuto, contattare assistenza", "error")
      });
  }

  getTipiDiDisstestoComboBox = () => {
    const config = {
      params: {
        codeAttr: ComboBoxCode.cl_dis_tipo,
      },
      headers: { Authorization: `Bearer ${localStorage.getItem("USER_TOKEN")}` }
    };
    axios.get(GetComboBoxChild,
      config).then(response => {
        this.setState({
          tipiDiDissentoList: response.data,
        })
      }).catch(error => {
        this.openMessage("Errore sconosciuto, contattare assistenza", "error")
      });
  }

  getOpereStrutturaliComboBox = () => {

    const { nodoType } = this.props

    const config = {
      params: {
        codeAttr: nodoType === "Nodo_frana" ? ComboBoxCode.cl_dis_opere_frane : ComboBoxCode.cl_dis_opere_idro,
      },
      headers: { Authorization: `Bearer ${localStorage.getItem("USER_TOKEN")}` }
    };
    axios.get(GetComboBoxChild,
      config).then(response => {
        this.setState({
          opereStrutturaliList: response.data,
        })
      }).catch(error => {
        this.openMessage("Errore sconosciuto, contattare assistenza", "error")
      });
  }



  openMessage = (text, type) => {
    this.setState(prevState => ({
      isOpenMessage: !prevState.isOpenMessage,
      textMessage: text,
      severity: type
    }))
  }

  getCodePC = (campo, idEnumSelected) => {
    const { saveValue } = this.props;
    const { tipiDiDissentoList, dimensionamentiList, opereStrutturaliList } = this.state

    if (campo === "nodoDimensionamentoSelected") {
      const dimensionamento = dimensionamentiList.filter(item => item.idEnum === idEnumSelected)
      this.setState({
        dimensionamentoCodePc: parseFloat(dimensionamento[0].codePc)
      }, () => {
        saveValue("nodoDimensionamentoSelected", idEnumSelected)
        this.getValorePericolosita()
      })

    } else if (campo === "nodoTipoDiDissestoSelected") {
      const tipoDissesto = tipiDiDissentoList.filter(item => item.idEnum === idEnumSelected)
      this.setState({
        tipoDissestoCodePc: parseFloat(tipoDissesto[0].codePc)
      }, () => {
        saveValue("nodoTipoDiDissestoSelected", idEnumSelected)
        this.getValorePericolosita()
      })
    } else if (campo === "nodoOpereStrutturaliSelected") {
      const operaStrutturale = opereStrutturaliList.filter(item => item.idEnum === idEnumSelected)
      this.setState({
        operaStrutturaleCodePc: parseFloat(operaStrutturale[0].codePc)
      }, () => {
        saveValue("nodoOpereStrutturaliSelected", idEnumSelected)
        this.getValorePericolosita()
      })

    }


  }


  updateCodePC = () => {
    const {
      nodoDimensionamentoSelected,
      nodoTipoDiDissestoSelected,
      nodoOpereStrutturaliSelected,
    } = this.props.nodo

    const { tipiDiDissentoList, dimensionamentiList, opereStrutturaliList } = this.state

    console.log("TEST-updateCodePC-nodoDimensionamentoSelected", nodoDimensionamentoSelected)
    console.log("TEST-updateCodePC-nodoTipoDiDissestoSelected", nodoTipoDiDissestoSelected)
    console.log("TEST-updateCodePC-nodoOpereStrutturaliSelected", nodoOpereStrutturaliSelected)

    const dimensionamento = nodoDimensionamentoSelected ? dimensionamentiList.filter(item => item.idEnum === nodoDimensionamentoSelected) : undefined
    const tipoDissesto = nodoTipoDiDissestoSelected ? tipiDiDissentoList.filter(item => item.idEnum === nodoTipoDiDissestoSelected) : undefined
    const operaStrutturale = nodoOpereStrutturaliSelected ? opereStrutturaliList.filter(item => item.idEnum === nodoOpereStrutturaliSelected) : undefined

    console.log("TEST-updateCodePC-dimensionamento", dimensionamento)
    console.log("TEST-updateCodePC-tipoDissesto", tipoDissesto)
    console.log("TEST-updateCodePC-operaStrutturale", operaStrutturale)

    this.setState({
      dimensionamentoCodePc: dimensionamento ? parseFloat(dimensionamento[0].codePc) : undefined,
      tipoDissestoCodePc: tipoDissesto ? parseFloat(tipoDissesto[0].codePc) : undefined,
      operaStrutturaleCodePc: operaStrutturale ? parseFloat(operaStrutturale[0].codePc) : undefined,
      isCodePcUpdate: true
    }, () => {
      this.getValorePericolosita()
    })
  }


  getValorePericolosita = () => {
    const { saveValue, nodoType } = this.props;
    const { nodoValorePericolosita } = this.props.nodo
    const { dimensionamentoCodePc, tipoDissestoCodePc, operaStrutturaleCodePc } = this.state

    let valorePericolosita = nodoValorePericolosita

    if (nodoType === "Nodo_frana" && dimensionamentoCodePc && tipoDissestoCodePc && operaStrutturaleCodePc) {
      valorePericolosita = dimensionamentoCodePc * tipoDissestoCodePc * operaStrutturaleCodePc
    } else if (nodoType === "Nodo_idro" && operaStrutturaleCodePc) {
      valorePericolosita = operaStrutturaleCodePc
    }
    console.log("TEST-dimensionamentoCodePc", dimensionamentoCodePc)
    console.log("TEST-tipoDissestoCodePc", tipoDissestoCodePc)
    console.log("TEST-operaStrutturaleCodePc", operaStrutturaleCodePc)


    console.log("TEST-valorePericolosita", valorePericolosita)
    saveValue("nodoValorePericolosita", valorePericolosita)

  }




  render() {
    const { classes, nodoType } = this.props;

    const {
      statoScheda,
      nodoDimensionamentoSelected,
      nodoTipoDiDissestoSelected,
      nodoOpereStrutturaliSelected,
      nodoValorePericolosita
    } = this.props.nodo

    const {
      // COMBO BOX
      dimensionamentiList,
      tipiDiDissentoList,
      opereStrutturaliList,

      //CONTROLLER
      isOpenMessage,
      textMessage,
      severity,
      isCodePcUpdate
    } = this.state

    if (!isCodePcUpdate
      && dimensionamentiList.length != 0
      && tipiDiDissentoList.length != 0
      && opereStrutturaliList.length != 0
      && nodoDimensionamentoSelected
      && nodoTipoDiDissestoSelected
      && nodoOpereStrutturaliSelected) this.updateCodePC()


    return (
      <Fragment>
        <br />

        <Grid
          container
          direction="row"
          justify="center"
          alignItems="flex-start"
          style={{ padding: '21px' }}
        >
          <Grid
            container
            justify="center"
            md={10}
            xl={12}
            xs={12}
          >

            <Grid
              item
              md={12}
              xl={12}
              xs={12}
            >
              <div style={{ background: '#f0f9ff', padding: '8px', marginBottom: '21px', marginTop: '21px' }}>
                <Typography variant="h6" className={classes.title} >
                  ELEMENTI DEL DISSESTO IDROGEOLOGICO
                </Typography>
              </div>
            </Grid>

            <Grid
              container
              spacing={1}
              md={12}
              xl={12}
              xs={12}
            >

              {nodoType === "Nodo_frana" && <>
                <Grid
                  item
                  md={12}
                  xl={12}
                  xs={12}
                >
                  <TextField
                    required
                    fullWidth
                    select
                    label="Dimensionamento"
                    className={classNames(classes.margin, classes.textField)}
                    value={String(nodoDimensionamentoSelected)}
                    onChange={event => {
                      this.getCodePC("nodoDimensionamentoSelected", event.target.value)
                    }}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    margin="normal"
                    variant="outlined"
                  >
                    {dimensionamentiList.map(option => (
                      <MenuItem key={option.idEnum} value={option.idEnum}>
                        {option.comboValue}
                      </MenuItem>
                    ))}
                  </TextField>

                </Grid>

                <Grid
                  item
                  md={12}
                  xl={12}
                  xs={12}
                >
                  <TextField
                    required
                    fullWidth
                    select
                    label="Tipo di dissesto"
                    className={classNames(classes.margin, classes.textField)}
                    value={String(nodoTipoDiDissestoSelected)}
                    onChange={
                      event => {
                        this.getCodePC("nodoTipoDiDissestoSelected", event.target.value)
                      }
                    }
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    margin="normal"
                    variant="outlined"
                  >
                    {tipiDiDissentoList.map(option => (
                      <MenuItem key={option.idEnum} value={option.idEnum}>
                        {option.comboValue}
                      </MenuItem>
                    ))}
                  </TextField>

                </Grid>
              </>}

              <Grid
                item
                md={12}
                xl={12}
                xs={12}
              >
                <TextField
                  required
                  fullWidth
                  select
                  label={nodoType === "Nodo_idro" ? "Condizioni Strutturali" : "Opere Strutturali"}
                  className={classNames(classes.margin, classes.textField)}
                  value={String(nodoOpereStrutturaliSelected)}
                  onChange={event => {
                    this.getCodePC("nodoOpereStrutturaliSelected", event.target.value)
                  }}
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  margin="normal"
                  variant="outlined"
                >
                  {opereStrutturaliList.map(option => (
                    <MenuItem key={option.idEnum} value={option.idEnum}>
                      {option.comboValue}
                    </MenuItem>
                  ))}
                </TextField>

              </Grid>

            </Grid>

          </Grid>
        </Grid>
        <br></br>

        {isOpenMessage && <Snackbar
          open={isOpenMessage}
          autoHideDuration={10000}
          onClose={() => this.openMessage("", "info")}
        >
          <Alert
            onClose={() => this.openMessage("", "info")}
            severity={severity}>
            {textMessage}
          </Alert>
        </Snackbar>}

      </Fragment >
    );
  }
}

InfoElementiDissesto.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(InfoElementiDissesto);