import React, {Component} from "react";
import ReactDOM from "react-dom";
import * as helpers from "../../../../../helpers/helpers";
import FloatingMenu, {FloatingMenuItem} from "../../../../../helpers/FloatingMenu.jsx";
import {Item as MenuItem} from "rc-menu";
import Portal from "../../../../../helpers/Portal.jsx";
import Layers from "./Layers.jsx";
import "./GroupItem.css";
import ls from 'local-storage'
import {Grid} from '@material-ui/core'

class GroupItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      panelOpen: false,
      visible: true,
      newProps: false,
      userPanelOpen: false,
      activeLayerCount: 0,
    };
    this._isMounted = false;
    window.emitter.addListener("activeTocLayerGroup", (groupName, callback) => {
      if (groupName === this.props.group.value) this.onActivateLayer(callback);
    });
    window.emitter.addListener("updateActiveTocLayers", (groupName) => {
      if (groupName === this.props.group.value || groupName === null) this.setActiveLayerCount();
    });

  }

  setActiveLayerCount = (group, callback) => {

    const countGroupActiveLayers = (group) => {
      let count = 0;
      if (group.layers !== undefined) {
        count += group.layers.filter((layer) => layer.layer.getVisible()).length;
      }
      if (group.groups !== undefined) {
        group.groups.forEach((group) => {
          count += countGroupActiveLayers(group);
        })
      }
      return count;
    };
    let activeCount = countGroupActiveLayers(group !== undefined ? group : this.props.group);

    /* CODICE VECCHIO SENZA RICORSIONE
    if (group !== undefined) {
      activeCount = group.layers.filter((layer) => layer.layer.getVisible()).length;
      console.log(group, activeCount);
    } else {
      activeCount = this.props.group.layers.filter((layer) => {
        if (layer.layer.getVisible()) console.log(layer);
        return layer.layer.getVisible();
      }).length;

    }
    */
    if (activeCount !== this.state.activeLayerCount) this.setState({activeLayerCount: activeCount}, callback);
  };

  onActivateLayer = (callback) => {
    const tocFolder = document.getElementById("sc-toc-simcoe-folder-view-container-main");
    if (tocFolder.classList.contains("sc-hidden")) return;

    let panelOpen = this.state.panelOpen;
    this.setActiveLayerCount();
    if (!panelOpen) {
      panelOpen = !panelOpen;
      ls.set(this.props.group.value, panelOpen);
      this.setState({panelOpen: panelOpen, userPanelOpen: panelOpen}, () => {
        if (callback !== undefined) callback();
      });
    }
  };

  onMenuItemClick = (action, group) => {
    switch (action) {
      case "sc-floating-menu-disable-layers":
        // window.emitter.emit("turnOffLayers", group);
        this.props.onLayerVisibilityGroup(this.props.group, false);
        break;
      case "sc-floating-menu-enable-layers":
        this.props.onLayerVisibilityGroup(this.props.group, true);
        // window.emitter.emit("turnOnLayers", group);
        break;
      // case "sc-floating-menu-expand":
      //   // window.emitter.emit("toggleGroupLegend", "OPEN", this.props.group.value);
      //   this.props.onLegendToggleGroup(this.props.group, true);
      //   break;
      // case "sc-floating-menu-collapse":
      //   // window.emitter.emit("toggleGroupLegend", "CLOSE", this.props.group.value);
      //   this.props.onLegendToggleGroup(this.props.group, false);
      //   break;
      default:
        break;
    }

    helpers.addAppStat("Group Options", action);
  };

  // ELLIPSIS/OPTIONS BUTTON
  onGroupOptionsClick = (evt, group) => {
    var evtClone = Object.assign({}, evt);
    const menu = (
      <Portal>
        <FloatingMenu
          title={"Options - " + this.props.group.label}
          key={helpers.getUID()}
          buttonEvent={evtClone}
          autoY={true}
          onMenuItemClick={(action) => this.onMenuItemClick(action, group)}
          styleMode={helpers.isMobile() ? "left" : "right"}
        >
          <MenuItem className="sc-floating-menu-toolbox-menu-item" key="sc-floating-menu-enable-layers">
            <FloatingMenuItem label="Attiva tutti i livelli"/>
          </MenuItem>
          <MenuItem className="sc-floating-menu-toolbox-menu-item" key="sc-floating-menu-disable-layers">
            <FloatingMenuItem label="Disattiva tutti i livelli"/>
          </MenuItem>
          {/* <MenuItem className="sc-floating-menu-toolbox-menu-item" key="sc-floating-menu-expand">
            <FloatingMenuItem imageName={"plus16.png"} label="Show Legend" />
          </MenuItem>
          <MenuItem className="sc-floating-menu-toolbox-menu-item" key="sc-floating-menu-collapse">
            <FloatingMenuItem imageName={"minus16.png"} label="Hide Legend" />
          </MenuItem> */}
        </FloatingMenu>
      </Portal>
    );

    ReactDOM.render(menu, document.getElementById("portal-root"));
  };

  componentDidMount() {
    this._isMounted = true;
    this.setActiveLayerCount(undefined, () => {
      this.setState({panelOpen: ls.get(this.props.group.value), userPanelOpen: ls.get(this.props.group.value)}, () => {
      });
    });
    if (this._isMounted) this.forceUpdate();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentWillReceiveProps(nextProps) {
    /*
    this.setState({newProps: true}, () => {
      this.setActiveLayerCount(undefined, () => {
        this.setOpenState(this.state.activeLayerCount > 0 ? true : false);
      });
    });
    */
  }

  saveLayerOptions = () => {
    this.layerRef.saveLayerOptions();
  };

  containsLayers = () => {
    let layers = [];
    const filterGroupLayers = (group) => {
      if (group.layers !== undefined) {
        group.layers.forEach((layer) => {
          if (this.props.searchText === "")
            layers.push(layer);
          if ([layer.displayName.toUpperCase(), layer.groupName.toUpperCase()].join(" ").indexOf(this.props.searchText.toUpperCase()) !== -1)
            layers.push(layer);
        });
      }
      if (group.groups !== undefined) {
        group.groups.forEach((group) => {
          filterGroupLayers(group);
        })
      }
    };
    filterGroupLayers(this.props.group);
    return layers;

    /* CODICE VECCHIO SENZA RICORSIONE
    return this.props.group.layers.filter((layer) => {
      if (this.props.searchText === "") return true;
      return [layer.displayName.toUpperCase(), layer.groupName.toUpperCase()].join(" ").indexOf(this.props.searchText.toUpperCase()) !== -1;
    });
    */
  };
  containsGroups = () => {
    let groups = [];
    const filterGroupGroups = (group) => {
      if (group.groups !== undefined) {
        group.groups.filter((group) => {
          if (this.props.searchText === "")
            groups.push(group);
          if ([group.label.toUpperCase(), group.label.toUpperCase()].join(" ").indexOf(this.props.searchText.toUpperCase()) !== -1){
            groups.push(group);
          }
        });
        group.groups.forEach((group) => {
          filterGroupGroups(group);
        })
      }
    };
    filterGroupGroups(this.props.group);
    return groups;
  };
  countLayers = () => {
    const countGroupLayers = (group) => {
      let count = 0;
      if (group.layers !== undefined) {
        count += group.layers.length;
      }
      if (group.groups !== undefined) {
        group.groups.forEach((group) => {
          count += countGroupLayers(group);
        })
      }
      return count;
    };
    return countGroupLayers(this.props.group);
  };
  isVisible = () => {
    if (this.containsLayers().length > 0 || this.containsGroups().length > 0 || this.props.group.label === "Livelli Utente") {
      return true;
    } else {
      return false;
    }
  };

  setOpenState = (state) => {
    if (!this.state.newProps) {
        this.setState({panelOpen: state});
        ls.set(this.props.group.value, state);
    } else {
      if (this.isVisible() && this.props.searchText !== "") {
        this.setState({panelOpen: true});
        ls.set(this.props.group.value, true);
      } else {
        this.setState({panelOpen: this.state.userPanelOpen});
        ls.set(this.props.group.value, this.state.userPanelOpen);
      }
    }
  };

  onHeaderClick = () => {
    let panelOpen = this.state.panelOpen;
    let userPanelOpen = this.state.userPanelOpen;
    panelOpen = !panelOpen;
    userPanelOpen = !userPanelOpen;
    if (this.props.searchText !== "") {
      this.setState({panelOpen: panelOpen, newProps: false});
      ls.set(this.props.group.value, panelOpen);
    } else {
      this.setState({panelOpen: panelOpen, userPanelOpen: userPanelOpen, newProps: false});
      ls.set(this.props.group.value, panelOpen);
    }
  };

  render() {
    if (this.props.group !== undefined && this.isVisible()) {
      if (this.props.group.label === "02 Particelle Originali") console.log('render 02 Particelle Originali', this.state.panelOpen);
      if (this.props.group.label === "20 - Catasto") console.log('render 20 - Catasto', this.state.panelOpen);
      return (
        <div className={"sc-toc-group-list-container"} key={"sc-toc-group-list-container" + this.props.group.value}>
          <div
            className={(this.state.panelOpen ? "sc-toc-group-list-header open" : "sc-toc-group-list-header") + (this.state.activeLayerCount > 0 ? " active" : "")}>
           
           {/** GROUPS OPTIONS SECTION
           className={"sc-toc-group-list-header-label"} */}
            <div 
                 onClick={this.onHeaderClick}>
              &nbsp;&nbsp;{this.props.group.label}{" "}
             {/* <span>
                - ({this.state.activeLayerCount}/{this.countLayers()})
              </span>*/}
            </div>

            {/* 
            GROUPS OPTIONS SECTION
            <div className="sc-toc-group-toolbox-folder-view" title="Group Options"
                 onClick={(evt) => this.onGroupOptionsClick(evt, this.props.group.value)}>
              <img src={images["group-more-options.png"]} alt="More Group Options"/>
            </div>*/}

          </div>
          
          <div className={this.state.panelOpen ? "sc-toc-group-list-item-container" : "sc-hidden"}
               key={helpers.getUID()}>
            {
              this.props.group.groups &&
              this.props.group.groups.length > 0 &&
              this.props.group.groups.map(group => {
                //console.log('gruppo da aggiungere', group);
                return (
                  <GroupItem
                    key={"group-item" + group.value}
                    group={group}
                    searchText={this.props.searchText}
                    sortAlpha={this.props.sortAlpha}
                    allGroups={this.props.layerGroups}
                    saveLayerOptions={this.props.saveLayerOptions}
                    onGroupChange={this.props.onGroupChange}
                    onLayerChange={this.props.onLayerChange}
                    onLegendToggle={this.props.onLegendToggle}
                    onSortChange={this.props.onSortChange}
                    onLegendToggleGroup={this.props.onLegendToggleGroup}
                    onLayerVisibilityGroup={this.props.onLayerVisibilityGroup}
                    onSliderChange={this.props.onSliderChange}
                    tocVisible={this.props.visible}
                  />
                )
              })
            }
            {
              this.props.group.layers &&
              <Layers
                ref={(ref) => {
                  this.layerRef = ref;
                }}
                key={"layer-list" + this.props.group.value}
                group={this.props.group}
                searchText={this.props.searchText}
                sortAlpha={this.props.sortAlpha}
                onGroupChange={this.props.onGroupChange}
                onLayerChange={this.props.onLayerChange}
                onLegendToggle={this.props.onLegendToggle}
                onSliderChange={this.props.onSliderChange}
                tocVisible={this.props.tocVisible}
              />
            }

          </div>
        </div>
      );
    } else {
      return <div/>;
    }
  }
}

export default GroupItem;

// IMPORT ALL IMAGES
const images = importAllImages(require.context("../images", false, /\.(png|jpe?g|svg|gif)$/));

function importAllImages(r) {
  let images = {};
  r.keys().map((item, index) => (images[item.replace("./", "")] = r(item)));
  return images;
}
