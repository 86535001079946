import React, { Component } from "react";
import "./SidebarSlim.css";
import MenuButton from "./MenuButton.jsx";
import * as helpers from "../helpers/helpers";
//Profile User
import ProfileUserDialog from '../user/ProfileUserDialog';

class SidebarSlim extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isOpenSettingsAccount: false
    };
  }

  openProfileAccount = () => {
    this.setState(prevState => ({
      isOpenProfileAccount: !prevState.isOpenProfileAccount
    }))
  }

  onButtonClick = (button) => {
    this.props.onClick(button);
  }

  render() {

    const rolesUser = JSON.parse(localStorage.getItem("ROLES_USER"))
    const { isOpenProfileAccount } = this.state

    return (
      <div className={window.sidebarOpen ? "sc-hidden" : "sc-sidebar-slim-container"}>
        <SlimButton title="Livelli" image="button_layers.png" onClick={() => { window.emitter.emit("activateTab", "layers"); }} isSelected={this.props.tabIndex === 0} />

        {rolesUser.some(role => role.ruolo !== "VIEWER") &&
          <SlimButton title="Reports" image="button_reports.png" onClick={() => { window.emitter.emit("activateTab", "reports"); }} isSelected={this.props.tabIndex === 4} />
        }

        {rolesUser.some(role => role.ruolo !== "VIEWER") &&
          <SlimButton title="Editing" image="map-32x32.png" onClick={() => { window.emitter.emit("activateTab", "mymaps"); }} isSelected={this.props.tabIndex === 3} />
        }
        {/* rolesUser.some(role => role.ruolo !== "VIEWER")  &&
          <SlimButton title="Servizi" image="theme-32x32.png" onClick={() => { window.emitter.emit("activateTab", "themes"); }} isSelected={this.props.tabIndex === 1} />
        */}
        {rolesUser.some(role => role.ruolo !== "VIEWER" && role.ruolo !== "USER") &&
          <SlimButton title="Strumenti" image="tools-32x32.png" onClick={() => { window.emitter.emit("activateTab", "tools"); }} isSelected={this.props.tabIndex === 2} />
        }
        {/*<SlimButton title="Servizi" image="theme-32x32.png" onClick={() => {window.emitter.emit("activateTab", "themes");}} isSelected={ this.props.tabIndex === 3}/>*/}

        {rolesUser.some(role => role.ruolo !== "VIEWER") &&
          <SlimButton title="Account"
            image="button_account.png"
            onClick={() => this.openProfileAccount()}
          />}

        <div className="sc-sidebar-slim-footer-container">
          <MenuButton />
        </div>

        <ProfileUserDialog isOpen={isOpenProfileAccount} closeDialog={this.openProfileAccount} />

      </div>
    );
  }
}
export default SidebarSlim;

class SlimButton extends Component {
  state = {};

  render() {
    return (
      <div key={helpers.getUID()} className={"sc-sidebar-slim-button-container" + (this.props.isSelected ? " active" : "")} onClick={this.props.onClick}>

        <button className="sc-sidebar-slim-button">
          <img src={images[this.props.image]} alt={this.props.title} />
          <br />
          <span>{this.props.title}</span>
        </button>

      </div>
    );
  }
}


// IMPORT ALL IMAGES
const images = importAllImages(require.context("./images", false, /\.(png|jpe?g|svg)$/));
function importAllImages(r) {
  let images = {};
  r.keys().map((item, index) => (images[item.replace("./", "")] = r(item)));
  return images;
}
