import React, { Component } from "react";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import MuiAlert from '@material-ui/lab/Alert';
import { Row } from 'reactstrap';
import { Typography, TextField, Snackbar, CircularProgress, Button, Box } from '@material-ui/core';

// API REQUEST
import axios from 'axios';
import {
  authLogin
} from '../backEnd/requestAPI';

const styles = {
  appBar: {
    position: 'relative',
  },
  flex: {
    flex: 1,
  },
  toolbarButtons: {
    marginLeft: 'auto',
  },
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class loginDialog extends Component {

  constructor(props) {
    super(props);

    this.state = {
      // Alert
      textMessage: "",
      severity: "info",
      isOpenMessage: false,

    };
  }

  openMessage = (text, type) => {
    this.setState(prevState => ({
      isOpenMessage: !prevState.isOpenMessage,
      isLoading: false,
      textMessage: text,
      severity: type
    }))
  }

  checkDataSignIn = event => {
    event.preventDefault();

    this.setState({
      isLoadingLogin: true,
      isErrorAuthorized: false
    })

    let user = {
      username: event.target.username.value,
      password: event.target.password.value
    }

    axios.post(authLogin,
      user).then(response => {
        let userAuth = response.data
        let userRole = userAuth.ruoli

        this.setState({
          isErrorAuthorized: false,
          isLoadingLogin: false
        }, () => {
          localStorage.setItem("USER_TOKEN", userAuth.access_token);
          localStorage.setItem("USER_ID", userAuth.utente.idUtente);
          localStorage.setItem("USER_NAME", userAuth.utente.nome + " " + userAuth.utente.cognome)
          localStorage.setItem("ROLES_USER", JSON.stringify(userRole));
          localStorage.setItem("USER_TOKEN_EXPIRATION_DATE", userAuth.expiration_date)
          localStorage.setItem("USER_TYPE", "AUTHORIZED")

          window.location.reload();
        })


      }).catch(error => {
        this.setState({
          isLoadingLogin: false,
          isErrorAuthorized: true
        })

        let statusCode = error.response.data.statusCode
        if (statusCode == 401) this.openMessage("Utente non autorizzato")
        else alert(error);

      });
  }



  render() {
    const { classes, isOpen, closeDialog } = this.props;
    const { isOpenMessage, textMessage, severity,
      isErrorAuthorized, isLoadingLogin
    } = this.state

    return (
      <div>
        <Dialog open={isOpen} onClose={closeDialog} TransitionComponent={Transition}>
          <AppBar className={classes.appBar}>
            <Toolbar style={{background: '#2F2483'}}>
              <IconButton edge="start" color="inherit" onClick={() => closeDialog()} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" className={classes.title}>
                Area riservata
              </Typography>
            </Toolbar>
          </AppBar>

          <Row className="app-row survey-app" style={{ margin: '21px' }}>


            <form onSubmit={this.checkDataSignIn} className={classes.form} >
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                autoFocus
                error={isErrorAuthorized}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                error={isErrorAuthorized}
              />
              <div style={{ marginTop: '34px', marginBottom: '21px' }}>
                {isLoadingLogin ?
                  <div style={{ textAlign: "center" }}>
                    <CircularProgress />
                  </div>
                  :
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    style={{ background: '#2F2483', color: '#FFFFFF' }}
                    className={classes.submit}
                  >
                    ACCEDI
                  </Button>
                }

              </div>

              {/* isLoadingLogin ?
              null
              :
              <Button
                fullWidth
                variant="contained"
                style={{ background: '#EBEBEB', color: '#000000' }}
                className={classes.submit}
                onClick={() => guestLogin()}
              >
                ACCEDI COME OSPITE
              </Button>
            */}

              {isErrorAuthorized &&
                <Typography style={{ marginBottom: "21px", color: "red", textAlign: "center" }}>
                  Username e/o password errati!
                </Typography>
              }

              {/*    <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Password dimenticata?
                </Link>
              </Grid>

            </Grid>*/}

            </form>



            {isOpenMessage ?
              <Snackbar
                open={isOpenMessage}
                autoHideDuration={10000}
                onClose={() => this.openMessage("", "info")}
              >

                <Alert
                  onClose={() => this.openMessage("", "info")}
                  severity={severity}>
                  {textMessage}
                </Alert>

              </Snackbar>
              : null
            }

          </Row>
        </Dialog>

      </div>
    );
  }
}

loginDialog.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(loginDialog);
